/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AppWindowTitle from 'AppWindowTitle'
import { importMediafileFromUrl } from 'actions/importFromUrl'
import { clearTimeline as clearTimelineAction } from 'actions/timeline'
import { isLink } from 'helpers/isLink'
import { useAction, useCssStyle, useExternalCustomScript } from 'hooks'
import { showSaveSettings } from 'actions/mainView'
import { ActivationComponent } from '~/features/desktop-activation-feature/activation-component/activation-component'

import MainView from './MainView'
import WebSocket from './WebSocket'
import { NW_JS_APP_PROFILES } from '~/enums'

function ExternalCustomScript({ url, stringified, onSuccess }) {
  const handleOnSuccess = () => {
    onSuccess()
  }

  useExternalCustomScript({ src: url,
    stringified,
    onSuccess: handleOnSuccess })
  return null
}

function ExternalCustomStyles({ url, stringified, onComplete }) {
  useCssStyle({ src: url, stringified, onComplete })
  return null
}

function MainViewContainer() {
  const [ customJSFetched, setCustomJSFetched ] = useState(false)
  const [ customCSSFetched, setCustomCSSFetched ] = useState(false)
  const importFromUrl = useAction(importMediafileFromUrl)
  const closeSaveSettings = useAction(showSaveSettings, false)

  const clearTimeline = useAction(clearTimelineAction)
  const { fetched: userFetched, customCSS, customJS } = useSelector(state => state.user)

  const fetched = (customJS ? customJSFetched : true)
    && (customCSS ? customCSSFetched : true)
    && userFetched

  useEffect(() => {
    // import video for package profile
    const importByUrl = async e => {
      const { videoUrl } = e.detail
      await clearTimeline()
      closeSaveSettings()
      importFromUrl(videoUrl)
    }

    if (__APP_PROFILE__ === 'package') {
      document.addEventListener('solveig-importFromUrl', importByUrl)
      return () => document.removeEventListener('solveig-importFromUrl', importByUrl)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {customJS && (
      <ExternalCustomScript
        stringified={!isLink(customJS) && customJS}
        url={isLink(customJS) && customJS}
        onSuccess={() => setCustomJSFetched(true)}
      />
      )}

      {customCSS && (
        <ExternalCustomStyles
          stringified={!isLink(customCSS) && customCSS}
          url={isLink(customCSS) && customCSS}
          onComplete={() => setCustomCSSFetched(true)}
        />
      )}

      <WebSocket />
      {fetched && (
      <>
        {__APP_PROFILE__ !== 'public' && <AppWindowTitle />}
        <MainView />
        {NW_JS_APP_PROFILES.includes(__APP_PROFILE__) && <ActivationComponent />}
      </>
      )}
    </>
  )
}

export default MainViewContainer
