import Scrollbars from 'components/base/Scrollbars'
import React from 'react'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import './View.scss'

export const View = ({ children, title, onClose, footer, header }) => (
  <div className="base-view">
    <div className="base-view__head head">
      <div className="head__top">
        <span className="head__caption">{title}</span>
        <div className="head__close" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      {header}
    </div>
    <div className="base-view__main">
      <Scrollbars style={{ height: 'calc(100% - 120px)' }}>
        {children}
      </Scrollbars>
      {!!footer && <div className="base-view__footer">{footer}</div>}
    </div>
  </div>
)
