import React, { useEffect, useState } from 'react'

import { volumeLevelGraph, setGain } from 'components/AssetPanels/Voiceover/utils/webAudioAPI'
import Slider from 'components/base/Slider'


import styles from './volumeLevel.module.scss'

const MAX_GAIN_LEVEL = 10
const DEFAULT_GAIN_LEVEL = 1

function useMediaStream(stream: MediaStream | null, autoGainControl: boolean):
  [ number, number, (x: number) => void ] {
  const [ volumeLevel, setVolumeLevel ] = useState(0)
  const [ gainLevel, _setGainLevel ] = useState(DEFAULT_GAIN_LEVEL)

  const setGainLevel = (value: number) => {
    if (value >= 0 && value <= MAX_GAIN_LEVEL) {
      _setGainLevel(value)
    }
  }

  useEffect(() => {
    setGain(gainLevel)
  }, [ stream, gainLevel ])

  useEffect(() => {
    if (!stream) return () => {}
    let cancel = () => {}
    (async function fn() {
      cancel = volumeLevelGraph(setVolumeLevel)
    }())

    return () => cancel()
  }, [ stream ])

  return [ volumeLevel, gainLevel, setGainLevel ]
}

type Props = {
  stream: MediaStream | null, disable: boolean, autoGainControl: boolean
}

export default function VolumeLevel({ stream, disable, autoGainControl }: Props) {
  const [ volumeLevel, gainLevel, setGainLevel ] = useMediaStream(stream, autoGainControl)

  const disableSlider = disable || autoGainControl

  return (
    <div className={styles.volumeMeter}>
      <Slider
        classes={{
          root: styles.sliderRoot,
          rail: styles.rail,
          track: styles.track,
          thumb: styles.thumb,
        }}
        onChange={setGainLevel}
        max={MAX_GAIN_LEVEL}
        min={0}
        step={0.01}
        value={gainLevel}
        defaultValue={1}
        disabled={disableSlider}
        emitValue
      />
      <Slider
        classes={{
          root: styles.sliderRoot,
          rail: styles.rail1,
          track: styles.track1,
          thumb: styles.thumb1,
        }}
        style={{ position: 'absolute' }}
        value={volumeLevel * gainLevel}
        max={MAX_GAIN_LEVEL * 100}
        scale={(x: number) => x / MAX_GAIN_LEVEL}
        disabled={disable}
      />
    </div>
  )
}
