import { MouseEvent, useCallback, useState } from 'react'

type UseContextMenu = {
    container: HTMLDivElement | null
}

export const useContextMenu = ({ container }: UseContextMenu) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const onOpenMenu = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(container)
  }

  const onCloseMenu = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
    setAnchorEl(null)
  }, [])

  return {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  }
}
