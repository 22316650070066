import * as ActionTypes from './ActionTypes'

export const setPreviewParams = (scale, boundingRect) => ({
  type: ActionTypes.SET_PREVIEW_PARAMS,
  payload: { scale, boundingRect },
})

export const toggleSplitPreview = () => ({
  type: ActionTypes.SPLIT_PREVIEW,
  payload: {},
})

export const setActivePreview = activePreview => ({
  type: ActionTypes.SET_ACTIVE_PREVIEW,
  payload: { activePreview },
})

export const setFullScreen = fullScreen => ({
  type: ActionTypes.SET_FULLSCREEN,
  payload: { fullScreen },
})

export const setResizableParams = ({ width, height }) => ({
  type: ActionTypes.SET_RESIZABLE_PARAMS,
  payload: { width, height },
})

export const setDuration = duration => ({
  type: ActionTypes.ON_CHANGE_DURATION,
  payload: { duration },
})

export const setPreviewMarker = ({
  preview,
  point,
  markType,
  showIOPoints = true,
  updateAsset = true,
}) => ({
  type: ActionTypes.SET_PREVIEW_MARKER,
  payload: { preview, point, markType, updateAsset, showIOPoints },
})

export const resetMarkers = ({ preview, markType = null }) => ({
  type: ActionTypes.RESET_MARK,
  payload: { preview, markType },
})

export const setPreviewMarkers = (
  data, options = { showIOPoints: true, preview: null, updateAsset: true }
) => ({
  type: ActionTypes.SET_PREVIEW_MARKERS,
  payload: {
    data,
    showIOPoints: options.showIOPoints,
    preview: options.preview,
    updateAsset: options.updateAsset,
  },
})

export const updatePreviewMarkers = assetId => ({
  type: ActionTypes.UPDATE_PREVIEW_MARKERS,
  payload: { assetId },
})

export const setInOutPointsTimelineAsset = assetId => ({
  type: ActionTypes.SET_IN_OUT_POINTS_TIMELINE_ASSET,
  payload: {
    assetId,
  },
})

export const setShowInOutPoints = show => ({
  type: ActionTypes.SET_SHOW_IN_OUT_POINTS,
  payload: {
    show,
  },
})

export const setInOutPointsMoving = isMoving => ({
  type: ActionTypes.SET_IN_OUT_POINTS_MOVING,
  payload: { isMoving },
})

export const setSourcePlayerProgress = progress => ({
  type: ActionTypes.SET_SOURCE_PLAYER_PROGRESS,
  payload: { progress },
})

export const setPreviewLoading = isLoading => ({
  type: ActionTypes.SET_PREVIEW_LOADING,
  payload: { isLoading },
})

export const setPreviewMode = mode => ({
  type: ActionTypes.SET_PREVIEW_MODE,
  payload: { mode },
})
