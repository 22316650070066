import React, { MouseEvent } from 'react'
import { Box } from '@material-ui/core'
import { MARK_TYPE } from 'config/constants/preview'
import { ClipCreatorMarkType } from 'types/preview'
import { isNil } from 'lodash'
import classNames from 'classnames'
import { ReactComponent as MarkOutIcon } from '~/assets/preview/mark_out.svg'

interface IProps {
  onClickMarkIcon: (markType: ClipCreatorMarkType, isResizer?: boolean) => (e: MouseEvent) => void
  markOut: number | null
  isDragging: boolean
}

export const RightBound = ({ markOut, onClickMarkIcon, isDragging }: IProps) => (
  <>
    {!isNil(markOut)
      ? (
        <div
          onMouseDown={onClickMarkIcon(MARK_TYPE.OUT, true)}
          className={classNames('clip-creator__bar-resizer clip-creator__bar-resizer_r', {
            draggable: isDragging,
          })}
        >
          <Box className="hidden-resizer hidden-resizer_r" />
          <MarkOutIcon />
          <div className="clip-creator__transparent-remover clip-creator__transparent-remover_r" />
        </div>
      )
      : <Box width="2px" height="2px" />}
  </>
)
