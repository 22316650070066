import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import ExtendedReactPlayer from '~/components/ExtendedReactPlayer/ExtendedReactPlayer'
import { videoUrlTimeFragmentParam } from '~/constant'
import { PLAYBACK_UPDATE_FREQUENCY } from '~/Util'
import './VideoPlayer.scss'

// eslint-disable-next-line prefer-arrow-callback
const BasePreviewPlayer = React.memo(function BasePreviewPlayer(props) {
  const {
    src,
    playing,
    width, height,
    startPositionSeconds,
    volume,
    style,
    externalRef,
    isMedia,
    ...rest
  } = props

  // InnerRef only for media preview. ExternalRef not available in media preview
  const innerRef = React.useRef()
  const ref = !isMedia ? externalRef : innerRef

  function getUrlWithFragmentPosition(positionSeconds, url) {
    return !positionSeconds || !url ? url : `${url}${videoUrlTimeFragmentParam}=${positionSeconds}`
  }

  const urlWithStartFragment = useMemo(
    () => getUrlWithFragmentPosition(startPositionSeconds, src),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ src ]
  )

  if (!ref) return null

  return (
    <div style={style} className={classNames('player', { 'display-none': !src })} draggable>
      <ExtendedReactPlayer
        {...rest}
        className="video-player"
        volume={volume}
        ref={ref}
        url={urlWithStartFragment}
        width={width}
        height={height}
        playing={playing}
        controls={false}
        progressInterval={PLAYBACK_UPDATE_FREQUENCY}
      />
    </div>
  )
})

BasePreviewPlayer.defaultProps = {
  width: 0,
  height: 0,
  startPositionSeconds: undefined,
  src: undefined,
  volume: 0,
  playing: false,
  externalRef: null,
  isMedia: false,
}

BasePreviewPlayer.propTypes = {
  src: PropTypes.string,
  playing: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  startPositionSeconds: PropTypes.number,
  volume: PropTypes.number,
  externalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isMedia: PropTypes.bool,
}

export default React.forwardRef((props, ref) => (
  <BasePreviewPlayer
    externalRef={ref}
    {...props}
  />
))
