import React from 'react'
import cx from 'classnames'
// eslint-disable-next-line no-unused-vars
import MaterialSlider from '@material-ui/core/Slider'
import { EMPTY_STRING } from 'constant'
import { noop } from 'lodash'
import './Slider.scss'

// ---

function useSliderChangeHandler(fn, emitValue) {
  return React.useCallback(
    (e, value) => emitValue ? fn(value) : fn(e, value), [ emitValue, fn ]
  )
}

// ---

/**
 * @typedef {object} SliderPropsType
 * @property {React.ReactNode} [children]
 * @property {string} [className]
 * @property {boolean} [emitValue]
 * @property {function} [onChange]
 * @property {function} [onChangeCommitted]
 * @property {number} [min]
 * @property {number} [max]
 * @property {number} [step]
 * @property {boolean} [disabled]
 * @property {number} [value]
 * @property {Array} [marks]
 * @property {Object} [classes]
 * @property {Function} [scale]
 * @property {number} [defaultValue]
 * @property {Object} [style]
 * @property {string} [orientation]
 */

/**
 * @param {SliderPropsType} props
 * @param {any} ref
 * @returns
 */
const Slider = (props, ref) => {
  const {
    className = EMPTY_STRING,
    emitValue = true,
    onChange = noop,
    onChangeCommitted = noop,
    onOpenMenu = noop,
    isPreview,
    ...rest
  } = props

  // ---

  const handleChange = useSliderChangeHandler(onChange, emitValue)
  const handleChangeCommitted = useSliderChangeHandler(onChangeCommitted, emitValue)
  const onContextMenu = e => {
    e.preventDefault()
    e.stopPropagation()
    onOpenMenu(e)
  }

  // ---

  return (
    <div
      onContextMenu={onContextMenu}
      className={cx('slider', { 'preview-slider': isPreview })}
      ref={ref}
    >
      <MaterialSlider
        {...rest}
        className={cx(className)}
        onChange={(e, value) => {
          // Reject for context menu open
          if (e.which !== 3 && e.button !== 2) {
            handleChange(e, value)
          }
        }}
        onChangeCommitted={handleChangeCommitted}
      />
    </div>
  )
}

export default React.forwardRef(Slider)
