import { useSelector } from 'react-redux'
import * as Selectors from '~/selectors'
import { DRAGNDROP_TYPE } from '~/enums'
import { assetToTimelineDragElement } from './assetToTimelineDragElement'
import { usePatchDraggingAsset } from './usePatchDraggingAsset'

export const useDraggingAsset = ({ dragItem, scale }) => {
  const draggingItemAsset = useSelector(state => Selectors.selectAssetById(state, dragItem.id))
  const draggingItemFile = useSelector(state => Selectors.getSourceFileById(state, dragItem.id))
  const transitions = useSelector(state => Selectors.getTransitionAssets(state))
  const isExternalSource = dragItem?.type !== DRAGNDROP_TYPE.LAYER_ASSET

  let draggingAsset = draggingItemAsset || draggingItemFile
  // set I/O points for the dragged media asset
  draggingAsset = usePatchDraggingAsset({ draggingAsset, isExternalSource })


  const assetLeftTransition = transitions.find(tr => tr.isAttachedTo(draggingAsset?.id, 'right'))
  const offset = assetLeftTransition
    ? assetToTimelineDragElement(scale)(assetLeftTransition).width : 0
  const draggingAssetWidth = draggingAsset
    ? assetToTimelineDragElement(scale)(draggingAsset).width : 0

  return {
    scale,
    draggingItemAsset,
    draggingItemFile,
    transitions,
    draggingAsset,
    offset,
    draggingAssetWidth,
    isExternalSource,
    assetLeftTransition,
  }
}
