import { PLAYBACK_STATE, PLAYER_TYPE } from '~/enums'
import * as ActionTypes from './ActionTypes'

export const changeClipPlaybackState = state => ({
  type: ActionTypes.CHANGE_PLAYBACK_STATE,
  payload: { state },
})

export const changeTimelinePlaybackState = state => ({
  type: ActionTypes.CHANGE_PREVIEW_STATE,
  payload: { state },
})

export const setPlaybackClip = (asset, playbackState, resetPreviewProgress = true) => ({
  type: ActionTypes.SET_PLAYBACK_MEDIA,
  payload: { asset, playbackState, resetPreviewProgress },
})

export const resetPlaybackClip = () => ({
  type: ActionTypes.RESET_PLAYBACK_MEDIA,
  payload: {},
})

export const togglePlaybackPlaying = ({
  playbackState = null,
  playerType,
}) => (dispatch, getState) => {
  const { clipPlaybackState, timelinePlaybackState } = getState().playback

  // const hasSelectedClip = selectedClipId !== null
  const currentPlaybackState = playerType === PLAYER_TYPE.MEDIA
    ? clipPlaybackState
    : timelinePlaybackState
  let newPlaybackState = currentPlaybackState === PLAYBACK_STATE.PLAY
    ? PLAYBACK_STATE.PAUSE
    : PLAYBACK_STATE.PLAY

  if (playbackState) {
    newPlaybackState = playbackState
  }
  if (playerType === PLAYER_TYPE.MEDIA) {
    dispatch(changeClipPlaybackState(newPlaybackState))
  } else {
    dispatch(changeTimelinePlaybackState(newPlaybackState))
  }
}

export const setIsBuffering = isBuffering => ({
  type: 'SET_PLAYBACK_BUFFERING',
  payload: { isBuffering },
})

export const setTimeCodeState = ({ timeCodeState, activeTimeCodeContainer }) => ({
  type: ActionTypes.SET_TIME_CODE_STATE,
  payload: { timeCodeState, activeTimeCodeContainer },
})

export const setTimeCodeMode = ({ mode, containerLocation }) => ({
  type: ActionTypes.SET_TIME_CODE_MODE,
  payload: { mode, containerLocation },
})

export const setCopiedTimeCode = ({ timeUnits, progress }) => ({
  type: ActionTypes.SET_COPIED_TIME_CODE,
  payload: { timeUnits, progress },
})

export const resetCopiedTimeCode = () => ({
  type: ActionTypes.RESET_COPIED_TIME_CODE,
  payload: {},
})

export const stopPlaybackClip = () => ({
  type: ActionTypes.STOP_PLAYBACK_MEDIA,
  payload: {},
})

export const stopPlaybackTimeline = () => ({
  type: ActionTypes.STOP_PLAYBACK_TIMELINE,
  payload: {},
})

export const emitMediaServerSeekProcessingStatus = isProcessing => ({
  type: ActionTypes.EMIT_MEDIA_SERVER_SEEK_PROCESSING_STATUS,
  payload: { isProcessing },
})

export const setMediaServerSeekProcessing = isProcessing => ({
  type: ActionTypes.SET_MEDIA_SERVER_SEEK_PROCESSING,
  payload: { isProcessing },
})
