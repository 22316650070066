import { clearAssetsSelection } from 'actions/layer'
import { useColorProvider } from 'hooks/useColorProvider'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Selectors from 'selectors'
import { setPreviewParams as setPreviewParamsAction } from '~/actions/preview'
import {
  useAggregatedPlayer,
  useCanvasParams,
  useTimelineVideoPlayerAssets
} from '~/components/Preview/lib'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { useAction, useChanged } from '~/hooks'
import { AudioAsset, VideoAsset } from '~/models/Asset'
import { PREVIEW_I_O_POINTS_TRIM_HEIGHT } from '~/constant'
import AssetsOverlay from '../AssetsOverlay/AssetsOverlay'
import TimelineAssetMediaPlayer from './TimelineAssetMediaPlayer'


export default function AggregatedPlayer(props) {
  const {
    playingMediaAssets,
    backgroundAssets,
    composedMediaAssets,
    isSliderOnBufferingPos,
    // mediaTransitions,
    playing: basePlaying,
    isMedia,
    ...rest
  } = props
  const { width, height } = rest
  // NOTE: preview with the Media dissolve transition can have only one transition
  // const transition = mediaTransitions[0]
  // NOTE: preview with the Media dissolve transition can have only one composedAsset
  const transitionVideoAsset = composedMediaAssets[0]

  const setPreviewParams = useAction(setPreviewParamsAction)
  const clearSelection = useAction(clearAssetsSelection)

  const mediaRecordingAssetInProgress = useSelector(Selectors.recording.isRecordingStarted)
  const composedOverlayAssets = useSelector(Selectors.selectComposedAssets)
  const overlayTransitions = useSelector(Selectors.selectExistingOverlayTransitionAssets)

  const params = useCanvasParams({ width, height })
  const scale = useChanged(params.scale)
  const rect = useChanged(params.rect)

  const toPlayerId = id => id // used for modified ids
  const assetToPlayerId = asset => toPlayerId(asset.id)
  const toPlayerIds = (klass, assets) => klass.filter(assets).map(assetToPlayerId)
  const toVideoAudioPlayerIds = assets => (
    [ ...toPlayerIds(VideoAsset, assets), ...toPlayerIds(AudioAsset, assets) ]
  )

  const playingPlayerIds = toVideoAudioPlayerIds(playingMediaAssets)
  const backgroundPlayerIds = toVideoAudioPlayerIds(backgroundAssets)
  const composedPlayerIds = toVideoAudioPlayerIds(composedMediaAssets)

  const mode = mediaRecordingAssetInProgress ? 'test' : 'default'


  const {
    isBuffering, onReady, onMounted, onBufferingProgress, isPlaying,
  } = useAggregatedPlayer(playingPlayerIds.concat(composedPlayerIds), backgroundPlayerIds,
    isSliderOnBufferingPos, basePlaying, mode)

  const {
    renderingAssets,
    videoRefs,
    getTimelineAssetVideoPlayerProps,
    overlayAssets,
  } = useTimelineVideoPlayerAssets({
    playingMediaAssets,
    backgroundAssets,
    composedMediaAssets,
    assetToPlayerId,
    onReady,
    onMounted,
    onBufferingProgress,
    transitionVideoAsset,
    isPlaying,
    rect,
    restAggregatedPlayerProps: rest,
  })

  // const isSomeAssetHaveCurrentTransition = renderingAssets.some(({ id }) => (
  //   id === transition?.leftVideoAssetId || id === transition?.rightVideoAssetId))

  useEffect(() => {
    setPreviewParams(scale, rect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ scale, rect ])

  const { isColorSelectionEnabled } = useColorProvider('overlays')

  const handleClickVideo = useCallback(e => {
    if (e.target.tagName === 'DIV') {
      clearSelection()
    }
  }, [ clearSelection ])

  return (
    <>
      {renderingAssets.map(asset => (
        <TimelineAssetMediaPlayer
          key={asset.id}
          isMedia={isMedia}
          {...getTimelineAssetVideoPlayerProps(asset,
            backgroundAssets.some(a => a.id === asset.id))}
        />
      ))}

      {!!overlayAssets.length
        && !isColorSelectionEnabled && (
        <div
          onClick={handleClickVideo}
          className="preview-player__text-overlay"
          style={{
            width,
            height: height - PREVIEW_I_O_POINTS_TRIM_HEIGHT,
            zIndex: 55,
          }}
        >
          <AssetsOverlay
            overlayAssets={overlayAssets}
            composedOverlayAssets={composedOverlayAssets}
            overlayTransitions={overlayTransitions}
            width={width}
            height={height - PREVIEW_I_O_POINTS_TRIM_HEIGHT}
            videoRefs={videoRefs}
          />
        </div>
      )}

      {/* <If condition={transition !== undefined && isSomeAssetHaveCurrentTransition}>
        <VideoTransition
          from={getPlayerById(
            toPlayerId(transition.leftVideoAssetId || transition.rightVideoAssetId)
          ) || null}
          to={transitionVideoAsset ? getPlayerById(assetToPlayerId(transitionVideoAsset)) : null}
          width={width}
          height={height}
          transition={transition}
          params={params}
        />
      </If> */}

      <If condition={isBuffering}>
        <div className="progress-spinner">
          <CircularProgress
            size={100}
            endless
            percents={50}
            transparent
          />
        </div>
      </If>
    </>
  )
}
