import * as Actions from 'actions'
import { isEmpty } from 'lodash'
import React, { memo, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { ListItemText } from '@material-ui/core'
import { ReactComponent as BrushIcon } from '~/assets/media/ic_brush.svg'
import { ReactComponent as FiltersIcon } from '~/assets/media/ic_filtres.svg'
import { ReactComponent as ImagesIcon } from '~/assets/media/ic_images.svg'
import { ReactComponent as MediaIcon } from '~/assets/media/ic_media.svg'
import { ReactComponent as SoundIcon } from '~/assets/media/ic_sound.svg'
import { ReactComponent as TextIcon } from '~/assets/media/ic_text.svg'
import { ReactComponent as TransitionIcon } from '~/assets/media/ic_transitions.svg'
import { ReactComponent as VideosIcon } from '~/assets/media/ic_videos.svg'

import 'styles/style.scss'
import './Menu.scss'

import { MENU_ITEMS, SOURCE_FILE_TYPES } from '~/enums'
import { useAction } from '~/hooks'

import Scrollbars from '~/components/base/Scrollbars'
import { TranslationContext } from '~/contexts/TranslationContext'
import { getChapterFromStorage } from '~/reducers/mainView'
import { selectSourceFilesParams } from '~/selectors'
import { EditorMenu } from '~/components/EditorMenu/EditorMenu'


// ---

const ICONS_ORDER = [
  MENU_ITEMS.MEDIA,
  MENU_ITEMS.TRANSITIONS,
  MENU_ITEMS.TEXT,
  MENU_ITEMS.AUDIO,
  MENU_ITEMS.FILTERS,
  MENU_ITEMS.DESIGN,
  MENU_ITEMS.IMAGES,
  MENU_ITEMS.VIDEOS,
]

const ICONS_ENABLED_STATUS = {
  [MENU_ITEMS.MEDIA]: __CFG__.SOURCE_FILES_TYPES.MEDIA,
  [MENU_ITEMS.AUDIO]: __CFG__.SOURCE_FILES_TYPES.AUDIO,
  [MENU_ITEMS.TEXT]: __CFG__.SOURCE_FILES_TYPES.TEXT,
  [MENU_ITEMS.TRANSITIONS]: __CFG__.SOURCE_FILES_TYPES.TRANSITIONS,
  [MENU_ITEMS.FILTERS]: __CFG__.SOURCE_FILES_TYPES.FILTERS,
  [MENU_ITEMS.DESIGN]: __CFG__.SOURCE_FILES_TYPES.DESIGN,
  [MENU_ITEMS.IMAGES]: __CFG__.SOURCE_FILES_TYPES.IMAGES,
  // [MENU_ITEMS.VIDEOS]: __CFG__.SOURCE_FILES_TYPES.VIDEOS,
}

const ENABLED_ICONS = ICONS_ORDER.filter(type => ICONS_ENABLED_STATUS[type] === true)

// ---

function MenuIcon({ type }) {
  switch (type) {
    case MENU_ITEMS.MEDIA:
      return <MediaIcon />
    case MENU_ITEMS.TRANSITIONS:
      return <TransitionIcon />
    case MENU_ITEMS.TEXT:
      return <TextIcon />
    case MENU_ITEMS.AUDIO:
      return <SoundIcon />
    case MENU_ITEMS.FILTERS:
      return <FiltersIcon />
    case MENU_ITEMS.DESIGN:
      return <BrushIcon />
    case MENU_ITEMS.IMAGES:
      return <ImagesIcon />
    case MENU_ITEMS.VIDEOS:
      return <VideosIcon />
    default:
      throw new Error(`Can't find ${type} icon`)
  }
}

function getMenuItemClassName(type) {
  switch (type) {
    case MENU_ITEMS.MEDIA:
      return 'menu-item__media'
    case MENU_ITEMS.TRANSITIONS:
      return 'menu-item__transitions'
    case MENU_ITEMS.TEXT:
      return 'menu-item__textpanel'
    case MENU_ITEMS.AUDIO:
      return 'menu-item__audiopanel'
    case MENU_ITEMS.FILTERS:
      return 'menu-item__filters'
    case MENU_ITEMS.DESIGN:
      return 'menu-item__design'
    case MENU_ITEMS.IMAGES:
      return 'menu-item__images'
    case MENU_ITEMS.VIDEOS:
      return 'menu-item__videos'
    default:
      throw new Error(`Can't find ${type} className`)
  }
}

function WebEditorMenu() {
  const openMenuChapter = useAction(Actions.mainView.openChapter)
  const { menuItem } = useSelector(state => state.mainView)
  // issue 3166: close properties should return to opened item tab
  const activeItem = useMemo(getChapterFromStorage, [ menuItem ])

  // issue 3189: don't show images item in the menu if lib sources does not exist
  const { imageLibSources } = useSelector(state => selectSourceFilesParams(state,
    SOURCE_FILE_TYPES.IMAGES))
  const enabledIcons = useMemo(() => ENABLED_ICONS.filter(i => {
    if (i === MENU_ITEMS.IMAGES) {
      return !isEmpty(imageLibSources)
    }
    return true
  }),
  [ imageLibSources ])

  useEffect(() => {
    if (activeItem === MENU_ITEMS.IMAGES && imageLibSources !== null
       && isEmpty(imageLibSources)) {
      openMenuChapter(MENU_ITEMS.MEDIA)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ imageLibSources ])

  const { t } = useContext(TranslationContext)

  function MenuText(type) {
    switch (type) {
      case MENU_ITEMS.MEDIA:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_MY_MEDIA',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_MY_MEDIA'),
          title: t('LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP'),
        }
      case MENU_ITEMS.TRANSITIONS:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_TRANSITIONS',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_TRANSITIONS'),
          title: t('LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP'),
        }
      case MENU_ITEMS.IMAGES:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_IMAGES',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_IMAGES'),
          title: t('LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP'),
        }
      case MENU_ITEMS.TEXT:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_TEXT',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_TEXT'),
          title: t('LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP'),
        }
      case MENU_ITEMS.AUDIO:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_AUDIO',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_AUDIO'),
          title: t('LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP'),
        }
      case MENU_ITEMS.FILTERS:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_FILTERS',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_FILTERS'),
          title: t('LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP'),
        }
      case MENU_ITEMS.DESIGN:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_DESIGN',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_DESIGN'),
          title: t('LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP'),
        }
      case MENU_ITEMS.VIDEOS:
        return {
          dataLangId: 'LEFT_TOOLBAR_MENU_BTN_VIDEOS',
          menuText: t('LEFT_TOOLBAR_MENU_BTN_VIDEOS'),
          title: t('LEFT_TOOLBAR_MENU_BTN_VIDEOS_TOOLTIP'),
        }
      default:
        return {
          dataLangId: t(type?.toUpperCase()),
          menuText: t(type?.toUpperCase()),
          title: t(type?.toUpperCase()),
        }
    }
  }

  const customizationView = useSelector(state => state.mainView.showCustomizeView)

  const showSelected = !customizationView

  return (
    <div className="menu-tabs">
      <EditorMenu />
      <Scrollbars trackHorizontalSize={0} trackSize={6} className="menu-tabs__scrollbar">
        <List disablePadding>
          {enabledIcons.map(iconType => {
            const currentIcon = MenuText(iconType)
            return (
              <ListItem
                key={iconType}
                button
                disableGutters
                selected={activeItem === iconType && showSelected}
                onClick={() => openMenuChapter(iconType)}
                className={getMenuItemClassName(iconType)}
                data-lang-id={currentIcon.dataLangId}
                title={currentIcon.title}
              >
                <ListItemIcon>
                  <MenuIcon type={iconType} />
                </ListItemIcon>
                <ListItemText primary={currentIcon.menuText} />
              </ListItem>
            )
          })}
        </List>
      </Scrollbars>
    </div>
  )
}

export default memo(WebEditorMenu)
