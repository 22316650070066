import React, { memo } from 'react'

import { LAYER_ASSET_HEIGHT } from 'constant'
import { DRAGNDROP_TYPE } from '~/enums'
import { getItemStyles } from './getItemStyles'
import styles from './draggingAsset.module.scss'

import { useTimelineSnapping } from './lib/useTimelineSnapping'
import { getExternalSourceElement } from './lib/getExternalSourceElement'
import { getSourceItemSourceOffset } from './lib/getSourceItemSourceOffset'
import { useDraggingAsset } from './lib/useDraggingAsset'
import { useMultipleDragging } from './lib/useMultipleDragging'
import { useInitialDragData } from './lib/useInitialDragData'
import { TimelineScrollPositionContext } from '~/components/Timeline/ScrollPositionContext'
import { SnappedPreview } from './SnappedPreview'
import { CustomDragLayerContext } from '~/components/CustomDragLayerContext'


// Dragged item should change it's look when it's over layer.
export const DraggingAsset = memo(({
  overNewLayerIndex,
}) => {
  const {
    item,
    clientOffset,
    sourceOffset,
    initialClientOffset,
    initialSourceOffset,
  } = React.useContext(CustomDragLayerContext)
  const {
    localTime,
    setSliderSticky,
    timelineMouseDown,
  } = React.useContext(TimelineScrollPositionContext)

  const {
    isDraggingItemOverLayer,
    isOverItem,
    detachedMode,
    scale,
    timelineTopOffset,
    scrollTop,
    timelineLeftOffset,
    scrollLeft,
    isMultipleDrag,
    targetLayerIndex,
    restElementsByLayers,
    dragItem,
    dragAssetsWidth,
    sliderTimeX,
  } = useInitialDragData({ item, clientOffset, localTime })

  const {
    draggingAsset,
    offset,
    draggingAssetWidth,
    isExternalSource,
  } = useDraggingAsset({ dragItem, scale })

  const {
    draggableBlockLeftX,
    draggableBlockY,
    multipleDragAssets,
    transitions,
    multipleSnappedPreview,
  } = useMultipleDragging({
    dragItem,
    clientOffset,
    sourceOffset,
    initialClientOffset,
    scale,
    overNewLayerIndex,
    timelineTopOffset,
    scrollTop,
    targetLayerIndex,
    timelineLeftOffset,
    scrollLeft,
    isMultipleDrag,
    sliderTimeX,
    dragAssetsWidth,
    setSliderSticky,
    timelineMouseDown,
  })

  const currentSourceOffset = isExternalSource
    ? getSourceItemSourceOffset(clientOffset, draggingAssetWidth, dragItem, detachedMode)
    : sourceOffset


  const { x, y, intersection, snappedPreview } = useTimelineSnapping({
    item: dragItem,
    clientOffset,
    sourceOffset: currentSourceOffset,
    initialClientOffset,
    initialSourceOffset,
    width: draggingAssetWidth,
    ignoredTypes: [ DRAGNDROP_TYPE.TRANSITION_ITEM, DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET ],
    offset,
    isDraggingItemOverLayer,
    overNewLayerIndex,
    targetLayerIndex,
    restElementsByLayers,
    timelineTopOffset,
    scrollTop,
    timelineLeftOffset,
    scrollLeft,
    isMultipleDrag,
    sliderTimeX,
    dragAssetsWidth,
    setSliderSticky,
    timelineMouseDown,
  })

  const showSnappedPreview = !overNewLayerIndex
  && ((intersection
  && !isOverItem.size
  && snappedPreview?.width < draggingAssetWidth)
  || (multipleSnappedPreview?.width && isMultipleDrag))

  const currentX = isMultipleDrag ? draggableBlockLeftX : x
  const currentY = isMultipleDrag ? draggableBlockY : y
  const currentDragAssets = isMultipleDrag ? multipleDragAssets : draggingAsset

  const currentSnappedPreviewX = isMultipleDrag ? multipleSnappedPreview.x : snappedPreview?.x
  const currentSnappedPreviewY = isMultipleDrag ? multipleSnappedPreview.y : snappedPreview?.y
  const currentSnappedPreviewWidth = isMultipleDrag
    ? multipleSnappedPreview.width
    : snappedPreview?.width

  return (
    <div style={{ position: 'relative' }}>
      {
      !isExternalSource
        ? (
          <SnappedPreview
            scale={scale}
            currentDragAssets={currentDragAssets}
            draggingAssetWidth={draggingAssetWidth}
            snappedPreview={snappedPreview}
            visible={!!clientOffset}
            currentX={currentX}
            currentY={currentY}
            isMultipleDrag={isMultipleDrag}
            offset={offset}
            dragItem={dragItem}
            transitions={transitions}
          />
        )
        : (
          <>
            {(!detachedMode && dragItem.type !== DRAGNDROP_TYPE.TRANSITION_ITEM)
              ? (
                <SnappedPreview
                  scale={scale}
                  currentDragAssets={currentDragAssets}
                  draggingAssetWidth={draggingAssetWidth}
                  snappedPreview={snappedPreview}
                  visible={!!clientOffset}
                  currentX={currentX}
                  currentY={currentY}
                  isMultipleDrag={isMultipleDrag}
                  offset={offset}
                  dragItem={dragItem}
                  transitions={transitions}
                />
              )
              : (
                <div style={{ ...getItemStyles({
                  clientOffset,
                  height: LAYER_ASSET_HEIGHT,
                  x,
                  y,
                }),
                position: 'relative' }}
                >
                  {getExternalSourceElement(dragItem, currentDragAssets, styles)}
                </div>
              )}
          </>
        )
      }
      {showSnappedPreview ? (
        <div
          className={styles.previewItem}
          style={{ position: 'absolute',
            top: '2px',
            ...getItemStyles({ x: currentSnappedPreviewX,
              clientOffset,
              width: currentSnappedPreviewWidth,
              y: currentSnappedPreviewY,
              height: LAYER_ASSET_HEIGHT }) }}
        />
      ) : null}
    </div>
  )
})
