import List from 'components/AssetPanels/BaseAssetsList'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import React, { useCallback, useContext, useMemo } from 'react'

import { setSourceFilesParams as setSourceFilesParamsAction } from 'actions/sourceFiles'
import { useSelector } from 'react-redux'

import classNames from 'classnames'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import Input from 'components/base/Input'
import { IMAGES_SOURCES, SOURCE_FILE_TYPES } from 'enums'
import { useAction } from 'hooks'
import { INITIAL_CATEGORY } from 'reducers/sourceFiles'
import {
  getIsSourceFilesLoading,
  selectSourceFilesParams,
  selectTotalCount
} from 'selectors'
import { TranslationContext } from 'contexts/TranslationContext'
import LoadButton from '../Media/LoadButton'
import Header from './Header'
import Item from './ListItem'

import { ReactComponent as NativeImagesIcon } from '~/assets/icons/native-images.svg'
import { ReactComponent as PexelsIcon } from '~/assets/icons/pexels.svg'
import { ReactComponent as UnsplashIcon } from '~/assets/icons/unsplash.svg'

import SearchMenu from '../Audio/SearchMenu'
import { useCategoryFetch } from './hooks/useCategoryFetch'
import styles from './list.module.scss'

const sourceIcons = {
  [IMAGES_SOURCES.Native]: <NativeImagesIcon />,
  [IMAGES_SOURCES.Pexels]: <PexelsIcon />,
  [IMAGES_SOURCES.Unsplash]: <UnsplashIcon />,
}

const useSourceItems = () => {
  const { t } = useContext(TranslationContext)

  const sourceTexts = {
    [IMAGES_SOURCES.Native]: t('IMAGES_TEXT_NATIVE_IMAGES'),
    [IMAGES_SOURCES.Pexels]: t('IMAGES_TEXT_PEXELS'),
    [IMAGES_SOURCES.Unsplash]: t('IMAGES_TEXT_UNSPLASH'),
  }

  function getSourceItem(type) {
    return {
      id: type,
      text: (
        <>
          {sourceIcons[type]}
          {' '}
          <span className={styles.dropdownItemText}>{sourceTexts[type]}</span>
        </>
      ),
    }
  }

  const allSourceItems = [
    {
      ...getSourceItem(IMAGES_SOURCES.Native),
    },
    {
      ...getSourceItem(IMAGES_SOURCES.Pexels),
    },
    {
      ...getSourceItem(IMAGES_SOURCES.Unsplash),
    },
  ]

  return { allSourceItems, sourceTexts }
}

export default function ImageAssetsList(props) {
  const { type } = props
  const { t } = useContext(TranslationContext)

  /* const imageTabs = [
    { id: 'search', name: t('IMAGES_TAB_SEARCH') },
    { id: 'ai', name: t('IMAGES_TAB_AI_GENERATED') },
  ] */

  const setSourceFilesParams = useAction(setSourceFilesParamsAction, type)

  const handleSearch = useCallback(
    search => {
      setSourceFilesParams({ search })
    },
    [ setSourceFilesParams ]
  )

  // const [ currentTab, setCurrentTab ] = useState(imageTabs[0].id)

  const {
    search = '',
    category,
    source,
    imageLibSources,
  } = useSelector(state => selectSourceFilesParams(state, SOURCE_FILE_TYPES.IMAGES))

  const { allSourceItems, sourceTexts } = useSourceItems()

  const sourceItems = useMemo(
    () => allSourceItems.filter(s => imageLibSources?.includes(s.id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ imageLibSources ]
  )

  const { id: categoryId, name } = category

  const itemsLoading = useSelector(state => getIsSourceFilesLoading(state, type))
  const totalCount = useSelector(state => selectTotalCount(state, type))

  const categories = useCategoryFetch(source)

  function handleSearchMenuSelect(categoryId, name) {
    setSourceFilesParams({ category: { id: categoryId, name } })
  }

  /* function handleTabChange(item) {
    setCurrentTab(item)
  } */

  function handleClickSource(source) {
    setSourceFilesParams({ source, category: INITIAL_CATEGORY })
  }

  return (
    <List
      {...props}
      header={(
        <>
          <Header caption={t('IMAGES_HEADER_CAPTION')} type={type} />
          <If condition={__CFG__.IMAGES_AI_SOURCE}>
            {/* <Navbar
              items={imageTabs}
              selected={currentTab}
              onSelect={handleTabChange}
            /> */}
          </If>
          <div className={styles.container}>
            <div className={styles.multiFilter}>
              <SearchInput
                value={search}
                onSearch={handleSearch}
                className={classNames('search_input', styles.imagesSearch, {
                  [styles.imagesSearchCombo]: sourceItems.length > 1,
                })}
                placeholder={`${t('IMAGES_PLACEHOLDER_SEARCH_IN')} ${name}`}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px', columnGap: '14px' }}>
              <If condition={sourceItems.length > 1}>
                <DropdownMenu
                  emitFromContent
                  dropdownClassName={styles.dropdownContainer}
                  className={styles.dropdownSource}
                  menuItems={sourceItems}
                  onClick={handleClickSource}
                >
                  <Input
                    startAdornment={sourceIcons[source]}
                    className={styles.sourceInput}
                    value={sourceTexts[source]}
                    readOnly
                  />
                </DropdownMenu>
              </If>
              <SearchMenu
                items={categories}
                includesAll
                onSelect={handleSearchMenuSelect}
                category={categoryId}
                search=""
                placeholder={t('IMAGES_PLACEHOLDER_SELECT_COLLECTION')}
                className={styles.inputSearch}
              />
            </div>
          </div>
          {search !== '' && !itemsLoading && !!totalCount && (
            <div className={styles.counter}>
              Found items:
              {totalCount}
            </div>
          )}
        </>
      )}
      ItemComponent={props => <Item {...props} />}
      placeholder={(
        <div className={styles.placeholder}>
          <div className={styles.header}>{t('IMAGES_TEXT_NO_RESULTS_FOUND')}</div>
          <div className={styles.desc}>
            {t('IMAGES_TEXT_CANNOT_FIND_RESULTS')}
          </div>
        </div>
      )}
      after={
        __CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL ? null : (
          <LoadButton type={type} />
        )
      }
    />
  )
}
