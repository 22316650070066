import React, { memo } from 'react'
import { getDraggingElement } from './lib/getDraggingElement'

/* See TransitionAsset style to clarify this div wrapper */
export const DraggingElem = memo(({
  isMultipleDrag,
  offset,
  dragItem,
  currentDragAssets,
  scale,
  transitions,
}) => (
  <div style={{
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: `${isMultipleDrag ? 0 : offset}px`,
  }}
  >
    {getDraggingElement(dragItem, currentDragAssets, scale, transitions)}
  </div>
))
