import React, { FC, memo, useContext } from 'react'
import { PLAYER_TYPE } from 'enums'
import { useMediaPreviewContainer } from 'hooks/preview/useMediaPreviewContainer'
import { TranslationContext } from 'contexts/TranslationContext'
import { PreviewModeType } from 'types/preview'
import { PreviewPlayerHead } from '../PreviewPlayerHead'
import MediaPreview from './MediaPreview'

interface IProps {
  isSplitted: boolean,
  activeMode: PreviewModeType,
}

export const MediaPreviewContainer:FC<IProps> = memo(({ isSplitted, activeMode }) => {
  const { mediaPreviewProps, name, onSelect } = useMediaPreviewContainer()
  const { t } = useContext(TranslationContext)

  return (
    <div className="preview-container__item head" onClick={onSelect(PLAYER_TYPE.MEDIA)}>
      <PreviewPlayerHead
        type={t('PREVIEW_HEADER_TITLE_SOURCE')}
        name={name}
        playerType={PLAYER_TYPE.MEDIA}
        showSplitter={false}
        isSplitted={isSplitted}
        activeMode={activeMode}
      />
      <MediaPreview
        {...mediaPreviewProps}
      />
    </div>
  )
})
