import { useDrop } from 'react-dnd'
import { DRAGNDROP_TYPE } from 'enums'

export const useDropPreviewClipCreator = () => (
  useDrop({
    accept: DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM,
    canDrop: () => false,
    collect: monitor => ({
      isHovered: monitor.isOver(),
      item: monitor.getItem(),
    }),
  })
)
