import React from 'react'
import { useSelector } from 'react-redux'
import { MovableModalContainer } from 'components/MovableModal'
import { selectShowCustomizeView } from 'selectors/mainView'
import { CustomizationHotkeys } from './CustomizationHotkeys'

export const CustomizationHotkeysContainer = () => {
  const customizationView = useSelector(selectShowCustomizeView)
  return (
    <MovableModalContainer visible={customizationView === 'hotkeys'}>
      <CustomizationHotkeys />
    </MovableModalContainer>
  )
}
