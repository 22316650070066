import React, { FC, memo, useContext } from 'react'
import { PLAYER_TYPE } from 'enums'
import { useTimelinePreviewContainer } from 'hooks/preview/useTimelinePreviewContainer'
import { TranslationContext } from 'contexts/TranslationContext'
import { PreviewModeType } from 'types/preview'
import { PreviewPlayerHead } from '../PreviewPlayerHead'
import TimelinePreview from './TimelinePreview'

interface IProps {
  isSplitted: boolean,
  activeMode: PreviewModeType,
}

export const TimelinePreviewContainer:FC<IProps> = memo(({ isSplitted, activeMode }) => {
  const { timelinePreviewProps, onSelect } = useTimelinePreviewContainer()
  const { t } = useContext(TranslationContext)

  return (
    <div className="preview-container__item head" onClick={onSelect(PLAYER_TYPE.TIMELINE)}>
      <PreviewPlayerHead
        type={t('PREVIEW_HEADER_TITLE_TIMELINE')}
        playerType={PLAYER_TYPE.TIMELINE}
        isSplitted={isSplitted}
        activeMode={activeMode}
        showSplitter
      />
      <TimelinePreview
        {...timelinePreviewProps}
      />
    </div>
  )
})
