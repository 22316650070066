import React, { HTMLAttributes, memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'

import MediaItem from 'components/AssetPanels/Media/ListItem'
import TextItem from 'components/AssetPanels/Text/ListItem'
import TransitionItem from 'components/AssetPanels/Transition/ListItem'
import { DRAGNDROP_TYPE } from 'enums'
import DraggingGroup from 'components/AssetPanels/components/DraggingGroup'
import * as Selectors from 'selectors'
import { CustomDragLayerContext } from 'components/CustomDragLayerContext'

const layerStyles: HTMLAttributes<HTMLDivElement>['style'] = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 61,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'block',
}

const CustomDragLayer = memo(() => {
  const dndDropTarget = useSelector((state: RootState) => state.mainView.dndDropTarget)
  const { isDragging, clientOffset, item, itemType } = useContext(CustomDragLayerContext)
  if (dndDropTarget !== 'root' || !isDragging || clientOffset?.x === undefined) {
    return null
  }

  switch (itemType) {
    case DRAGNDROP_TYPE.GROUP:
      return (
        <DraggingGroup
          item={item.selectedItems.length}
          clientOffset={clientOffset}
        />
      )
    case DRAGNDROP_TYPE.MEDIA_ITEM:
    case DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM:
    case DRAGNDROP_TYPE.FOLDER:
    case DRAGNDROP_TYPE.TEXT_ITEM:
    case DRAGNDROP_TYPE.AUDIO_ITEM:
    case DRAGNDROP_TYPE.IMAGE_ITEM:
    case DRAGNDROP_TYPE.TRANSITION_ITEM: {
      return (
        <div style={{ transform: `translate(${clientOffset?.x - 0.5 * 184}px, ${clientOffset?.y - 0.5 * 112}px)` }}>
          <MediaDraggingItem key={item.id} item={item} itemType={itemType} />
        </div>
      ) }
    default: return null
  }
})

const CustomDragLayerContainer = memo(() => ReactDOM.createPortal(
  <div style={{ ...layerStyles }}>
    <CustomDragLayer />
  </div>, document.body
))


const MediaDraggingItem = memo(({ item, itemType }: { item: { id: string }, itemType: string }) => {
  const draggingFile = useSelector(state => Selectors.getSourceFileById(state, item.id))
  switch (itemType) {
    case DRAGNDROP_TYPE.MEDIA_ITEM:
    case DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM:
    case DRAGNDROP_TYPE.FOLDER:
      return (
        <MediaItem
          asset={draggingFile}
          thumbBorder={itemType === DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM}
          asDragPreview
        />
      )
    case DRAGNDROP_TYPE.TEXT_ITEM:
      return <TextItem asset={draggingFile} asDragPreview />

    case DRAGNDROP_TYPE.AUDIO_ITEM:
      return <MediaItem asset={draggingFile} asDragPreview />

    case DRAGNDROP_TYPE.IMAGE_ITEM:
      return <MediaItem asset={draggingFile} asDragPreview />

    case DRAGNDROP_TYPE.TRANSITION_ITEM:
      return <TransitionItem asset={draggingFile} asDragPreview />

    default: return null
  }
})

export default CustomDragLayerContainer
