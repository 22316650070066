import { setDuration, setFullScreen, setResizableParams } from 'actions/preview'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectorPreview, selectPreviewLoading } from 'selectors/preview'
import { setPlayerVolume as setPlayerVolumeAction } from 'actions/mainView'
import { selectPlayerVolume } from 'selectors'
import { selectIsBuffering } from 'selectors/playback'

export default function usePreviewState({ isMedia }: { isMedia?: boolean }) {
  const dispatch = useDispatch()
  const volume = useSelector(selectPlayerVolume) as number
  const isLoading = useSelector(selectPreviewLoading)
  const isBuffering = useSelector(selectIsBuffering)
  const { width, height, duration, fullScreen } = useSelector(selectorPreview)

  const onChangeDuration = useCallback(x => {
    dispatch(setDuration(x))
  }, [ dispatch ])

  const onChangeVolume = useCallback(e => {
    dispatch(setPlayerVolumeAction(e))
  }, [ dispatch ])

  const onCloseFullscreen = useCallback(() => {
    dispatch(setFullScreen(false))
  }, [ dispatch ])

  const onToggleFullscreen = useCallback(() => {
    dispatch(setFullScreen())
  }, [ dispatch ])

  const onToggleMute = useCallback(() => {
    dispatch(setPlayerVolumeAction(volume ? 0 : 1))
  }, [ dispatch, volume ])

  const onResize = useCallback(({ width, height }) => {
    if (!isMedia) {
      dispatch(setResizableParams({ width, height }))
    }
  }, [ dispatch, isMedia ])

  const muted = volume === 0

  const params = useMemo(() => (
    { width, height, duration, fullScreen, volume, muted }
  ), [ width, height, duration, fullScreen, volume, muted ])

  return {
    onChangeDuration,
    onChangeVolume,
    onCloseFullscreen,
    onResize,
    onToggleFullscreen,
    onToggleMute,
    params,
    isLoading: isLoading || isBuffering,
  }
}
