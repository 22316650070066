import classNames from 'classnames'
import React, { FC, useRef } from 'react'
import './SourcePreview.scss'

type TextSettingsType = {
  text: string,
  fontSize: number,
  outlineWidth: number,
  outlineColor: { r: number, g: number, b: number, a: number },
}

interface IProps {
  styles: Record<string, string>,
  isTextAsset: boolean,
  textSettings: TextSettingsType,
}

export const SourceTextPreview: FC<IProps> = ({
  styles = {},
  isTextAsset,
  textSettings = {} as TextSettingsType,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)
  const ratio = 16 / 9

  const { offsetWidth: innerOffsetWidth } = innerRef.current ?? {}
  const {
    offsetWidth: containerOffsetWidth,
    offsetHeight: containerOffsetHeight,
  } = containerRef.current ?? {}

  const height = (containerOffsetWidth ?? 0) / ratio
  const width = (containerOffsetHeight ?? 0) * ratio
  const maxWidth = ((containerOffsetHeight ?? 0) * ratio) <= (innerOffsetWidth ?? 0)
    ? width
    : '100%'


  return (
    <div
      ref={containerRef}
      style={{ ...styles }}
      className={classNames('source-preview-text player', { 'display-none': !isTextAsset })}
      draggable
    >
      <div
        ref={innerRef}
        className="source-preview-text__body"
        style={{
          width: maxWidth || '100%',
          maxHeight: height,
          fontSize: textSettings.fontSize || 24,
        }}
      >
        {textSettings.text}
      </div>
    </div>
  )
}
