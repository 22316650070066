import { useEffect } from 'react'
import { getFPSByRefVideo, n, nnn, timeToFractions } from 'Util'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { UNIT_MEASUREMENT } from 'config/constants/timecode'
import { selectTimeCodeMode } from 'selectors/playback'
import { TimeCodeContainerLocationType, TimeCodeModeType } from 'types/timecode'
import { useTimeCodeState } from './useTimeCodeState'
import { useTimeCodeEventListeners } from './useTimeCodeEventListeners'
import { useTimeCodeRefs } from './useTimeCodeRefs'
import { useContextMenu } from './useContextMenu'

type UseTimeCodeType = {
  progress: number
  containerLocation: TimeCodeContainerLocationType
  defaultTimeCodeMode: TimeCodeModeType
  onRewindTimeline?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps?: number
  readonly?: boolean
}

export const useTimeCode = ({
  progress,
  containerLocation,
  defaultTimeCodeMode,
  onRewindTimeline,
  onMoveSlider,
  fps,
  readonly,
}: UseTimeCodeType) => {
  const refVideo = useSelector(getReferenceVideoAsset)
  const currentFps = fps || getFPSByRefVideo(refVideo)
  const timeMode = useSelector(selectTimeCodeMode)

  const { timeCodeRefs, timeCodeContainer } = useTimeCodeRefs()
  const {
    timeUnits,
    isActiveContainer,
    onDeactivateContainer,
    onActiveUnit,
    onKeyDown,
    setTimeUnits,
    onChangeMode,
    onContainerArrowsIncrementControl,
    onChangeUnit,
  } = useTimeCodeState({
    containerLocation,
    defaultTimeCodeMode,
    progress,
    onRewindTimeline,
    onMoveSlider,
    fps: currentFps,
    readonly,
    timeMode,
  })

  const timeCode = timeMode?.[containerLocation]
  // Use for change time code format for splitted preview containers, because the states are different for each player
  useEffect(() => {
    if (timeCode) {
      onChangeMode(timeCode)
    }
  }, [ timeCode, containerLocation, onChangeMode ])

  useEffect(() => {
    const {
      hours,
      minutes,
      seconds,
      milliseconds,
    } = timeToFractions(progress)
    setTimeUnits(units => units.map(prevUnit => {
      const unit = { ...prevUnit }
      switch (unit.id) {
        case UNIT_MEASUREMENT.HOUR: unit.value = n(hours); break
        case UNIT_MEASUREMENT.MINUTE: unit.value = n(minutes); break
        case UNIT_MEASUREMENT.SECOND: unit.value = n(seconds); break
        case UNIT_MEASUREMENT.MILLISECOND: unit.value = nnn(milliseconds); break
        case UNIT_MEASUREMENT.FRAME: {
          const newFrame = milliseconds / (1000 / currentFps)
          const diff = newFrame - Math.trunc(newFrame)
          unit.value = diff > 0.55 ? n(Math.ceil(newFrame)) : n(Math.floor(newFrame))
        }
          break
      }
      return unit
    }))
  }, [ progress, currentFps, setTimeUnits, timeMode ])

  useEffect(() => {
    const editUnit = timeUnits.find(unit => unit.editableDigit)
    if (editUnit?.editableDigit) {
      timeCodeRefs[editUnit.id][editUnit.editableDigit]?.current?.focus()
    }
  }, [ timeUnits, timeCodeRefs ])

  useTimeCodeEventListeners({
    timeCodeContainer,
    onActiveUnit,
    onKeyDown,
    onDeactivateContainer,
  })

  const {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  } = useContextMenu({ container: timeCodeContainer.current })

  return {
    onContainerArrowsIncrementControl,
    unitMeasurementFormats: [ UNIT_MEASUREMENT.MILLISECOND, UNIT_MEASUREMENT.FRAME ],
    timeCodeContainer,
    timeCodeRefs,
    isActiveContainer,
    timeUnits,
    onChangeMode,
    timeMode: timeMode[containerLocation],
    onChangeUnit,
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  }
}
