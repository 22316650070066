import { EMPTY_STRING } from 'constant'
import { DEFAULT_HOTKEYS } from 'enums'
import ucFirst from 'Util/text/ucFirst'

export const MARK_TYPE = {
  IN: 'markIn',
  OUT: 'markOut',
  DRAG: 'drag',
  CLEAR_IN_OUT: 'clearInOutPoints',
} as const

export const CLIP_CREATOR_CONTEXT_MENU = {
  [MARK_TYPE.IN]: {
    TITLE: 'Mark In',
    HOTKEY: DEFAULT_HOTKEYS.MARK_IN.replace('Key', EMPTY_STRING),
    CALLBACK_NAME: `on${ucFirst(MARK_TYPE.IN)}`,
  },
  [MARK_TYPE.OUT]: {
    TITLE: 'Mark Out',
    HOTKEY: DEFAULT_HOTKEYS.MARK_OUT.replace('Key', EMPTY_STRING),
    CALLBACK_NAME: `on${ucFirst(MARK_TYPE.OUT)}`,
  },
  [MARK_TYPE.CLEAR_IN_OUT]: {
    TITLE: 'Clear In/out points',
    HOTKEY: DEFAULT_HOTKEYS.CLEAR_IN_OUT_MARK_POINTS.replace('Key', EMPTY_STRING),
    CALLBACK_NAME: `on${ucFirst(MARK_TYPE.CLEAR_IN_OUT)}`,
  },
} as const

export const PREVIEW_MODE = {
  AUTO: 'auto',
  SOURCE: 'source',
  TIMELINE: 'timeline',
} as const

export const SPLIT_PREVIEW_BUTTONS = [
  { name: 'PREVIEW_MODE_AUTO', mode: PREVIEW_MODE.AUTO },
  { name: 'PREVIEW_MODE_SOURCE', mode: PREVIEW_MODE.SOURCE },
  { name: 'PREVIEW_MODE_TIMELINE', mode: PREVIEW_MODE.TIMELINE },
]
