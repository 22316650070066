import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import { Box } from '@material-ui/core'
import RawButton from 'components/base/RawButton'
import React, { FC, memo, useContext } from 'react'
import Fade from '@material-ui/core/Fade'
import { TranslationContext } from 'contexts/TranslationContext'
import { isRecordingSaving, isRecordingStarted } from 'selectors/recording'
import usePreviewState from 'hooks/preview/usePreviewState'
import Slider from 'components/base/Slider'
import { PreviewPlayerContext } from 'contexts/PreviewPlayerContext'
import { ReactComponent as FullscreenIcon } from '~/assets/video-player/ic_fullscreen.svg'
import { ReactComponent as UnmutedIcon } from '~/assets/video-player/ic_volume.svg'
import { ReactComponent as MutedIcon } from '~/assets/video-player/ic_volume_off.svg'
import './PreviewPlayerToolbarRight.scss'


export const PreviewPlayerToolbar: FC = memo(() => {
  const { t } = useContext(TranslationContext)
  const { handleFullscreen } = useContext(PreviewPlayerContext)
  const mediaRecording = useSelector(isRecordingStarted)
  const isSaving = useSelector(isRecordingSaving)
  const disabledCtrls = Boolean(mediaRecording || isSaving)
  const {
    onChangeVolume,
    onToggleMute,
    params: { volume, muted },
  } = usePreviewState({})

  return (
    <Box display="flex" alignItems="baseline">
      <div className="preview-player-toolbar__item" title={t('PREVIEW_BTN_VOLUME_TOOLTIP')}>
        <Tooltip
          classes={{
            tooltip: 'preview-player-toolbar__volume-tooltip',
          }}
          TransitionComponent={Fade}
          placement="top"
          disableHoverListener={disabledCtrls}
          disableFocusListener={disabledCtrls}
          disableTouchListener={disabledCtrls}
          interactive
          title={(
            <div className="preview-player-toolbar__volume">
              <Slider
                value={muted ? 0 : volume}
                onChange={onChangeVolume}
                orientation="vertical"
                min={0}
                max={1}
                step={0.1}
              />
            </div>
            )}
        >
          <RawButton onClick={onToggleMute} dataLangId="PREVIEW_BTN_VOLUME">
            <span>
              {muted ? <MutedIcon /> : <UnmutedIcon />}
            </span>
          </RawButton>
        </Tooltip>
      </div>

      <div className="preview-player-toolbar__item" title={t('PREVIEW_BTN_FULL_SCREEN_TOOLTIP')}>
        <RawButton onClick={handleFullscreen} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_FULL_SCREEN">
          <FullscreenIcon />
        </RawButton>
      </div>
    </Box>
  )
})
