import { TranslationContext } from 'contexts/TranslationContext'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { TIME_CODE_STATE } from 'config/constants/timecode'
import { DEFAULT_FPS, RangeTools } from '~/Util'
import * as Actions from '~/actions'
import { ReactComponent as IconRendering } from '~/assets/video-player/ic_rendering.svg'
import { ReactComponent as IconUpload } from '~/assets/video-player/ic_upload.svg'
import { FILE_STATUS, PLAYBACK_STATE } from '~/enums'
import { useAction, useBind } from '~/hooks'
import { useDnDPreview } from '~/hooks/preview/dnd/useDnDPreview'
import BasePreviewPlayer from '../BasePreviewPlayer'
import Preview from '../Preview'
import { usePlayer } from '../lib'
import usePlayerState from '../lib/usePlayerState'
import useMediaPreviewState from '../useMediaPreviewState'
import { getFrameStep } from '~/helpers/getFrameStep'
import { SourceImagePreview } from './sourcePreview/SourceImagePreview'
import { SourceTextPreview } from './sourcePreview/SourceTextPreview'

// ---

function useFrameStepHandlers(fps, {
  seek,
  usePlayerCallback,
  previewPlayerType,
}) {
  const pause = useAction(Actions.playback.changeClipPlaybackState, PLAYBACK_STATE.PAUSE)
  const { timeCodeState } = useSelector(state => state.playback)
  const activePreview = useSelector(state => state.preview.activePreview)

  const onFrameStep = usePlayerCallback(React.useCallback(
    (player, forward) => {
      if (timeCodeState !== TIME_CODE_STATE.EDIT
        && (previewPlayerType === activePreview)) {
        const progress = player.getCurrentTime()
        const duration = player.getDuration()
        const { forwardStep, backStep } = getFrameStep({ progress, fps })
        const offset = forward ? forwardStep : backStep
        const time = RangeTools.fitTo([ 0, duration ], Number((progress + offset).toFixed(3)))

        pause()
        seek(time)
      }
    },
    [ fps, pause, seek, timeCodeState, previewPlayerType, activePreview ]
  ))

  const onNextFrame = useBind(onFrameStep, true)
  const onPrevFrame = useBind(onFrameStep, false)

  return {
    onNextFrame,
    onPrevFrame,
  }
}

// ---

function MediaPreview(props) {
  const { srcVideo, fps, previewPlayerType, ...rest } = props
  const [ onSetPlayer, { usePlayerCallback, seek, getState }] = usePlayer()
  const [ , forceUpdate ] = React.useReducer(x => x + 1, 0)
  const { imageUrl, status, error, isTextAsset } = props
  const pause = useAction(Actions.playback.changeClipPlaybackState,
    PLAYBACK_STATE.PAUSE)
  const onBufferingProgress = React.useCallback(forceUpdate, [ ])

  const [
    { duration, progress },
    { onChangeDuration, onChangeProgress },
  ] = useMediaPreviewState({ seek })

  usePlayerState(getState)

  // ---

  const { onNextFrame, onPrevFrame } = useFrameStepHandlers(fps, {
    seek,
    usePlayerCallback,
    previewPlayerType,
  })

  const { t } = useContext(TranslationContext)

  const uploading = error === undefined
    && (status === FILE_STATUS.UPLOAD
      || status === FILE_STATUS.IMPORT
      || status === FILE_STATUS.S3_UPLOAD
      || status === FILE_STATUS.CONVERT
      || (__APP_PROFILE__ === 'package' && status === FILE_STATUS.PREPARE_THUMBNAILS))

  const rendering = error === undefined
    && (status === FILE_STATUS.RENDER)
  const { setDraggable } = useDnDPreview({ isMedia: rest?.isMedia, asset: rest?.asset })
  const showPlaceholder = !srcVideo && !isTextAsset && !imageUrl

  return (
    <>
      <Choose>
        <When condition={uploading || rendering}>
          <div className="preview-placeholder-base">
            <div className="preview-placeholder-base__logo-importing">
              <If condition={uploading}>
                <IconUpload />
              </If>

              <If condition={rendering}>
                <IconRendering />
              </If>
            </div>

            <div className="preview-placeholder-base__text-importing">
              <If condition={uploading}>
                {t('MEDIA_PREVIEW_TEXT_IMPORTING')}
                ...
              </If>

              <If condition={rendering}>
                {t('MEDIA_PREVIEW_TEXT_RENDERING')}
                ...
              </If>
            </div>
          </div>
        </When>
        <Otherwise>
          <Preview
            {...rest}
            showPlaceholder={showPlaceholder}
            fps={fps}
            duration={duration}
            progress={progress}
            onNextFrame={onNextFrame}
            onPrevFrame={onPrevFrame}
            onRewindTimeline={seek}
            previewPlayerType={previewPlayerType}
          >
            {playerProps => (
              <>
                <If condition={imageUrl && !isTextAsset}>
                  <SourceImagePreview
                    imageUrl={imageUrl}
                    setDraggable={setDraggable}
                    styles={playerProps?.style}
                  />
                </If>
                <If condition={isTextAsset && !imageUrl}>
                  <SourceTextPreview
                    styles={playerProps?.style}
                    isTextAsset={isTextAsset}
                    textSettings={rest?.asset?.settings ?? {}}
                  />
                </If>
                {/* note that this will work for audio content too */}
                <If condition={!imageUrl && !isTextAsset}>
                  <BasePreviewPlayer
                    {...playerProps}
                    id={rest?.id}
                    onReady={player => onSetPlayer(player)}
                    src={srcVideo || ''}
                    onDuration={onChangeDuration}
                    onProgress={onChangeProgress}
                    onBufferingProgress={onBufferingProgress}
                    onEnded={pause}
                  />
                </If>
              </>
            )}
          </Preview>
        </Otherwise>
      </Choose>
    </>
  )
}

MediaPreview.propTypes = {
  srcVideo: PropTypes.string,
  fps: PropTypes.number,
  imageUrl: PropTypes.string,
  error: PropTypes.string,
  status: PropTypes.string,
  isMedia: PropTypes.bool,
  previewPlayerType: PropTypes.string,
  isTextAsset: PropTypes.bool,
}

MediaPreview.defaultProps = {
  fps: DEFAULT_FPS,
  imageUrl: undefined,
  status: undefined,
  error: undefined,
  isMedia: false,
  previewPlayerType: '',
  srcVideo: '',
  isTextAsset: false,
}

export default React.memo(MediaPreview)
