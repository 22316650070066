import React from 'react'
import { useDrop } from 'react-dnd'
import { DRAGNDROP_TYPE } from '~/enums'
import { Context as DnDContext } from '../DnDContextProvider'
import { useLayerDropHandler } from './useLayerDropHandler'

// import { TimelineScrollPositionContext } from '../ScrollPositionContext'
// ---

// function getDragPosition(item, dropTargetMonitor) {
//   switch (item.type) {
//     case DRAGNDROP_TYPE.LAYER_ASSET:
//       // When dragging layer asset, pointer can be anywhere inside asset node.
//       // So that use `getSourceClientOffset` method, which tracks offset of dragged node (i.e. it's top-left coords)
//       return dropTargetMonitor.getSourceClientOffset().x

//     default:
//       // When dragging source clip, pointer is strictly at it's left edge.
//       // So that we can use `getClientOffset` method, which tracks pointer position.
//       return dropTargetMonitor.getClientOffset().x
//   }
// }

// ---

function useLayerDropTarget(ref, {
  layerId,
  layerIndex,
  scale,
  // getDragOffsetLeft,
  onDenied,
}) {
  const handleDrop = useLayerDropHandler()
  const {
    // setTime: setContextTime,
    setLayerHovered,
  } = React.useContext(DnDContext)

  // logic for updating sliderTime while dragging asset
  //
  // const refScroll = useLatestRef(React.useContext(TimelineScrollPositionContext))

  // const getDragTime = React.useCallback(
  //   (item, monitor) => {
  //     // Offsets, provided by react-dnd, are relative to entire screen borders.
  //     // So subtract from it layer left padding.
  //     const position = (
  //       getDragPosition(item, monitor)
  //       + intersectedPxls
  //       - getDragOffsetLeft()
  //       + refScroll.current.scrollLeft
  //     )

  //     const time = pixel2Time(position, scale)
  //     return Math.max(0, time)
  //   },
  //   [ getDragOffsetLeft, scale, refScroll, intersectedPxls ]
  // )

  // ---
  const [ collectedProps, drop ] = useDrop({
    accept: [
      DRAGNDROP_TYPE.LAYER_ASSET,
      DRAGNDROP_TYPE.MEDIA_ITEM,
      DRAGNDROP_TYPE.TEXT_ITEM,
      DRAGNDROP_TYPE.AUDIO_ITEM,
      DRAGNDROP_TYPE.IMAGE_ITEM,
      DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM,
    ],

    collect: monitor => ({
      isHovered: monitor.isOver(),
      item: monitor.getItem(),
    }),

    drop(item, monitor) {
      handleDrop(item, monitor, {
        onDenied, scale, layerId, layerIndex,
      })
    },

    // hover(item, monitor) {
    //   const time = getDragTime(item, monitor)
    //   setContextTime(time)
    // },
  }, [ scale ])
  // ---
  const { isHovered } = collectedProps

  React.useEffect(
    () => {
      setLayerHovered({ layerId, isHovered })
    },
    [ setLayerHovered, layerId, isHovered ]
  )

  // explicit clean up when layer is removed
  React.useEffect(
    () => () => {
      setLayerHovered({ layerId, isHovered: false })
    },
    [ setLayerHovered, layerId ]
  )
  // ---

  drop(ref)

  return collectedProps
}

export default useLayerDropTarget
