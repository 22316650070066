import { useCallback, useEffect } from 'react'

import { addTimeToUrl } from './domLegacy'

type ExternalCustomScriptProps = {
  src: string,
  stringified: string,
  onSuccess: (config: unknown) => void
}


export const useExternalCustomScript = ({ src,
  stringified,
  onSuccess = () => {} }: ExternalCustomScriptProps) => {
  const handleError = useCallback(() => {
    onSuccess(null)
    console.error('Error: invalid external custom script url')
  }, [ onSuccess ])

  const addScript = useCallback((src, onLoad) => {
    const script = document.createElement('script')
    script.addEventListener('error', handleError)
    script.addEventListener('abort', handleError)
    script.addEventListener('load', onLoad)
    script.async = true
    script.defer = true
    script.id = 'custom-script'
    if (src) {
      script.src = addTimeToUrl(src)
      script.type = 'module'
      document.body.appendChild(script)
    }
    return script
  }, [ handleError ])

  useEffect(() => {
    (async function fn() {
      const oldScript = document.getElementById('custom-script')
      if (oldScript) {
        document.head.removeChild(oldScript)
      }
      if (stringified) {
        try {
          const script = document.createElement('script')
          script.id = 'custom-script'
          script.textContent = stringified
          document.head.append(script)
          onSuccess(true)
        } catch (e) {
          console.error(e)
        }
      } else {
        let config = null
        let script = null
        try {
          const { config: customConfig } = await import(/* webpackIgnore: true */addTimeToUrl(src))
          config = customConfig
        } catch (e) { // if dynamic import is not supported
          const promise = new Promise(resolve => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((window as any).customConfig = {} as any).initConfig = (config: unknown) => {
              resolve(config)
            }

            setTimeout(() => { resolve(null) }, 10000)

            script = addScript(src, () => {
              resolve(null)
            })
          })

          config = await promise
        } finally {
          if (script) {
            document.body.removeChild(script)
            script = null
          }
          onSuccess(config)
        }
      }
    }())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ stringified ])
}
