import { changeClipPlaybackState, resetPlaybackClip, setMediaServerSeekProcessing, stopPlaybackClip, stopPlaybackTimeline } from 'actions/playback'
import { PREVIEW_MODE } from 'config/constants/preview'
import { PLAYBACK_STATE } from 'enums'
import { isEmpty } from 'lodash'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import { selectMediaServerSeekProcessing } from 'selectors/playback'
import * as ActionTypes from '~/actions/ActionTypes'

function* resetPlaybackState({ payload }) {
  const { id } = payload
  const selectedClip = yield select(state => state.playback.selectedClipId)
  const playingClip = yield select(state => state.playback.playingAsset)
  if (selectedClip === id || playingClip?.id === id) {
    yield put(changeClipPlaybackState(PLAYBACK_STATE.STOP))
  }
}


function* resetSourceMediaPreview({ type, payload }) {
  if ([ ActionTypes.DELETE_ASSETS, ActionTypes.RIPPLE_DELETE_ASSETS ].includes(type)) {
    const { assets } = payload
    if (!isEmpty(assets)) {
      const ioPointAssetID = yield select(state => state.preview.inOutPointsTimeLineAsset)
      const removedIOPointsAsset = assets.find(asset => asset.id === ioPointAssetID)
      if (removedIOPointsAsset) {
        yield put(resetPlaybackClip())
      }
    }
  } else {
    yield put(resetPlaybackClip())
  }
}

function* onMediaServerSeekProcessing({ payload }) {
  const isMediaServerProcessing = yield select(selectMediaServerSeekProcessing)
  const { isProcessing } = payload
  if (isProcessing !== isMediaServerProcessing) {
    yield put(setMediaServerSeekProcessing(isProcessing))
  }
}

function* stopPlaying({ payload }) {
  const { mode } = payload
  switch (mode) {
    case PREVIEW_MODE.TIMELINE:
      yield put(stopPlaybackClip())
      break
    case PREVIEW_MODE.SOURCE:
      yield put(stopPlaybackTimeline())
      break
    default:
      break
  }
}

function* watchAll() {
  yield all([
    takeEvery([ ActionTypes.SOURCE_FILE_DELETED ], resetPlaybackState),
    takeEvery([
      ActionTypes.RESET_MAIN_VIEW,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
    ], resetSourceMediaPreview),
    takeEvery([ ActionTypes.EMIT_MEDIA_SERVER_SEEK_PROCESSING_STATUS ],
      onMediaServerSeekProcessing),
    takeEvery([ ActionTypes.SET_PREVIEW_MODE ],
      stopPlaying),
  ])
}

export default watchAll
