/* eslint-disable no-alert */
import { showCustomizeView } from 'actions/mainView'
import {
  deleteHotkeyProfile,
  renameHotkeyProfile,
  resetProfileToDefaults,
  setHotkey as setHotkeyAction
} from 'actions/user'
import { DeleteHotkeyProfilePresetModal } from 'components/DeleteHotkeyProfilePresetModal/DeleteHotkeyProfilePresetModal'
import { HotkeysTable } from 'components/HotkeysTable/HotkeysTable'
import { InputModal } from 'components/InputModal/InputModal'
import { View } from 'components/View/View'
import ButtonBase from 'components/base/Button'
import MessageBox from 'components/base/MessageBox'
import { CATEGORIZED_HOTKEYS } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import { HOTKEY_CATEGORY } from 'enums'
import { useAction, useModalDialog } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import classNames from 'classnames'
import { ReactComponent as UndoIcon } from '~/assets/icons/undo.svg'
import { ReactComponent as WarningIcon } from '~/assets/icons/warning.svg'
import styles from './CustomizationHotkeys.module.scss'
import { HotkeysHead } from './Head/HotkeysHead'

export const CustomizationHotkeys = () => {
  const { t } = React.useContext(TranslationContext)

  const [ categoryId, setCategoryId ] = useState(HOTKEY_CATEGORY.All)
  const [ conflictCombination, setConflictCombination ] = useState('')
  const [ conflictHotkey, setConflictHotkey ] = useState('')
  const [ rerenderSeed, setRerenderSeed ] = useState(1)

  const onClose = useAction(showCustomizeView, false)
  const resetToDefaults = useAction(resetProfileToDefaults)
  const setHotkey = useAction(setHotkeyAction)
  const rename = useAction(renameHotkeyProfile)
  const deleteProfile = useAction(deleteHotkeyProfile)

  const [ undoStack, setUndoStack ] = useState([])

  const [ error, setError ] = React.useState('')
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const handleConflict = (conflictHotkey, combination) => {
    setConflictCombination(combination)
    setConflictHotkey(conflictHotkey)
    setRerenderSeed(Math.random())
  }

  const handleChanged = (hotkey, combination, oldCombination) => {
    setUndoStack(v => [ ...v, { hotkey, oldCombination }])
    setConflictCombination('')
    setConflictHotkey('')
    setRerenderSeed(Math.random())
  }

  const handleClickUndo = () => {
    if (undoStack.length) {
      setUndoStack(v => {
        const { hotkey, oldCombination } = v.pop()
        setHotkey(hotkey, oldCombination)

        if (conflictCombination) {
          setHotkey(conflictHotkey, conflictCombination)
          setConflictCombination('')
          setConflictHotkey('')
        }
        return v
      })
    }
    setRerenderSeed(Math.random())
  }

  const deletionDialog = useModalDialog({
    onConfirm: async () => {
      deleteProfile(activeHotkeyProfile.id)
    },
  })

  const renameModal = useModalDialog({
    onConfirm: newName => {
      rename(newName)
    },
  })

  const handleResetAll = () => {
    setUndoStack([])
    resetToDefaults()
    setRerenderSeed(Math.random())
  }

  useEffect(() => {
    setConflictCombination('')
    setConflictHotkey('')
    setRerenderSeed(Math.random())
    setUndoStack([])
  }, [ activeHotkeyProfile ])

  return (
    <>
      <View
        header={(
          <HotkeysHead
            setCategoryId={setCategoryId}
            categoryId={categoryId}
            deletionDialog={deletionDialog}
            renameModal={renameModal}
          />
        )}
        footer={
          // eslint-disable-next-line no-nested-ternary
          conflictHotkey ? (
            <div
              className="flex j-space-between items-center"
              style={{ width: '100%' }}
            >
              <div className={classNames(styles.conflictMessage, 'flex')}>
                <WarningIcon style={{ marginRight: '12px' }} />
                {`${conflictCombination} was previously assigned to ${t(
                  CATEGORIZED_HOTKEYS[conflictHotkey]
                )} > ${t(conflictHotkey)}`}
                <br />
                Video Editor unassigned the shortcut for that command
              </div>
              <ButtonBase
                style={{ width: 'fit-content' }}
                border
                className={styles.undoButton}
                onClick={handleClickUndo}
              >
                <UndoIcon className={styles.undoIcon} />
                Undo
              </ButtonBase>
            </div>
          ) : undoStack.length ? (
            <div
              className="flex j-space-between items-center"
              style={{ width: '100%' }}
            >
              <ButtonBase
                color="primary"
                text
                size="small"
                style={{ textTransform: 'unset', fontSize: '14px' }}
                onClick={handleResetAll}
              >
                {t('RESET_ALL_TO_DEFAULTS')}
              </ButtonBase>
              <ButtonBase
                style={{ width: 'fit-content' }}
                border
                className={styles.undoButton}
                onClick={handleClickUndo}
              >
                <UndoIcon className={styles.undoIcon} />
                Undo
              </ButtonBase>
            </div>
          ) : null
        }
        onClose={onClose}
        title={t('SETTINGS_HOTKEYS')}
      >
        <HotkeysTable
          onChanged={handleChanged}
          categoryId={categoryId}
          onConflict={handleConflict}
          key={rerenderSeed}
        />
      </View>
      {error && (
        <MessageBox
          title="Customization saving error"
          message={error}
          onClose={() => setError(false)}
        />
      )}
      <InputModal
        fieldName={t('ENTER_PRESET_NAME')}
        title={t('KEYBOARD_MAPPING_PRESET')}
        value={activeHotkeyProfile.id}
        modalDialog={renameModal}
      />
      <DeleteHotkeyProfilePresetModal
        modalDialog={deletionDialog}
        name={activeHotkeyProfile.id}
      />
    </>
  )
}
