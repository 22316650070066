import { DEFAULT_IMAGE_DURATION } from 'constant'
import { PLAYBACK_STATE } from 'enums'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFPSByRefVideo, RangeTools, secondsToTimelineTime, timelineTimeToSeconds } from 'Util'
import * as Assets from 'models/Asset'
import { getReferenceVideoAsset } from 'selectors'
import { noop } from 'lodash'
import { selectSourcePlayerProgress } from 'selectors/preview'
import { useAction } from 'hooks/utils'
import { setSourcePlayerProgress } from 'actions/preview'
import { getFrameStep } from 'helpers/getFrameStep'

const FAKE_PROGRESS_INTERVAL = 5
const PROGRESS_INCREMENT = 100000

type UseMediaPreviewSliderProgressType = {
  isMedia: boolean,
  asset: Assets.MediaAsset,
  onPlayPause(e: MouseEvent): void,
  onRewindTimeline(sec: number): void
  previewDration: number
  progress: number,
  fps: number,
  onPrevFrame():void,
  onNextFrame():void,
}

export const useMediaPreviewSliderProgress = ({
  isMedia,
  onPlayPause,
  asset,
  onRewindTimeline,
  previewDration,
  progress,
  fps,
  onPrevFrame,
  onNextFrame,
}: UseMediaPreviewSliderProgressType) => {
  // media player progress (slider progress) for ImageAsset || TextAsset
  const [ fakeProgress, setFakeProgress ] = useState(0)
  const isProcessing = useRef(false)
  const progressInterval = useRef<NodeJS.Timeout | null>(null)
  const mediaPlaybackState = useSelector((state: RootState) => state.playback.clipPlaybackState)
  const refVideo = useSelector(getReferenceVideoAsset)
  const sourcePlayerProgress = useSelector(selectSourcePlayerProgress)
  const setMediaPlayerProgress = useAction(setSourcePlayerProgress)

  const isStaticAsset = asset instanceof Assets.ImageAsset || asset instanceof Assets.TextAsset
  const currentFps = fps || getFPSByRefVideo(refVideo)

  const clipDuration = isStaticAsset
    ? timelineTimeToSeconds(asset.duration ?? DEFAULT_IMAGE_DURATION)
    : previewDration
  const mediaPreviewProgress = isStaticAsset
    ? timelineTimeToSeconds(fakeProgress)
    : progress


  const onChangeFakeProgress = useCallback((sec: number) => {
    setFakeProgress(secondsToTimelineTime(sec))
    setMediaPlayerProgress(sec)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setFakeProgress, fakeProgress ])

  const onStopFakeProgress = useCallback((e: MouseEvent) => {
    if (isMedia && progressInterval.current) {
      clearInterval(progressInterval.current)
      progressInterval.current = null
      onPlayPause(e)
      setMediaPlayerProgress(timelineTimeToSeconds(fakeProgress))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onPlayPause, progressInterval, fakeProgress ])

  const onStartFakeProgress = useCallback((e: MouseEvent) => {
    if (mediaPlaybackState !== PLAYBACK_STATE.PLAY) {
      onPlayPause(e)
    }
    if (!progressInterval.current) {
      progressInterval.current = setInterval(() => {
        setFakeProgress(prev => {
          if (progressInterval.current && !isProcessing.current && Number(DEFAULT_IMAGE_DURATION) === prev) {
            isProcessing.current = true
            return 0
          }
          if (Number(DEFAULT_IMAGE_DURATION) > prev) {
            isProcessing.current = true
            return (prev + PROGRESS_INCREMENT)
          }
          if (progressInterval.current) {
            isProcessing.current = false
            onStopFakeProgress(e)
            setMediaPlayerProgress(timelineTimeToSeconds(prev))
          }
          return prev
        })
      }, FAKE_PROGRESS_INTERVAL)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onPlayPause, onStopFakeProgress, setFakeProgress, progressInterval, isProcessing, mediaPlaybackState ])

  const onPlayPauseFakeProgress = useCallback((e: MouseEvent) => {
    if (isMedia) {
      mediaPlaybackState !== PLAYBACK_STATE.PLAY
        ? onStartFakeProgress(e)
        : onStopFakeProgress(e)
    }
  }, [ mediaPlaybackState, onStartFakeProgress, onStopFakeProgress, isMedia ])

  const onRewind = useCallback((sec: number) => {
    isStaticAsset ? onChangeFakeProgress(sec) : onRewindTimeline(sec)
  }, [ onRewindTimeline, isStaticAsset, onChangeFakeProgress ])

  const onTogglePlayPause = useCallback((e: MouseEvent) => {
    isStaticAsset ? onPlayPauseFakeProgress(e) : onPlayPause(e)
  }, [ onPlayPauseFakeProgress, onPlayPause, isStaticAsset ])

  const handlePrevFrame = () => {
    if (isMedia) {
      if (isStaticAsset) {
        const { backStep } = getFrameStep({ progress, fps: currentFps })
        const time = RangeTools.fitTo([ 0, clipDuration ], Number((mediaPreviewProgress + backStep).toFixed(3)))
        setFakeProgress(secondsToTimelineTime(time))
      } else {
        onPrevFrame()
      }
    }
  }

  const handleNextFrame = () => {
    if (isMedia) {
      if (isStaticAsset) {
        const { forwardStep } = getFrameStep({ progress, fps })
        const time = RangeTools.fitTo([ 0, clipDuration ], Number((mediaPreviewProgress + forwardStep).toFixed(3)))
        setFakeProgress(secondsToTimelineTime(time))
      } else {
        onNextFrame()
      }
    }
  }


  useEffect(() => {
    if (isMedia && isStaticAsset && mediaPlaybackState === PLAYBACK_STATE.PLAY && !progressInterval.current) {
      if (sourcePlayerProgress === 0) setFakeProgress(0)
      onStartFakeProgress({ preventDefault: noop } as MouseEvent)
    }
    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current)
        progressInterval.current = null
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset?.id, isStaticAsset, mediaPlaybackState, sourcePlayerProgress, progressInterval ])

  return {
    onPlayPauseFakeProgress,
    onChangeFakeProgress,
    onRewind,
    onTogglePlayPause,
    clipDuration,
    mediaPreviewProgress,
    currentFps,
    handlePrevFrame,
    handleNextFrame,
  }
}
