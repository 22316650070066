import React, { useEffect } from 'react'
import { isHLS } from 'helpers/isHLS'
import { isMediaServerUrl } from 'helpers/isMediaServerUrl'
import Hls from 'hls.js'
import { initHLSPlayer } from 'helpers/initHlsPlayer'
import { getPlayerProps } from 'helpers/getPlayerProps'
import { initMediaServerPlayer } from 'helpers/initMediaServerPlayer'
import { useAction } from 'hooks'
import { emitMediaServerSeekProcessingStatus } from 'actions/playback'

export const Player = React.forwardRef((props, ref) => {
  const { src, onDuration, id, onSeeking } = props
  const playerProps = getPlayerProps(src, props)
  const onFinishMediaServerSeekProcessing = useAction(emitMediaServerSeekProcessingStatus, false)
  const onStartMediaServerSeekProcessing = useAction(emitMediaServerSeekProcessingStatus, true)

  useEffect(() => {
    let player
    const refPlayer = ref.current
    const durationChange = event => {
      if (event?.target) {
        onDuration(event.target.duration)
      }
    }

    if (Hls.isSupported() && isHLS(src)) {
      player = initHLSPlayer(ref, { src })
    } else if (isMediaServerUrl(src)) {
      player = initMediaServerPlayer(ref, {
        src,
        onDuration,
        onSeeking,
        onFinishMediaServerSeekProcessing,
        onStartMediaServerSeekProcessing,
      })
    } else {
      // for native HTML5 Player
      refPlayer.addEventListener('durationchange', durationChange)
    }
    return () => {
      if (player) player.destroy()
      refPlayer.removeEventListener('durationchange', durationChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ src, ref, onDuration, onSeeking, id ])

  return <video {...playerProps} ref={ref} />
})
