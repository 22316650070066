import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as Actions from 'actions'
import Button from 'components/base/Button'
import Input from 'components/base/Input'
import MenuItem from 'components/base/MenuItem'
import Scrollbars from 'components/base/Scrollbars'
import Select from 'components/base/Select'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '~/AppRoutes'
import { DEFAULT_PROJECT_OUTPUT_NAME } from '~/constant'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { FILE_STATUS, MEDIA_TYPE, SOURCE_FILE_TYPES, TASK_STATUS, HIEROGLYPH_LANG } from '~/enums'
import { useAction } from '~/hooks'
import * as Selectors from '~/selectors'
import ErrorText from './ErrorText'
import './SaveSettings.scss'
import Status from './Status'
import { getLang } from '~/helpers/getLang'

const STEP = {
  INITIAL: 'initial',
  STATUS: 'status',
}

// TODO: refactor this mix of editing task info and sourceFile info (http://18.184.210.86/issues/1235#note-6)
function SaveSettings() {
  const history = useHistory()
  const onClose = useAction(Actions.mainView.showSaveSettings, false)
  const tasks = useSelector(state => state.project.editingTasks)
  const error = useSelector(state => state.project.error)
  const selectedTaskId = useSelector(state => state.project.selectedTaskId)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const selectedClip = useSelector(state => Selectors.getSourceFileById(state, selectedClipId))
  const isTaskLoading = useSelector(state => state.project.loading)
  const isViewLoading = useSelector(Selectors.selectIsViewLoading)
  const prefferedFormat = useSelector(Selectors.getPrefferedOutputFormat)
  const isLoading = isViewLoading

  const { t } = useContext(TranslationContext)

  const task = selectedTaskId ? tasks.find(t => t.id === selectedTaskId) : null
  const sourceFile = __CFG__.SAVE_SETTINGS.CREATE_OUTPUT_MEDIA ? selectedClip : null


  const [ step, setStep ] = useState(task || isTaskLoading ? STEP.STATUS : STEP.INITIAL)
  const [ format, setFormat ] = useState(prefferedFormat)
  const onChangeFormat = useCallback(e => {
    setFormat(e.target.value)
  }, [ setFormat ])

  const [ name, setName ] = useState(
    useSelector(state => state.project.outputName === DEFAULT_PROJECT_OUTPUT_NAME
      ? t('PUBLISH_SETTINGS_OUTPUT_NAME')
      : state.project.outputName)
  )
  const onChangeName = useCallback(e => {
    const value = e.target.value.slice(0, __CFG__.SAVE_PROJECT_MENU.MAX_FILENAME_LENGTH)
    setName(value)
  }, [])

  const formattedNamed = `${name}.${format.toLowerCase()}`

  const createTask = useAction(Actions.project.requestCreateTask, name, format)

  const onSubmit = useCallback(() => {
    setStep(STEP.STATUS)
    createTask()
  }, [ createTask ])

  const requestDeleteOutputFile = useAction(
    Actions.sourceFiles.requestDeleteFile, task?.fileId, SOURCE_FILE_TYPES.MEDIA
  )
  const deleteTask = useAction(Actions.project.deleteTask, task?.id)
  const onCancel = useCallback(() => {
    requestDeleteOutputFile()
    deleteTask()
    if (__CFG__.SAVE_SETTINGS.CLOSE_ON_CANCEL) {
      onClose()
    } else {
      setStep(STEP.INITIAL)
    }
  }, [ requestDeleteOutputFile, deleteTask, onClose ])

  const [ confirm, setConfirm ] = React.useState(false)
  const onCloseClick = useCallback(() => {
    if (__CFG__.SAVE_SETTINGS.CLOSE_CONFIRMATION_ENABLED && task) {
      setConfirm(true)
    } else if (__CFG__.SAVE_SETTINGS.DELETE_OUTPUT_ON_CLOSE && task) {
      requestDeleteOutputFile()
      deleteTask()
      onClose()
    } else {
      onClose()
    }
  }, [ task, onClose, requestDeleteOutputFile, deleteTask ])

  const onConfirmationCanceled = useCallback(() => {
    setConfirm(false)
  }, [])

  const clearTimeline = useAction(Actions.timeline.clearTimeline)
  const onConfirm = useCallback(() => {
    requestDeleteOutputFile()
    const { status } = task
    if (__CFG__.SAVE_SETTINGS.REDIRECT_TO_PROJECTS_ON_CLOSE
      && status !== TASK_STATUS.ABORTED) {
      history.push(AppRoutes.Projects)
    }
    onClose()
    deleteTask()
    if (__CFG__.SAVE_SETTINGS.CLEAR_TIMELINE_ON_CLOSE) {
      clearTimeline()
    }
  }, [ task, onClose, deleteTask, requestDeleteOutputFile, clearTimeline, history ])

  const refScrollAPI = useRef()
  useEffect(() => {
    refScrollAPI.current.scrollToBottom()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ task?.progress ])

  return (
    <div className="save-settings">
      <div className="save-settings__head">
        <div className="save-settings__top">
          <span className="save-settings__caption">
            {
              task?.status === TASK_STATUS.COMPLETED || sourceFile?.status === FILE_STATUS.READY
                ? t('SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE')
                : t('SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS')
            }
          </span>
          <div className="save-settings__close" onClick={onCloseClick}>
            <CloseIcon />
          </div>
          {/* TODO: remove after demo */}
          {confirm && <Confirmation onCancel={onConfirmationCanceled} onClose={onConfirm} />}
        </div>

        <If condition={!isLoading}>
          <Choose>
            <When condition={step === STEP.INITIAL
              && (!sourceFile || sourceFile?.status !== FILE_STATUS.READY)}
            >
              <Controls
                name={name}
                onChangeName={onChangeName}
                format={format}
                onChangeFormat={onChangeFormat}
                onSubmit={onSubmit}
              />
            </When>

            <Otherwise>
              <ControlsStatus
                outputName={sourceFile?.name || name}
                onCancel={onCancel}
                status={task?.status}
              />
            </Otherwise>
          </Choose>
        </If>
      </div>
      <Scrollbars trackHorizontalSize={1} ref={refScrollAPI}>
        <Choose>
          <When condition={isLoading}>
            <CircularProgress
              size={100}
              endless
              percents={80}
              text={`${t('SAVE_SETTINGS_TEXT_LOADING')}..`}
              transparent
            />
          </When>
          <Otherwise>
            <div className="save-settings__body">
              <If condition={__CFG__.SAVE_PROJECT_MENU.DETAILED_PROJECT_STATS_ON_SAVE}>
                <Info />
              </If>
              <If condition={step === STEP.STATUS || sourceFile?.status === FILE_STATUS.READY}>
                <div className="save-settings__bottom">
                  {(task || sourceFile?.status === FILE_STATUS.READY)
                    && <Status sourceFile={sourceFile} task={task} outputName={formattedNamed} />}
                  {error?.message && <ErrorText text={error.message} />}
                </div>
              </If>
            </div>
          </Otherwise>
        </Choose>
      </Scrollbars>
    </div>
  )
}
function Controls({ name, onChangeName, format, onChangeFormat, onSubmit }) {
  const { t } = useContext(TranslationContext)
  return (
    <div className="save-settings__control">
      <If condition={__CFG__.SAVE_PROJECT_MENU.FILENAME_TITLE}>
        <span className="save-settings__title">
          {t('SAVE_SETTINGS_TEXT_TITLE')}
          :
        </span>
      </If>
      <div className="save-settings__name">
        <Input
          value={name}
          onChange={onChangeName}
          placeholder={t('SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME')}
        />
      </div>
      <div className="save-settings__format">
        <If condition={__CFG__.SAVE_PROJECT_MENU.CHOOSE_OUTPUT_FILE_FORMAT}>
          <Select
            value={format}
            onChange={onChangeFormat}
          >
            <MenuItem value={MEDIA_TYPE.MP4}>{MEDIA_TYPE.MP4}</MenuItem>
            <MenuItem value={MEDIA_TYPE.MXF}>{MEDIA_TYPE.MXF}</MenuItem>
            <MenuItem value={MEDIA_TYPE.TS}>{MEDIA_TYPE.TS}</MenuItem>
          </Select>
        </If>
      </div>
      <div className="save-settings__btn-start" title={t('SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP')}>
        <Button
          primary
          disabled={name?.length === 0}
          onClick={onSubmit}
          data-lang-id="SAVE_SETTINGS_BTN_RENDER_START"
        >
          {t('SAVE_SETTINGS_BTN_RENDER_START')}
        </Button>
      </div>
    </div>
  )
}

function ControlsStatus({ onCancel, outputName, status }) {
  const showCancel = (status && status !== TASK_STATUS.COMPLETED)
  const { t } = useContext(TranslationContext)
  return (
    // TODO: Add 'Save Complete!' when status is completed
    <div className="save-settings__descr">
      <span className="save-settings__fullname">{`${t('PUBLISH_SETTINGS_TITLE')}: ${outputName}`}</span>
      <div className="save-settings__btn-cancel">
        {showCancel && <Button className={HIEROGLYPH_LANG.includes(getLang()) ? 'fs15' : ''} onClick={onCancel} secondary>{t('SAVE_SETTINGS_BTN_CANCEL')}</Button>}
      </div>
    </div>
  )
}

function Info() {
  const { width, height } = useSelector(Selectors.getReferenceVideoAsset)
  const { t } = useContext(TranslationContext)
  return (
    <ul className="save-settings__info">
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE')}
        :
        <span> 101,7 MB</span>
      </li>
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_RESOLUTION')}
        :
        <span>
          {' '}
          {width}
          {' '}
          x
          {' '}
          {height}
        </span>
      </li>
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_DURATION')}
        :
        <span> 5 m 35 s </span>
      </li>
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_CODEC')}
        :
        <span> AAC, H.264</span>
      </li>
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_FRAME_RATE')}
        {' '}
        (fps):
        <span> 25 fps</span>
      </li>
      <li className="save-settings__info-item">
        {t('SAVE_SETTINGS_LIST_ITEM_AUDIO')}
        :
        <span>
          {' '}
          44100
          {' '}
          {t('SAVE_SETTINGS_LIST_ITEM_HZ_STEREO')}
        </span>
      </li>
    </ul>
  )
}

function Confirmation({ onCancel, onClose }) {
  const { t } = useContext(TranslationContext)
  return (
    <Dialog disableBackdropClick open onClose={onCancel}>
      <DialogTitle>{__CFG__.SAVE_SETTINGS.CONFIRMATION_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button primary onClick={onCancel} title={t('SAVE_SETTINGS_BTN_CANCEL_TOOLTIP')} data-lang-id="SAVE_SETTINGS_BTN_CANCEL">
          {t('SAVE_SETTINGS_BTN_CANCEL')}
        </Button>
        <Button primary onClick={onClose} autoFocus title={t('SAVE_SETTINGS_BTN_OK_TOOLTIP')} data-lang-id="SAVE_SETTINGS_BTN_OK">
          {t('SAVE_SETTINGS_BTN_OK')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveSettings
