import React from 'react'
import { useSelector } from 'react-redux'
import { selectShowCustomizeView } from 'selectors/mainView'
import { MovableModalContainer } from 'components/MovableModal'
import { CustomizationView } from './CustomizationView'

export const CustomizationViewContainer = () => {
  const customizationView = useSelector(selectShowCustomizeView)
  return (
    <MovableModalContainer visible={customizationView === 'theme'}>
      <CustomizationView />
    </MovableModalContainer>
  )
}
