import React from 'react'
import cx from 'classnames'

import styles from './navbar.module.scss'

type Item = {
  id: string,
  name: string,
}

type Props = {
  items: Array<Item>,
  selected: string,
  onSelect: (itemId: string) => void
}

function Navbar({ items, selected, onSelect }: Props) {
  function handleClick(item: string) {
    onSelect(item)
  }

  return (
    <div className={styles.container}>
      {items.map((item, i) => (
        <React.Fragment key={item.id}>
          <div
            className={cx(styles.item, { [styles.selected]: selected === item.id })}
            onClick={() => handleClick(item.id)}
          >
            {item.name}
          </div>
          {i !== items.length - 1
            ? <div className={styles.placeholder} />
            : null}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Navbar
