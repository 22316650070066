import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTimeCodeMode, setTimeCodeState } from 'actions/playback'
import {
  ActiveTimeUnits,
  TimeCodeDataSetType,
  TimeCodeContainerLocationType,
  TimeCodeModeType,
  TimeCodeStateType,
  TimeCodeUnitsType,
  UnitMeasurementType,
  TimeCodeDigitsType
} from 'types/timecode'
import {
  TIME_CODE_MODE,
  INITIAL_TIME_CODE_DATA_BY_MODE,
  TIME_CODE_DATASET_VALUES,
  TIME_CODE_DATASET_ID,
  TIME_CODE_TIME_UNITS,
  TIME_CODE_STATE
} from 'config/constants/timecode'
import { selectActiveTimeCode } from 'selectors/playback'
import { useTimeCodeKeyDown } from './useTimeCodeKeyDown'
import { useInitialTimeCodeContainer } from './useInitialTimeCode'


type UseTimeCodeState = {
  containerLocation: TimeCodeContainerLocationType
  defaultTimeCodeMode: TimeCodeModeType
  progress: number
  onRewindTimeline?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps: number,
  readonly?: boolean,
  timeMode: Record<TimeCodeContainerLocationType, TimeCodeModeType>
}

export const useTimeCodeState = ({
  containerLocation,
  defaultTimeCodeMode,
  progress,
  onRewindTimeline,
  onMoveSlider,
  fps,
  readonly,
  timeMode,
}: UseTimeCodeState) => {
  const dispatch = useDispatch()
  const activeTimeCodeContainer = useSelector(selectActiveTimeCode)
  const [ timeUnits, setTimeUnits ] = useState<ActiveTimeUnits>([])
  const [ isActiveContainer, setActiveContaner ] = useState<boolean>(false)

  const onChangeMode = useCallback((mode: TimeCodeModeType) => {
    setTimeUnits(prev => {
      switch (mode) {
        case TIME_CODE_MODE.FRAME:
        case TIME_CODE_MODE.MILLISECOND:
          return [ ...INITIAL_TIME_CODE_DATA_BY_MODE[mode] ]
        default:
          return prev
      }
    })
    dispatch(setTimeCodeMode({ containerLocation, mode }))
  }, [ containerLocation, dispatch ])

  const resetActiveUnit = useCallback(() => {
    setTimeUnits(units => units.map(unit => ({ ...unit, active: false, editableDigit: null, nextDigit: null })))
  }, [ setTimeUnits ])

  const toggleContainer = useCallback((
    timeCodeState?: TimeCodeStateType,
    activeTimeCodeLocation?: TimeCodeContainerLocationType
  ) => {
    setActiveContaner(false)
    resetActiveUnit()
    dispatch(setTimeCodeState({
      timeCodeState: timeCodeState ?? TIME_CODE_STATE.VIEW,
      activeTimeCodeContainer: activeTimeCodeLocation ?? null,
    }))
  }, [ setActiveContaner, resetActiveUnit, dispatch ])

  const onDeactivateContainer = useCallback((e: globalThis.MouseEvent) => {
    const dataId = (e.target as HTMLElement)?.dataset?.id ?? ''
    if (isActiveContainer
      && !TIME_CODE_DATASET_VALUES.includes(dataId as TimeCodeDataSetType)
    ) {
      e.stopPropagation()
      toggleContainer()
    }
  }, [ toggleContainer, isActiveContainer ])

  const onActiveUnit = useCallback((e: globalThis.MouseEvent) => {
    if (readonly) return
    const dataSet = (e.target as HTMLElement)?.dataset
    const timeUnit = dataSet?.unit as UnitMeasurementType
    const timeDigit = dataSet?.digit as TimeCodeDigitsType
    const timeUnitDataSetID = dataSet?.id as TimeCodeUnitsType[number]

    if (dataSet?.id === TIME_CODE_DATASET_ID.MEASUREMENT) return
    if (TIME_CODE_TIME_UNITS.includes(timeUnitDataSetID)) {
      if (!isActiveContainer) {
        setActiveContaner(true)
        dispatch(setTimeCodeState({ timeCodeState: TIME_CODE_STATE.EDIT, activeTimeCodeContainer: containerLocation }))
      }
      setTimeUnits(units => units.map(unit => {
        const editableDigit = (unit.id === timeUnit) && unit.active ? timeDigit : null
        return {
          ...unit,
          editableDigit,
          active: unit.id === timeUnit,
        }
      }))
    }
  }, [ setActiveContaner, isActiveContainer, setTimeUnits, dispatch, containerLocation, readonly ])

  const { onKeyDown, onContainerArrowsIncrementControl, onChangeUnit } = useTimeCodeKeyDown({
    setActiveContaner,
    resetActiveUnit,
    timeUnits,
    isActiveContainer,
    setTimeUnits,
    progress,
    containerLocation,
    activeTimeCodeContainer,
    onRewindTimeline,
    onMoveSlider,
    fps,
    toggleContainer,
    readonly,
  })

  useInitialTimeCodeContainer({
    containerLocation,
    setTimeUnits,
    defaultTimeCodeMode,
    toggleContainer,
    activeTimeCodeContainer,
    timeCode: timeMode?.[activeTimeCodeContainer],
  })

  return {
    timeUnits,
    isActiveContainer,
    setTimeUnits,
    resetActiveUnit,
    onKeyDown,
    onActiveUnit,
    onDeactivateContainer,
    onChangeMode,
    onContainerArrowsIncrementControl,
    onChangeUnit,
    activeTimeCodeContainer,
  }
}
