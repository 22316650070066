import React, { useEffect } from 'react'

type UseTimeCodeEventListeners = {
  timeCodeContainer: React.MutableRefObject<HTMLDivElement | null>,
  onActiveUnit(e: globalThis.MouseEvent): void,
  onKeyDown(e: KeyboardEvent): void,
  onDeactivateContainer(e: globalThis.MouseEvent): void
}

export const useTimeCodeEventListeners = ({
  timeCodeContainer,
  onActiveUnit,
  onKeyDown,
  onDeactivateContainer,
}: UseTimeCodeEventListeners) => {
  useEffect(() => {
    const contaner = timeCodeContainer.current

    contaner?.addEventListener('click', onActiveUnit)
    document?.addEventListener('keydown', onKeyDown)
    document?.addEventListener('click', onDeactivateContainer)
    return () => {
      contaner?.removeEventListener('click', onActiveUnit)
      document?.removeEventListener('keydown', onKeyDown)
      document?.removeEventListener('click', onDeactivateContainer)
    }
  }, [ onActiveUnit, onDeactivateContainer, onKeyDown, timeCodeContainer ])
}
