import { isNil } from 'lodash'
import { useSelector } from 'react-redux'
import { secondsToTimelineTime } from '~/Util'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'

export const usePatchDraggingAsset = ({ draggingAsset, isExternalSource }) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (!isExternalSource || !draggingAsset) return draggingAsset

  const { markIn, markOut } = useSelector(state => state.preview.clipCreator.media)
  const playbackFileID = useSelector(state => state.playback.selectedClipId)
  let cloneDraggingAsset = cloneInstanceClass(draggingAsset)
  if (playbackFileID === draggingAsset.fileId) {
    const initialStartTime = draggingAsset.startTime
    const mediaStart = !isNil(markIn) ? secondsToTimelineTime(markIn) : draggingAsset.mediaStart
    const duration = !isNil(markOut) ? secondsToTimelineTime(markOut) : draggingAsset.duration
    const patch = {
      startTime: initialStartTime,
      mediaStart,
      duration,
      endTime: initialStartTime + duration - mediaStart,
    }
    cloneDraggingAsset = Object.assign(cloneDraggingAsset, patch)
  }
  return cloneDraggingAsset
}
