export const getFrameStep = ({ progress, fps }: { progress: number, fps: number}) => {
  const progressSeconds = Math.trunc(progress)
  const progressMilliseconds = Number(((progress - progressSeconds)).toFixed(6))
  const frameDuration = Number((1 / fps).toFixed(6))
  const showedFrames = Number((progressMilliseconds / frameDuration).toFixed(6))
  const absoluteShowedFrames = Math.trunc(showedFrames)
  const adjustmentBackward = Number((
    (showedFrames - absoluteShowedFrames) * frameDuration
  ).toFixed(6))
  const adjustmentForward = Number((
    (1 - (showedFrames - absoluteShowedFrames)) * frameDuration
  ).toFixed(6))

  const backStep = adjustmentBackward <= 0.001
    ? -(frameDuration + adjustmentBackward)
    : -adjustmentBackward
  const forwardStep = adjustmentForward <= 0.001
    ? (frameDuration + adjustmentForward)
    : adjustmentForward

  return {
    forwardStep,
    backStep,
  }
}
