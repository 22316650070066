import React, { memo, useMemo } from 'react'
import { DraggingElem } from './DraggingElem'
import { getDragElementsContainerWidth } from './lib/getDragElementsContainerWidth'
import { getDragElementsContainerHeight } from './lib/getDragElementsContainerHeight'
import { getTransformTranslateXY } from './lib/getTransformTranslateXY'

export const SnappedPreview = memo(({
  scale,
  currentDragAssets,
  draggingAssetWidth,
  snappedPreview,
  visible,
  currentX,
  currentY,
  isMultipleDrag,
  offset,
  dragItem,
  transitions,
}) => {
  const { x, y } = useMemo(() => getTransformTranslateXY(snappedPreview, draggingAssetWidth,
    currentX, currentY, isMultipleDrag), [
    snappedPreview,
    draggingAssetWidth,
    currentX,
    currentY,
    isMultipleDrag,
  ])

  const width = useMemo(() => getDragElementsContainerWidth({
    scale, draggingAsset: currentDragAssets,
  }), [ scale, currentDragAssets ])

  const height = useMemo(() => getDragElementsContainerHeight({
    draggingAsset: currentDragAssets,
  }), [ currentDragAssets ])

  if (!visible) return null
  return (
    <div style={{
      width,
      height,
      position: 'relative',
      transform: `translate(${x}px, ${y}px)`,
    }}
    >
      <DraggingElem
        isMultipleDrag={isMultipleDrag}
        offset={offset}
        dragItem={dragItem}
        currentDragAssets={currentDragAssets}
        scale={scale}
        transitions={transitions}
      />
    </div>
  )
})
