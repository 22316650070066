import { useDispatch } from 'react-redux'
import { isNil, isNumber } from 'lodash'
import { MouseEvent, useCallback, useEffect, useMemo } from 'react'
import { ClipCreatorMarkType } from 'types/preview'
import { setActivePreview, setInOutPointsMoving, setPreviewMarker } from 'actions/preview'
import { MARK_TYPE } from 'config/constants/preview'
import { stopPlaybackClip } from 'actions/playback'
import { PLAYER_TYPE } from 'enums'
import { LocalClipCreatorMarkerType, UseEditClip } from './types'
import { INITIAL_MOUSE_MOVEMENT_DATA } from './constant'

export const useEditClip = ({
  preview,
  activePreview,
  onSetMarker,
  clipCreatorMarkers,
  duration,
  previewPlayerVideoElem,
  onRewindTimeline,
  progress,
  mouseMovementData,
  setLocalMarkers,
  localMarkers,
  setDragging,
  ioPointsBarRef,
  isMediaServerProcessing,
  isDragging,
}: UseEditClip) => {
  const dispatch = useDispatch()

  // Only for media server player. When isMediaServerProcessing will be false.
  useMemo(() => {
    if (activePreview === preview) {
      const { savedProgress, markType: isMoving } = mouseMovementData.current
      if (!isMediaServerProcessing && !isMoving && !isDragging && isNumber(savedProgress)) {
        onRewindTimeline(savedProgress)
        // eslint-disable-next-line no-param-reassign
        mouseMovementData.current = { ...INITIAL_MOUSE_MOVEMENT_DATA }
      }
    }
  }, [ isMediaServerProcessing, mouseMovementData, onRewindTimeline, activePreview, preview, isDragging ])

  const onClickMarkIcon = useCallback((
    markType: Exclude<ClipCreatorMarkType, 'clearInOutPoints'>,
    isResizer
  ) => (e: MouseEvent) => {
    if (activePreview !== preview) dispatch(setActivePreview(preview))
    if (isResizer) {
      dispatch(stopPlaybackClip())
      dispatch(setInOutPointsMoving(true))
    }
    // eslint-disable-next-line no-param-reassign
    mouseMovementData.current = { ...mouseMovementData.current, markType, left: e.clientX, savedProgress: progress }
    setLocalMarkers(() => ({ ...clipCreatorMarkers }))
  }, [ preview, progress, mouseMovementData, clipCreatorMarkers, setLocalMarkers, dispatch, activePreview ])

  const onMouseUp = useCallback(e => {
    if (preview === PLAYER_TYPE.MEDIA) {
      setDragging(prev => {
        if (isNumber(mouseMovementData.current.savedProgress)) {
          if (preview === activePreview && prev) {
            onRewindTimeline(mouseMovementData.current.savedProgress ?? 0)
          }
        }
        return false
      })
      document.body.style.cursor = 'default'
      if (mouseMovementData.current.markType) {
        if (isNumber(mouseMovementData.current.savedProgress) && !isMediaServerProcessing) {
          onRewindTimeline(mouseMovementData.current.savedProgress ?? 0)
        }
        if (mouseMovementData.current.markType === MARK_TYPE.IN) {
          dispatch(setPreviewMarker({
            preview,
            point: localMarkers[activePreview].markIn,
            markType: MARK_TYPE.IN,
          }))
        } else if (mouseMovementData.current.markType === MARK_TYPE.OUT) {
          dispatch(setPreviewMarker({
            preview,
            point: localMarkers[activePreview].markOut,
            markType: MARK_TYPE.OUT,
          }))
        }
        setLocalMarkers({} as LocalClipCreatorMarkerType)
      }
      /*
       * Only for media server player.
       * if isMediaServerProcessing true don't remove savedProgress for media player preview.
      */
      if (isMediaServerProcessing) {
        // eslint-disable-next-line no-param-reassign
        mouseMovementData.current = {
          ...INITIAL_MOUSE_MOVEMENT_DATA,
          savedProgress: mouseMovementData.current.savedProgress,
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        mouseMovementData.current = { ...INITIAL_MOUSE_MOVEMENT_DATA }
      }
      dispatch(setInOutPointsMoving(false))
    }
  }, [
    onRewindTimeline,
    mouseMovementData,
    dispatch,
    localMarkers,
    setLocalMarkers,
    preview,
    activePreview,
    setDragging,
    isMediaServerProcessing,
  ])

  const { left, right } = previewPlayerVideoElem?.getBoundingClientRect() ?? {}

  const onMouseMove = useCallback((e: globalThis.MouseEvent) => {
    if (preview === activePreview) {
      if (mouseMovementData.current.markType && !mouseMovementData.current.dragging) {
        document.body.style.cursor = 'ew-resize'
        if (!isNil(left) && !isNil(right)) {
          const pixelsInSecond = (right - left) / duration
          let newTimePoint = (e.clientX - left) / pixelsInSecond
          newTimePoint = e.clientX <= left ? 0 : newTimePoint
          newTimePoint = e.clientX >= right ? duration : newTimePoint
          onSetMarker(
            activePreview,
            mouseMovementData.current.markType
          )(e, { newTimePoint })
        }
      }

      if (mouseMovementData.current.dragging && ioPointsBarRef.current) {
        document.body.style.cursor = 'grabbing'
        if (!isNil(left) && !isNil(right)) {
          if (isNil(mouseMovementData.current.startX)) {
            // set initial x params
            const { left: leftBarX, right: rightBarX } = ioPointsBarRef.current.getBoundingClientRect()
            // eslint-disable-next-line no-param-reassign
            mouseMovementData.current = {
              ...mouseMovementData.current,
              startX: e.clientX,
              leftBarX,
              rightBarX,
            }
          }
          const pixelsInSecond = (right - left) / duration
          const offset = e.clientX - mouseMovementData.current.draggingX
          const offsetDraggedIOPointsSegment = offset / pixelsInSecond

          const isLeftMove = offset < 0
          const isRightMove = offset > 0

          const { leftBarX, rightBarX, startX } = mouseMovementData.current
          let isAllowed = true
          if (isNumber(leftBarX) && isNumber(rightBarX) && isNumber(startX)) {
            if (isRightMove) {
              // Diff between ioPointsBar leftX and center drag
              const diff = startX - leftBarX
              if ((e.clientX - diff) < left) {
                isAllowed = false
                // eslint-disable-next-line no-param-reassign
                mouseMovementData.current.draggingX = left + diff // set draggingX in the middle of ioPoints bar
              }
            } else if (isLeftMove) {
              // Diff between ioPointsBar rightX and center drag
              const diff = rightBarX - startX
              if ((e.clientX + diff) > right) {
                isAllowed = false
                // eslint-disable-next-line no-param-reassign
                mouseMovementData.current.draggingX = right - diff // set draggingX in the middle of ioPoints bar
              }
            }
          }
          if (isAllowed) {
            onSetMarker(
              activePreview,
              MARK_TYPE.DRAG
            )(e, { newTimePoint: null, offsetDraggedIOPointsSegment })
            // eslint-disable-next-line no-param-reassign
            mouseMovementData.current.draggingX = e.clientX
          }
        }
      }
    }
  }, [
    activePreview,
    onSetMarker,
    preview,
    duration,
    left,
    right,
    mouseMovementData,
    ioPointsBarRef,
  ])

  const onSetDragging = useCallback((dragging: boolean) => (e: MouseEvent) => {
    if (dragging) {
      dispatch(setActivePreview(preview))
      dispatch(stopPlaybackClip())
      dispatch(setInOutPointsMoving(true))
      setDragging(dragging)
      // eslint-disable-next-line no-param-reassign
      mouseMovementData.current = {
        ...mouseMovementData.current,
        dragging,
        draggingX: dragging ? e.clientX : 0,
        savedProgress: dragging ? progress : null,
      }
    }
    if (!dragging) {
      document.body.style.cursor = 'default'
    }
  }, [ setDragging, mouseMovementData, progress, preview, dispatch ])

  useEffect(() => {
    if (preview === activePreview) {
      window.addEventListener('mouseup', onMouseUp)
      document.addEventListener('mousemove', onMouseMove)
    }
    return () => {
      window.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [ onMouseUp, onMouseMove, activePreview, preview ])

  return {
    onClickMarkIcon,
    savedProgress: mouseMovementData.current.savedProgress,
    onSetDragging,
  }
}
