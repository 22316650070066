import { useRef } from 'react'
import { TIME_CODE_DIGITS, UNIT_MEASUREMENT } from 'config/constants/timecode'

export const useTimeCodeRefs = () => {
  const timeCodeContainer = useRef<HTMLDivElement | null>(null)
  /* HOUR */
  const hourUnitTimeDigitFirst = useRef<HTMLInputElement | null>(null)
  const hourUnitTimeDigitSecond = useRef<HTMLInputElement | null>(null)
  /* \ HOUR */

  /* MINUTES */
  const minutesUnitTimeDigitFirst = useRef<HTMLInputElement | null>(null)
  const minutesUnitTimeDigitSecond = useRef<HTMLInputElement | null>(null)
  /* \ MINUTES */


  /* SECONDS */
  const secondsUnitTimeDigitFirst = useRef<HTMLInputElement | null>(null)
  const secondsUnitTimeDigitSecond = useRef<HTMLInputElement | null>(null)
  /* \ SECONDS */

  /* MILLISECONDS */
  const millisecondsUnitTimeDigitFirst = useRef<HTMLInputElement | null>(null)
  const millisecondsUnitTimeDigitSecond = useRef<HTMLInputElement | null>(null)
  const millisecondsUnitTimeDigitThree = useRef<HTMLInputElement | null>(null)
  /* \ MILLISECONDS */

  /* FRAMES */
  const framesUnitTimeDigitFirst = useRef<HTMLInputElement | null>(null)
  const framesUnitTimeDigitSecond = useRef<HTMLInputElement | null>(null)
  /* \ FRAMES */

  const timeCodeRefs = {
    [UNIT_MEASUREMENT.HOUR]: {
      [TIME_CODE_DIGITS.FIRST]: hourUnitTimeDigitFirst,
      [TIME_CODE_DIGITS.SECOND]: hourUnitTimeDigitSecond,
      [TIME_CODE_DIGITS.THIRD]: null,
    },
    [UNIT_MEASUREMENT.MINUTE]: {
      [TIME_CODE_DIGITS.FIRST]: minutesUnitTimeDigitFirst,
      [TIME_CODE_DIGITS.SECOND]: minutesUnitTimeDigitSecond,
      [TIME_CODE_DIGITS.THIRD]: null,
    },
    [UNIT_MEASUREMENT.SECOND]: {
      [TIME_CODE_DIGITS.FIRST]: secondsUnitTimeDigitFirst,
      [TIME_CODE_DIGITS.SECOND]: secondsUnitTimeDigitSecond,
      [TIME_CODE_DIGITS.THIRD]: null,
    },
    [UNIT_MEASUREMENT.MILLISECOND]: {
      [TIME_CODE_DIGITS.FIRST]: millisecondsUnitTimeDigitFirst,
      [TIME_CODE_DIGITS.SECOND]: millisecondsUnitTimeDigitSecond,
      [TIME_CODE_DIGITS.THIRD]: millisecondsUnitTimeDigitThree,
    },
    [UNIT_MEASUREMENT.FRAME]: {
      [TIME_CODE_DIGITS.FIRST]: framesUnitTimeDigitFirst,
      [TIME_CODE_DIGITS.SECOND]: framesUnitTimeDigitSecond,
      [TIME_CODE_DIGITS.THIRD]: null,
    },
  }

  return {
    timeCodeRefs,
    timeCodeContainer,
  }
}
