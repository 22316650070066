import * as ActionTypes from 'actions/ActionTypes'

export const changeSplitSize = ({ value, type }) => ({
  type: ActionTypes.CHANGE_SPLIT_SIZE,
  payload: {
    value,
    type,
  },
})

export const setResizing = resizing => ({
  type: ActionTypes.SET_RESIZING,
  payload: { resizing },
})
