import PropTypes from 'prop-types'
import React from 'react'
import AudioAssetSettingsForm from 'components/AssetPanels/Audio/Settings'
import * as PT from '~/PropTypes'
import { VideoAsset, ImageAsset, AudioAsset, TextAsset } from '~/models/Asset'
import TextAssetSettingsForm from '~/components/AssetPanels/Text/Settings'
import ImageAssetSettingsForm from './ImageForm'
import VideoAssetSettingsForm from './VideoForm'

// import AudioTracks from './AudioTracks'

function getSettingsForm(asset) {
  switch (asset?.constructor) {
    case VideoAsset:
      return VideoAssetSettingsForm
    case ImageAsset:
      return ImageAssetSettingsForm
    case AudioAsset:
      return AudioAssetSettingsForm
    case TextAsset:
      return TextAssetSettingsForm
    default:
      return null
  }
}

export default function MediaAssetSettingsForm(props) {
  const { asset } = props
  const MediaForm = getSettingsForm(asset)
  return MediaForm && <div style={{ overflow: 'hidden', height: '100%' }}><MediaForm {...props} /></div>
}

MediaAssetSettingsForm.defaultProps = {
  type: '',
  asset: null,
}

MediaAssetSettingsForm.propTypes = {
  asset: PropTypes.oneOfType([ PT.VideoAsset, PT.ImageAsset, PT.AudioAsset ]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
