import React, { FC } from 'react'
import { SEPARATOR } from 'enums'
import { ActiveTimeUnits, TimeCodeModeType, UnitMeasurementFormatType } from 'types/timecode'
import { TIME_CODE_DATASET_ID, TIME_CODE_MODE, UNIT_MEASUREMENT } from 'config/constants/timecode'

type IProps = {
 unitMeasurementFormats: UnitMeasurementFormatType[]
 unit: ActiveTimeUnits[number],
 timeMode: TimeCodeModeType
}

export const TimeCodeSeparator: FC<IProps> = ({
  unitMeasurementFormats,
  unit,
  timeMode,
}) => {
  if (unitMeasurementFormats.includes(unit.id as UnitMeasurementFormatType)) return null
  if ((unit.id === UNIT_MEASUREMENT.SECOND) && (timeMode === TIME_CODE_MODE.MILLISECOND)) {
    return (
      <div data-id={TIME_CODE_DATASET_ID.SEPARATOR}>{SEPARATOR.DOT}</div>
    )
  }
  return (
    <div data-id={TIME_CODE_DATASET_ID.SEPARATOR}>
      {SEPARATOR.COLON}
    </div>
  )
}
