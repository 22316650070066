import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as Actions from 'actions'
import { HOTKEYS } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { TranslationContext } from 'contexts/TranslationContext'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import './ZoomSlider.scss'


const MIN_VALUE = 1
const MAX_VALUE = 12.5 // for max scale 5ms
const STEP = 0.1

function ZoomSlider(props) {
  const { scale, setScale } = props
  const [ value, setValue ] = useState(Math.log(scale))
  const [ isMount, setIsMount ] = useState(false)
  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()
  const interval = useRef()

  useEffect(() => {
    if (isMount) {
      setScale(Math.exp(value))
    } else {
      setIsMount(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value, setScale ])

  useEffect(() => {
    setValue(Math.log(scale))
  }, [ scale, setScale ])

  const handleChange = useCallback((e, newValue) => {
    clearInterval(interval.current)
    interval.current = setTimeout(() => {
      setValue(newValue)
    })
  }, [])

  const onZoomOut = useCallback(() => {
    if (value > MIN_VALUE) {
      setValue(value - STEP)
    }
  }, [ value ])

  const onZoomIn = useCallback(() => {
    if (value < MAX_VALUE) {
      setValue(value + STEP)
    }
  }, [ value ])

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_IN], onZoomIn)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_OUT], onZoomOut)

  return (
    <div className="zoom-slider">
      <div className="zoom-slider__wrap">
        <div
          className="zoom-slider__btn"
          onClick={onZoomOut}
          title={getTooltip(t('ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_OUT)}
          data-lang-id="ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP"
        >
          <RemoveIcon />
        </div>
        <div className="zoom-slider__inner">
          <Slider
            step={STEP}
            min={MIN_VALUE}
            max={MAX_VALUE}
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </div>
        <div
          className="zoom-slider__btn"
          onClick={onZoomIn}
          title={getTooltip(t('ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_IN)}
          data-lang-id="ZOOM_SLIDER_BTN_INCREASE_ZOOM"
        >
          <AddIcon />
        </div>
      </div>
      <If condition={__CFG__.TIMELINE.ZOOM_MENU}>
        <div className="zoom-slider__control">
          <span className="zoom-slider__dots" />
        </div>
      </If>
    </div>
  )
}

ZoomSlider.propTypes = {
  setScale: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
})

const mapDispatchToProps = dispatch => ({
  setScale: scale => dispatch(Actions.timeline.setScale(scale)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoomSlider)
