import React, { FC } from 'react'
import { useMovableModal } from 'hooks/modal/movable/useMovableModal'
import './MovableModal.scss'
import cs from 'classnames'

interface IProps {
  visible: boolean
  width?: number,
  height?: number,
  withBackground? :boolean
}

export const MovableModalContainer: FC<IProps> = ({ children, visible, withBackground, width = 640, height = 520 }) => {
  const { movableContainerRef, isMouseDown } = useMovableModal({ visible, width, height })
  if (!visible) return null
  return (
    <div className={cs('movable-modal', {
      background: withBackground,
      active: visible,
    })}
    >
      <div
        ref={movableContainerRef}
        className={cs('movable-modal__container', {
          move: isMouseDown,
          active: visible,
        })}
        style={{ width, height }}
      >
        {children}
      </div>
    </div>
  )
}
