import React, { MouseEvent } from 'react'
import { Box } from '@material-ui/core'
import classNames from 'classnames'
import { ReactComponent as DragPreview } from '~/assets/preview/drag_preview.svg'

interface IProps {
  onSetDragging: (dragging: boolean) => (e: MouseEvent) => void
  isDragging: boolean,
  hiddenDragButton: boolean,
  isResize: boolean,
  isSm: boolean
}

export const CenterBar = ({ onSetDragging, isDragging, hiddenDragButton, isResize, isSm }: IProps) => (
  <div
    onMouseDown={onSetDragging(true)}
    onMouseUp={onSetDragging(false)}
    className={classNames('clip-creator__drag-button', {
      active: isDragging,
      hidden: hiddenDragButton,
      expand: isResize,
    })}
  >
    <Box width="calc(100% - 7px)" position="absolute" height="4px" left="0" top="-4px" margin="0 4px">
      <Box position="relative" width={!isSm ? '10px' : '100%'} top="5px" margin="0 auto">
        {!isSm ? <DragPreview /> : <Box position="absolute" top="0" left="0" width="100%" />}
      </Box>
    </Box>
  </div>
)
