import { PREVIEW_CANVAS_ADDITIONAL_FREE_SPACE, PREVIEW_SUMMARY_EXTRA_HEIGHT_INDENT, PREVIEW_SUMMARY_HEIGHT_INDENT, PREVIEW_SUMMARY_WIDTH_INDENT } from 'constant'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Selectors from '~/selectors'
import { DEFAULT_VIDEO_WIDTH, DEFAULT_VIDEO_HEIGHT } from '~/Util'

function useReferenceVideo() {
  return useSelector(Selectors.getReferenceVideoAsset)
}

/**
 * @param {{ width: number, height: number }} viewport
 * @returns {{
 *  rect: { width: number, x: number, y: number, height: number },
 *  scale: { x: number, y: number },
 * }}
 */
export default function useCanvasParams(viewport) {
  const video = useReferenceVideo()

  return useMemo(() => {
    let rect = {
      width: viewport.width,
      height: viewport.height,
      x: 0,
      y: 0,
    }

    let scale = { x: 1, y: 1 }
    const isVisibleRect = viewport.width > 0 && viewport.height > 0

    if (isVisibleRect) {
      const videoWidth = video ? video.width : DEFAULT_VIDEO_WIDTH
      const videoHeight = video ? video.height : DEFAULT_VIDEO_HEIGHT
      const videoRatio = videoWidth / videoHeight
      rect = {
        width: viewport.height * videoRatio,
        height: viewport.width / videoRatio,
      }

      rect.width = Math.round(Math.min(rect.width, viewport.width)) - PREVIEW_SUMMARY_WIDTH_INDENT
      rect.height = Math.round(
        Math.min(rect.height, viewport.height)
      ) - PREVIEW_SUMMARY_HEIGHT_INDENT

      if (viewport.height <= (rect.height + PREVIEW_CANVAS_ADDITIONAL_FREE_SPACE)) {
        rect.height -= PREVIEW_SUMMARY_EXTRA_HEIGHT_INDENT
      }

      if (document.fullscreenElement) {
        rect.height = viewport.height
      }

      rect.x = 0 // Math.round((viewport.width - rect.width) / 2)
      rect.y = 0 // Math.round((viewport.height - rect.height) / 2)

      rect.offsetX = Math.round((viewport.width - rect.width) / 2)
      rect.offsetY = Math.round((viewport.height - rect.height) / 2)

      scale = {
        x: rect.width / videoWidth,
        y: rect.height / videoHeight,
      }
    }

    return { rect, scale, viewport }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ video, viewport.height, viewport.width ])
}
