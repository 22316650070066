import { VideoPixelColorPicker } from 'components/Preview/VideoPixelColorPicker'
import { useColorProvider } from 'hooks/useColorProvider'
import { connectAudioContext } from 'lib/elementAudioContext'
import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isHLS } from 'helpers/isHLS'
import { isMediaServerUrl } from 'helpers/isMediaServerUrl'
import { secondsToTimelineTime, timelineTimeToSeconds } from '~/Util'
import * as Actions from '~/actions'
import { useAction, useTap } from '~/hooks'
import { AudioAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import BasePreviewPlayer from '../BasePreviewPlayer'
import { usePlayer } from '../lib'
import useAudioContext from '../lib/useAudioContext'
import {
  useAssetProgress,
  usePlayerProgressHandler
} from './lib'


const noop = () => {}

function useAssetPlayerHandlers(asset, {
  onReady = noop,
  shouldTrackProgress = true,
  mode = 'default',
}) {
  const [
    setPlayerInstance,
    { usePlayerEffect, seek, getState: getPlayerState },
  ] = usePlayer()

  const { shouldRewind, latestTimelineRewindTime } = useAssetProgress(asset)

  // NOTE: This is used for wait initializing seek to the video start time (http://18.184.210.86/issues/1241)
  const [ seekInitialized, setSeekInitialized ] = useState(false)

  usePlayerEffect(
    (player, isInitializing) => {
      if (mode === 'test') return

      if ((shouldRewind || isInitializing || !seekInitialized)
        && latestTimelineRewindTime !== null) {
        const { startTime, mediaStart } = asset
        // NOTE: Set to 0 is important for background video (when startTime > latestTimelineRewindTime)
        const sliderAndStartTimesDiff = Math.max(latestTimelineRewindTime - startTime, 0)

        const assetProgress = sliderAndStartTimesDiff + mediaStart
        const seekTime = timelineTimeToSeconds(assetProgress)

        if (seekTime !== player.getCurrentTime() || !seekInitialized) {
          setSeekInitialized(true)
          // console.log(`Seeking ${player.getId()} to ${seekTime}s`)
          seek(seekTime)
        }
      }
    },
    [ latestTimelineRewindTime, shouldRewind, mode ]
  )

  const onProgress = usePlayerProgressHandler(asset, getPlayerState, {
    enabled: shouldTrackProgress,
    seekInitialized,
  })

  return {
    onProgress,
    onReady: useTap(onReady, setPlayerInstance),
  }
}

// ---

function TimelineAssetMediaPlayer(props) {
  const {
    /** @type {Assets.VideoAsset|Assets.AudioAsset} */
    asset,
    /** @type {Assets.VideoAsset|undefined} */

    onReady: baseOnReady,
    shouldTrackProgress,

    playing, muted, volume,
    width, height,
    playAudioOnly,
    style,
    active,
    mode,
    videoRef,
    isMedia,
    ...rest
  } = props

  const { onReady, onProgress } = useAssetPlayerHandlers(asset, {
    shouldTrackProgress,
    onReady: baseOnReady,
    mode,
  })

  const [ setGainNode,
    setAudioSourceNode ] = useAudioContext({ gain: Math.max(1, asset.volume) })

  const correctAssetDuration = useAction(Actions.layer.correctAssetDuration, asset)

  const layer = useSelector(state => Selectors.getLayerById(state, asset.layerId))
  const layerIndex = useSelector(state => Selectors.getLayerIndexById(state, asset.layerId))
  const currentPreviewAsset = useSelector(Selectors.selectCurrentPreviewAsset)
  const sliderTime = useSelector(state => state.timeline.sliderTime)

  const rewind = useAction(Actions.timeline.rewind)

  // NOTE: issue 749: media stops playing in preview on second file
  // It's because duration value recieved from backend greater than real video duration
  const onVideoPlayerReady = player => {
    if (!isHLS(asset.url) && !isMediaServerUrl(asset.url)) {
      const { gainNode,
        source } = connectAudioContext(player.getInternalPlayer())

      setGainNode(gainNode, Math.max(1, asset.volume))
      setAudioSourceNode(source)
    }
    onReady(player)
    const playerDuration = secondsToTimelineTime(player.getDuration())
    if (player.getId() === asset.id && playerDuration < asset.duration) {
      correctAssetDuration(playerDuration)
    }
  }

  const handleChangeDuration = duration => {
    const playerDuration = secondsToTimelineTime(duration)
    // issue 3262
    if (isHLS(asset.url)
    && currentPreviewAsset?.id === asset.id && playerDuration < asset.duration) {
      correctAssetDuration(playerDuration)
    }
  }

  const handleEnded = () => {
    const endDuration = sliderTime - asset.startTime
    // issue 3262
    if (isHLS(asset.url) && endDuration < asset.duration) {
      rewind(asset.endTime)
    }
  }

  const { colorProvider, isColorSelectionEnabled } = useColorProvider(`Chroma-key-${asset.id}`)

  const isVideoHidden = asset instanceof AudioAsset
  || ((playAudioOnly || asset.settings.isChromaKeyEnabled)
    && !colorProvider.colorClientsArray.some(cl => cl.params.assetId === asset.id))

  const mixedVolume = volume * Math.min(1, asset.volume)

  return (
    <If condition={layer}>
      <BasePreviewPlayer
        ref={videoRef}
        onProgress={mode === 'test' ? noop : onProgress}
        playing={playing}
        muted={muted || layer.muted || asset.muted}
        volume={mixedVolume}
        width={width}
        height={height}
        src={asset.url}
        active={active}
        mode={mode}
        isMedia={isMedia}
        {...rest}
        startPositionSeconds={timelineTimeToSeconds(asset.mediaStart)}
        onReady={onVideoPlayerReady}
        onDuration={handleChangeDuration}
        onEnded={handleEnded}
        style={
          { ...style,
          // NOTE: zIndex of VideoPlayer must be greater than zIndex of AudioPlayer and less than .gltransition zIndex
            ...(isVideoHidden ? { zIndex: 0 } : { zIndex: 2 }),
            visibility: (isColorSelectionEnabled
               && colorProvider.colorClientsArray.some(cl => cl.params.assetId === asset.id))
              ? 'visible' : 'hidden',
            cursor: isColorSelectionEnabled ? 'url(https://icons.iconarchive.com/icons/led24.de/led/16/pipette-icon.png) -16 32, default' : 'default' }
}
      />

      {isColorSelectionEnabled && (
      <VideoPixelColorPicker
        key={`color-picker-${layerIndex}`}
        videoRef={videoRef}
        width={width}
        height={height}
        onSelectColor={color => colorProvider.provideColor(color)}
      />
      )}
    </If>
  )
}

export default memo(TimelineAssetMediaPlayer)
