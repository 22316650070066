import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { MARK_TYPE } from 'config/constants/preview'
import { HOTKEYS, PLAYER_TYPE } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { secondsToTimelineTime, timelineTimeToSeconds } from 'Util'
import { DEFAULT_IMAGE_DURATION } from 'constant'
import { AudioAsset } from 'models/Asset'
import { useEditClip } from './useEditClip'
import { UseClipCreator } from './types'
import { usePlayerProgress } from './usePlayerProgress'
import { useContextMenu } from './useContextMenu'
import { useIOMarkers } from './useIOMarkers'
import { useInitialData } from './useInitialData'
import { useIOPointsTimelineAssetAsSourcePreviewClip } from './useIOPointsTimelineAssetAsSourcePreviewClip'

export const useClipCreator = ({
  preview,
  progress,
  previewPlayerVideoElem,
  duration,
  currentFps,
  asset,
  onRewind,
  isMedia,
}: UseClipCreator) => {
  const {
    dispatch,
    mouseMovementData,
    timeout,
    activeHotkeyProfile,
    activePreview,
    showInOutPoints,
    inOutPointsTimeLineAsset,
    minClipDuration,
    getTimePointToPercent,
    layerAssets,
    setDragging,
    isDragging,
    ioPointsBarRef,
    isResize,
    isMediaServerProcessing,
    assets,
    isStaticAsset,
  } = useInitialData({ currentFps, asset })

  const { onCloseMenu, onOpenMenu, contextMenuContainer, anchorEl, menuCoords } = useContextMenu()

  const {
    clipCreatorMarkers,
    handleSetLocalMarkers,
    localMarkers,
    setLocalMarkers,
    onClearInOutPoints,
    onSetMarker,
    onClickMarker,
  } = useIOMarkers({
    progress,
    minClipDuration,
    duration,
    showInOutPoints,
    activePreview,
    mouseMovementData,
    dispatch,
    inOutPointsTimeLineAsset,
    onRewindTimeline: onRewind,
    layerAssets,
    preview,
    isDragging,
    assetID: asset?.id,
    asset,
    isStaticAsset,
  })

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.MARK_IN], onClickMarker(preview, MARK_TYPE.IN))
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.MARK_OUT], onClickMarker(preview, MARK_TYPE.OUT))
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.CLEAR_IN_OUT_MARK_POINTS], onClearInOutPoints(preview))

  // For timeline
  const timeLinePreviewMarkers = useMemo(() => ({
    [MARK_TYPE.IN]: 0,
    [MARK_TYPE.OUT]: timelineTimeToSeconds(!isEmpty(assets) ? assets[assets.length - 1].endTime : 0),
  }), [ assets ])

  // For source media
  const mediaPreviewMarkers = !isEmpty(localMarkers)
    ? localMarkers[PLAYER_TYPE.MEDIA]
    : clipCreatorMarkers[PLAYER_TYPE.MEDIA]

  const clipMarkers = isMedia ? mediaPreviewMarkers : timeLinePreviewMarkers
  const timeCodeMarkers = useMemo(() => ({
    [MARK_TYPE.IN]: clipMarkers.markIn || 0,
    [MARK_TYPE.OUT]: clipMarkers.markOut || (clipMarkers.markIn ? duration : 0),
  }), [ clipMarkers, duration ])

  let rightTimeCodeDuration = 0

  if (isMedia) {
    rightTimeCodeDuration = secondsToTimelineTime(
      timeCodeMarkers[MARK_TYPE.OUT] - timeCodeMarkers[MARK_TYPE.IN]
    )
    if (!timeCodeMarkers[MARK_TYPE.OUT] && !timeCodeMarkers[MARK_TYPE.IN]) {
      if (isStaticAsset) {
        rightTimeCodeDuration = DEFAULT_IMAGE_DURATION
      }
      if (asset instanceof AudioAsset) {
        rightTimeCodeDuration = secondsToTimelineTime(duration)
      }
    }
  }

  const { onClickMarkIcon, onSetDragging, savedProgress } = useEditClip({
    preview,
    activePreview,
    onSetMarker,
    clipCreatorMarkers,
    previewPlayerVideoElem,
    duration,
    onRewindTimeline: onRewind,
    progress,
    mouseMovementData,
    handleSetLocalMarkers,
    setLocalMarkers,
    localMarkers,
    asset,
    setDragging,
    ioPointsBarRef,
    isMediaServerProcessing,
    isDragging,
  })

  usePlayerProgress({
    preview,
    onRewindTimeline: onRewind,
    progress: savedProgress ?? progress,
    timeout,
    isMediaServerProcessing,
    isStaticAsset,
  })

  useIOPointsTimelineAssetAsSourcePreviewClip({
    onRewindTimeline: onRewind,
    preview,
    inOutPointsTimeLineAsset,
    timeCodeMarkers,
    mouseMovementData,
    activePreview,
  })

  return {
    onSetMarker,
    timeLinePreviewMarkers,
    mediaPreviewMarkers,
    timeCodeMarkers,
    getTimePointToPercent,
    onClearInOutPoints,
    contextMenuContainer,
    onOpenMenu,
    onCloseMenu,
    anchorEl,
    anchorPosition: menuCoords,
    onClickMarkIcon,
    savedProgress,
    showInOutPoints,
    clipMarkers,
    isDragging,
    onSetDragging,
    isResize,
    onClickMarker,
    ioPointsBarRef,
    sliderProgress: savedProgress ?? progress,
    rightTimeCodeDuration,
  }
}
