import React, { FC, memo, useState } from 'react'
import { TimelineLeftOffsetContext } from 'contexts/TimelineLeftOffsetContext'

export const TimelineLeftOffsetContextProvider:FC = memo(({ children }) => {
  const [ data, setLayerControlsDOMRectData ] = useState<DOMRect>({} as DOMRect)

  const value = {
    timelineLeftOffset: data?.right || 0,
    setLayerControlsDOMRectData,
  }

  return (
    <TimelineLeftOffsetContext.Provider value={value}>
      {children}
    </TimelineLeftOffsetContext.Provider>
  )
})
