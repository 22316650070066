import * as Actions from 'actions'
import { HOTKEYS, PLAYBACK_STATE } from 'enums'
import { useAction } from 'hooks'
import { memo, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { selectorActivePreview } from 'selectors/preview'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'

export const PlaybackKeyHandler = memo(() => {
  const onPlayPause = useAction(Actions.playback.togglePlaybackPlaying)
  const activePreview = useSelector(selectorActivePreview)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const stopPropagation = useCallback((event: KeyboardEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const onToggle = useCallback((event: KeyboardEvent) => {
    stopPropagation(event)
    onPlayPause({ playerType: activePreview })
  }, [ onPlayPause, activePreview, stopPropagation ])

  const onPlay = useCallback((event: KeyboardEvent) => {
    stopPropagation(event)
    onPlayPause({ playbackState: PLAYBACK_STATE.PLAY, playerType: activePreview })
  }, [ onPlayPause, activePreview, stopPropagation ])

  const onPause = useCallback((event: KeyboardEvent) => {
    stopPropagation(event)
    onPlayPause({ playbackState: PLAYBACK_STATE.PAUSE, playerType: activePreview })
  }, [ onPlayPause, activePreview, stopPropagation ])

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PLAY_PAUSE], onToggle, [ onToggle, onPlayPause, activePreview ])
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PLAY], onPlay, [ onPlay, onPlayPause, activePreview ])
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PAUSE], onPause, [ onPause, onPlayPause, activePreview ])
  // const documentRef = useRef(document)
  // useKeyCodeListener(documentRef, KEY_SPACE, onPlayPause, { preventDefault: true })
  return null
})
