import { all, select, takeEvery } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import { newProjectId } from '~/constant'
import { selectActiveProjectId } from '~/selectors/projectData'
import { HistoryActionsService } from '~/services/HistoryActionsService'

function* enableHistoryActionsSync({ payload }) {
  yield HistoryActionsService.start()
  const activeProjectId = yield select(selectActiveProjectId)
  if (activeProjectId !== newProjectId || payload?.project?.id) {
    yield HistoryActionsService.enableSync(activeProjectId !== newProjectId ? activeProjectId
      : payload?.project?.id)
  }
}

function* stopHistoryActionsService() {
  yield HistoryActionsService.stop()
}

function* watchAll() {
  yield all(__CFG__.EDITOR.UNDO_REDO ? [
    takeEvery([
      ActionTypes.PROJECTS_END_PROJECT_LOAING,
      ActionTypes.PROJECTS_CREATE,
    ], enableHistoryActionsSync),
    takeEvery([ ActionTypes.PROJECTS_SET_ACTIVE_NAME,
      ActionTypes.PROJECTS_ALLOW_SAVE_EMPTY ], stopHistoryActionsService),
  ] : [])
}

export default watchAll
