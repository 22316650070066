import { MouseEvent, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MAX_UNIT_INDEX,
  PERMITTED_TIME_CODE_HOTKEYS,
  TIME_CODE_CONTAINER_LOCATION,
  TIME_CODE_DIGITS,
  TIME_CODE_DIGITS_SEQUENCE,
  TIME_CODE_UNIT_PARAMS,
  UNIT_MEASUREMENT
} from 'config/constants/timecode'
import { ActiveTimeUnits, TimeCodeContainerLocationType } from 'types/timecode'
import { RegularControlActionType } from 'types/common'
import { REGULAR_CONTROLS_UNITS, REGULATION_CONTROL } from 'enums'
import { resetCopiedTimeCode, setCopiedTimeCode } from 'actions/playback'
import { selectCopiedProgress } from 'selectors/playback'
import { n, nnn, timelineTimeToSeconds } from 'Util'
import { timeUnitsToStringValueTime } from './helpers/timeUnitsToStringValueTime'
import { stringValueToTimeLineTime } from './helpers/stringValueToTimeLineTime'
import { getIncrementValue } from './helpers/getIncrementValue'

type UseKeyDown = {
  setActiveContaner: React.Dispatch<React.SetStateAction<boolean>>,
  resetActiveUnit(): void,
  timeUnits: ActiveTimeUnits,
  isActiveContainer: boolean,
  setTimeUnits(value: React.SetStateAction<ActiveTimeUnits>): void,
  progress: number,
  containerLocation: TimeCodeContainerLocationType,
  activeTimeCodeContainer: TimeCodeContainerLocationType
  onRewindTimeline?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps: number,
  toggleContainer(): void,
  readonly?: boolean
}

export const useTimeCodeKeyDown = ({
  setActiveContaner,
  resetActiveUnit,
  timeUnits,
  isActiveContainer,
  setTimeUnits,
  progress,
  containerLocation,
  activeTimeCodeContainer,
  onRewindTimeline,
  onMoveSlider,
  fps,
  toggleContainer,
  readonly,
}: UseKeyDown) => {
  const dispatch = useDispatch()
  const copiedProgress = useSelector(selectCopiedProgress)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const onIncrementValues = (actionType: RegularControlActionType) => {
    setTimeUnits(units => (
      units.map(prevUnit => {
        const unit = { ...prevUnit }
        if (unit.active && (REGULAR_CONTROLS_UNITS as Array<RegularControlActionType>).includes(actionType)) {
          const incrementVal = getIncrementValue(unit.editableDigit)
          const increment = actionType === REGULATION_CONTROL.INCREASE ? incrementVal : -(incrementVal)
          let newValue = Number(unit.value) + increment
          if (newValue > TIME_CODE_UNIT_PARAMS[unit.id].MAX) {
            newValue = TIME_CODE_UNIT_PARAMS[unit.id].MAX
          }
          if (newValue < TIME_CODE_UNIT_PARAMS[unit.id].MIN) {
            newValue = TIME_CODE_UNIT_PARAMS[unit.id].MIN
          }
          unit.value = (unit.id === UNIT_MEASUREMENT.MILLISECOND) ? nnn(newValue) : n(newValue)
        }
        return unit
      })
    ))
  }

  // ArrowUp & ArrowDown
  const onKeyBoardArrowsIncrementControl = (e: KeyboardEvent) => {
    e.preventDefault()
    /* if ([
      PERMITTED_TIME_CODE_HOTKEYS.ARROWDOWN,
      PERMITTED_TIME_CODE_HOTKEYS.ARROWUP,
    ].includes(e.key)) {
      setTimeUnits(units => units.map(unit => ({ ...unit, isEdit: unit.active })))
    } */
    if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ARROWDOWN) {
      onIncrementValues(REGULATION_CONTROL.DECREASE)
    } else if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ARROWUP) {
      onIncrementValues(REGULATION_CONTROL.INCREASE)
    }
  }

  // Arrows (up/down) to the right of the container
  const onContainerArrowsIncrementControl = (actionType: RegularControlActionType) => (e: MouseEvent) => {
    onIncrementValues(actionType)
  }

  // Copy: Ctrl + C
  const onCopy = () => {
    dispatch(setCopiedTimeCode({ timeUnits, progress }))
    // Write to buffer
    navigator.clipboard.writeText(timeUnitsToStringValueTime(timeUnits))
  }

  // Paste: Ctrl + V
  const onPaste = () => {
    // Read from buffer
    navigator.clipboard.readText().then(bufferText => {
      let progress = copiedProgress
      if (bufferText) {
        progress = stringValueToTimeLineTime(bufferText, fps)
      }
      switch (containerLocation) {
        case TIME_CODE_CONTAINER_LOCATION.TIMELINE: onMoveSlider?.(progress); break
        case TIME_CODE_CONTAINER_LOCATION.PREVIEW: onRewindTimeline?.(timelineTimeToSeconds(progress)); break
      }
      dispatch(resetCopiedTimeCode())
    })
  }

  const onCopyPaste = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === PERMITTED_TIME_CODE_HOTKEYS.KEY_C) {
      onCopy()
    } else if (e.ctrlKey && e.key === PERMITTED_TIME_CODE_HOTKEYS.KEY_V) {
      onPaste()
    }
  }

  const applyChanges = (e: KeyboardEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      const progress = stringValueToTimeLineTime(timeUnitsToStringValueTime(timeUnits), fps)
      switch (containerLocation) {
        case TIME_CODE_CONTAINER_LOCATION.TIMELINE: onMoveSlider?.(progress); break
        case TIME_CODE_CONTAINER_LOCATION.PREVIEW: onRewindTimeline?.(timelineTimeToSeconds(progress)); break
      }
    }, 100)
  }

  // Change active time unit
  const onChangeActiveUnit = useCallback((e: KeyboardEvent, activeIndex: number) => {
    let newActiveIndex = activeIndex
    if (![ PERMITTED_TIME_CODE_HOTKEYS.ARROWRIGHT,
      PERMITTED_TIME_CODE_HOTKEYS.ARROWLEFT ].includes(e.key)) {
      return
    }
    if (activeIndex >= 0) {
      if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ARROWRIGHT) {
        newActiveIndex = (activeIndex + 1) > MAX_UNIT_INDEX ? MAX_UNIT_INDEX : activeIndex + 1
      }
      if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ARROWLEFT) {
        newActiveIndex = (activeIndex - 1) < 0 ? 0 : activeIndex - 1
      }
      setTimeUnits(units => units.map((unit, index) => ({
        ...unit,
        active: index === newActiveIndex,
        editableDigit: null,
      })))
    }
  }, [ setTimeUnits ])

  // Entering numbers from the keyboard
  const onChangeUnit = useCallback((activeUnit: ActiveTimeUnits[number]) => (
    e: React.SyntheticEvent | KeyboardEvent
  ) => {
    const num = ((e.target as HTMLInputElement).value ?? (e as React.KeyboardEvent<HTMLDivElement>)?.key).slice(-1)
    // Digital check
    if (!(/^\d+$/.test(num))) return

    // If the entered value exceeds the MAX, shiftActiveIndex will be true
    let shiftActiveIndex = false
    let activeIndex = -1

    setTimeUnits(units => units.map((prevUnit, index) => {
      const unit = { ...prevUnit }
      if (activeUnit.id === unit.id) {
        if (!unit.editableDigit) {
          unit.editableDigit = TIME_CODE_DIGITS.FIRST
        }
        if (!unit.nextDigit) {
          unit.nextDigit = unit.editableDigit
        }
        const { MAX, MASK } = TIME_CODE_UNIT_PARAMS[unit.id]
        // Reverse the string array because the first digit is the last in the string of numbers
        const inputArrayValue = unit.value?.split('').reverse() ?? MASK.split('').reverse()
        const digitIndex = TIME_CODE_DIGITS_SEQUENCE.indexOf(unit.nextDigit)
        if (unit.editableDigit === unit.nextDigit) {
          inputArrayValue[digitIndex] = num
        } else {
          inputArrayValue.pop()
          const insertableIndex = TIME_CODE_DIGITS_SEQUENCE.indexOf(unit.editableDigit)
          inputArrayValue.splice(insertableIndex, 0, num)
        }


        const nextDigitIndex = digitIndex + 1
        const newValue = inputArrayValue.reverse().join('')

        const CURRENT_MAX = unit.id === UNIT_MEASUREMENT.FRAME ? Math.floor(fps) : MAX

        if (CURRENT_MAX < Number(newValue) || (nextDigitIndex > TIME_CODE_UNIT_PARAMS[unit.id].MAX_DIGIT_INDEX)) {
          unit.value = CURRENT_MAX < Number(newValue) ? CURRENT_MAX.toString() : newValue
          unit.editableDigit = null
          unit.nextDigit = null

          shiftActiveIndex = true
          activeIndex = index
        } else if (nextDigitIndex <= TIME_CODE_UNIT_PARAMS[unit.id].MAX_DIGIT_INDEX) {
          unit.nextDigit = TIME_CODE_DIGITS_SEQUENCE[nextDigitIndex]
          unit.value = newValue
        }

        return {
          ...unit,
        }
      }
      return unit
    }))

    setTimeout(() => {
      if (shiftActiveIndex) {
        onChangeActiveUnit({
          key: PERMITTED_TIME_CODE_HOTKEYS.ARROWRIGHT,
        } as KeyboardEvent, activeIndex)
      }
    })
  }, [ onChangeActiveUnit, setTimeUnits, fps ])

  const onKeyDown = (e: KeyboardEvent) => {
    if (readonly) return
    e.stopPropagation()

    const editUnit = timeUnits.find(unit => unit.editableDigit)
    const activeUnit = timeUnits.find(unit => unit.active)

    if (activeUnit && !editUnit) {
      onChangeUnit(activeUnit)(e)
    }

    if (!Object.values(PERMITTED_TIME_CODE_HOTKEYS).includes(e.key)) return
    if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ESC) {
      setActiveContaner(false)
      resetActiveUnit()
      return
    }

    if (isActiveContainer && (containerLocation === activeTimeCodeContainer)) {
      const activeIndex = timeUnits.findIndex(unit => unit.active)

      onChangeActiveUnit(e, activeIndex)
      onCopyPaste(e)
      onKeyBoardArrowsIncrementControl(e)

      if (e.key === PERMITTED_TIME_CODE_HOTKEYS.ENTER) {
        applyChanges(e)
        toggleContainer()
      }
      applyChanges(e)
    }
  }

  return {
    onKeyDown,
    onContainerArrowsIncrementControl,
    onChangeUnit,
  }
}
