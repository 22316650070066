import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CustomDragLayerContext } from 'components/CustomDragLayerContext'
import { DraggingItemContext } from 'components/Timeline/DraggingItemProvider'
import { DRAGNDROP_TYPE } from '~/enums'
import { DraggingAsset } from './DraggingAsset'
import { DraggingLayer } from './DraggingLayer'
import DropHoverOnAssets from './DropHoverOnAssets'
import useOverNewLayer from './lib/useNewLayer'
import NewLayer from './NewLayer'

// ---

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 3,
  left: 0,
  width: '100%',
  height: '100%',
}

const CustomDragLayer = () => {
  const {
    isDragging,
    item,
    clientOffset,
    sourceOffset,
    initialClientOffset,
    initialSourceOffset,
    itemType,
  } = useContext(CustomDragLayerContext)
  const { resetProps: resetDraggingItemProps,
    getProps: getDraggingItemProps,
    setProps: setDraggingItemProps } = useContext(DraggingItemContext)
  const { isOverItem } = getDraggingItemProps()
  const dndDropTarget = useSelector(state => state.mainView.dndDropTarget)
  const { overNewLayerIndex, scrollTop } = useOverNewLayer({ clientOffset, itemType })
  const active = dndDropTarget === 'timeline'

  useEffect(() => {
    setDraggingItemProps({ overNewLayerIndex })

    if (overNewLayerIndex !== null) {
      setDraggingItemProps({ detachedMode: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ overNewLayerIndex, active ])

  useEffect(() => {
    if (!active || !isDragging) resetDraggingItemProps()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ active, isDragging ])

  const disableDrop = clientOffset ? clientOffset.x <= 0 : true

  useEffect(() => {
    if (isDragging) {
      setDraggingItemProps({ disableDrop })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ disableDrop, isDragging, active ])

  if (!isDragging || !clientOffset || clientOffset?.x === undefined) {
    return null
  }
  return (
    <If condition={!!item}>
      <div style={{ ...layerStyles, display: active ? 'block' : 'none' }}>
        <Choose>
          <When condition={itemType === DRAGNDROP_TYPE.LAYER}>
            <DraggingLayer
              item={item}
              clientOffset={clientOffset}
              sourceOffset={sourceOffset}
              initialSourceOffset={initialSourceOffset}
            />
          </When>
          <Otherwise>
            <If condition={isOverItem.size === 1}>
              <DropHoverOnAssets
                item={item}
                ignoredTypes={[ DRAGNDROP_TYPE.TRANSITION_ITEM,
                  DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET ]}
                clientOffset={clientOffset}
                sourceOffset={sourceOffset}
                initialClientOffset={initialClientOffset}
                initialSourceOffset={initialSourceOffset}
                hoveredAssetId={Array.from(isOverItem)[0]}
                isOverNewLayer={overNewLayerIndex !== null}
              />
            </If>
            <If condition={overNewLayerIndex !== null}>
              <NewLayer layerIndex={overNewLayerIndex} scrollTop={scrollTop} />
            </If>
            <DraggingAsset overNewLayerIndex={overNewLayerIndex} />
          </Otherwise>
        </Choose>
      </div>
    </If>
  )
}

export default React.memo(CustomDragLayer)
