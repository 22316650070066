import { useSelector } from 'react-redux'
import * as Actions from 'actions'
import { MENU_ITEMS, SOURCE_FILE_TYPES } from 'enums'
import { selectMediaRecordingSettingsState } from 'selectors/recording'
import { useAction } from 'hooks/utils'
import { selectMenuItem } from 'selectors/mainView'
import { getEditingAsset } from './helpers/getEditingAsset'

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
type UpdateAssetFuncType = (...args: any[]) => void

export const useMediaAssetSettingsForm = () => {
  const mediaRecordingOpened = useSelector(selectMediaRecordingSettingsState)
  const menuItem = useSelector(selectMenuItem)
  const menuType = Object.values(MENU_ITEMS).find(item => item === menuItem)
  const sourceFileType = Object.values(SOURCE_FILE_TYPES).find(fType => fType === menuType)

  const onUpdateAsset = useAction(Actions.layer.updateEditingAsset, sourceFileType) as UpdateAssetFuncType
  const asset = useSelector(state => getEditingAsset(state, sourceFileType))

  const showSettings = (asset !== null) && !mediaRecordingOpened

  return {
    showSettings,
    onUpdateAsset,
    asset,
    type: menuType,
  }
}
