import { useDispatch } from 'react-redux'
import { changeSplitSize } from 'actions/userInterface'
import { TIMELINE_MIN_WIDTH_WITHOUT_RESIZER } from 'constant'
import { useRef } from 'react'
import { isNil } from 'lodash'

type UseEditorSplitSizeProps = {
  previewRef: React.MutableRefObject<{
    pane1: HTMLDivElement,
    pane2: HTMLDivElement
  } | null>,
  windowWidth: number
}

export const useEditorSplitSize = ({ previewRef, windowWidth }: UseEditorSplitSizeProps) => {
  const dispatch = useDispatch()
  const prevSizeNum = useRef<null | number>(null)
  const onChangeSplitVertical = (newSize: number) => {
    if (isNil(prevSizeNum.current)) prevSizeNum.current = newSize
    const pane1PreviewWidth = previewRef.current?.pane1.offsetWidth ?? 0
    const pane2PreviewWidth = previewRef.current?.pane2.offsetWidth ?? 0
    const sumPreview = pane1PreviewWidth + pane2PreviewWidth
    const sizeAll = pane1PreviewWidth + pane2PreviewWidth + newSize
    const diff = newSize - prevSizeNum.current
    if (previewRef.current && (((sizeAll > windowWidth)
      && (sumPreview >= TIMELINE_MIN_WIDTH_WITHOUT_RESIZER)) || (diff <= 0))) {
      // eslint-disable-next-line no-param-reassign
      previewRef.current.pane1.style.width = `${pane1PreviewWidth - diff}px`
    }
    prevSizeNum.current = newSize
    dispatch(changeSplitSize({ value: newSize, type: 'media' }))
  }

  return {
    onChangeSplitVertical,
  }
}
