import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { DEFAULT_FPS } from 'Util'
import { isNil } from 'lodash'
import {
  selectInOutPointsTimeLineAssetID,
  selectorSplitPlayers,
  selectPreviewMode
} from 'selectors/preview'
import { selectMediaPlaybackState, selectSelectedClipId } from 'selectors/playback'
import * as Selectors from 'selectors'
import * as Assets from 'models/Asset'
import { setPreviewLoading } from 'actions/preview'
import { useAction } from 'hooks/utils'
import { PREVIEW_MODE } from 'config/constants/preview'
import { usePreviewCommon } from './usePreviewCommon'


export const useMediaPreviewContainer = () => {
  const isSplitted = useSelector(selectorSplitPlayers)
  const selectedClipId = useSelector(selectSelectedClipId)
  const playing = useSelector(selectMediaPlaybackState)
  const mediaClip = useSelector(state => Selectors.getSourceFileById(state, selectedClipId))
  const inOutPointsTimeLineAssetID = useSelector(selectInOutPointsTimeLineAssetID)
  const activePreviewMode = useSelector(selectPreviewMode)
  const stopPreviewLoading = useAction(setPreviewLoading, false)

  const isAudioAsset = mediaClip instanceof Assets.AudioAsset
  const isImageAsset = mediaClip instanceof Assets.ImageAsset
  const isTextAsset = mediaClip instanceof Assets.TextAsset
  const { url, status, error, fps, name } = mediaClip ?? {}
  let title = name

  if (!isNil(name) && inOutPointsTimeLineAssetID) {
    title = `${name.slice(0, name.lastIndexOf('.'))} \u2013 Timeline`
  }

  const {
    handleStopRecording,
    togglePlaying,
    mediaRecordingInProgress,
    onSelect,
  } = usePreviewCommon({
    playing,
    playerType: PLAYER_TYPE.MEDIA,
  })

  useEffect(() => {
    if (activePreviewMode === PREVIEW_MODE.SOURCE) {
      stopPreviewLoading()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activePreviewMode ])

  const mediaPreviewProps = {
    status,
    error,
    fps: fps || DEFAULT_FPS,
    isMedia: true,
    showThumbnail: isAudioAsset,
    mediaRecordingInProgress,
    isMediaItemPlaying: mediaClip?.canPlay,
    imageUrl: isImageAsset ? url : null,
    srcVideo: url,
    showVideoPlayer: Boolean(mediaClip),
    playing: playing === PLAYBACK_STATE.PLAY,
    onPlayPause: mediaRecordingInProgress ? handleStopRecording : togglePlaying,
    previewPlayerType: PLAYER_TYPE.MEDIA,
    controls: true,
    asset: mediaClip,
    id: inOutPointsTimeLineAssetID,
    isTextAsset,
  }

  return {
    mediaPreviewProps,
    isSplitted,
    name: title,
    onSelect,
  }
}
