import { Folder } from 'models/Folder'
import AbstractAsset, * as Assets from 'models/Asset'
import { DRAGNDROP_TYPE } from 'enums'

type Asset = InstanceType<typeof AbstractAsset> & InstanceType<typeof Assets.UploadableAsset>

export function getDraggableType(asset: Asset) {
  switch (asset.constructor) {
    case Assets.VideoAsset:
      return DRAGNDROP_TYPE.MEDIA_ITEM
    case Assets.ImageAsset:
      return DRAGNDROP_TYPE.IMAGE_ITEM
    case Assets.TextAsset:
      return DRAGNDROP_TYPE.TEXT_ITEM
    case Assets.AudioAsset:
      return DRAGNDROP_TYPE.AUDIO_ITEM
    case Assets.TransitionAsset:
      return DRAGNDROP_TYPE.TRANSITION_ITEM
    case Folder:
      return DRAGNDROP_TYPE.FOLDER
    default:
      throw new Error(`Unsupported asset type: ${asset.constructor.name}`)
  }
}
