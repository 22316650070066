import cx from 'classnames'
import { range } from 'lodash'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import * as Actions from 'actions'
import { insertLayer } from 'actions/insertLayer'
import PropTypes from 'prop-types'

import { assetsPaste } from 'actions/assetsPaste'
import { HOTKEYS, PLAYBACK_STATE, PLAYER_TYPE } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { TimelineTopOffsetContext } from '~/components/Timeline/TimelineTopOffsetContext'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import MessageBox from '~/components/base/MessageBox'
import { newProjectId } from '~/constant'
import { useAction, useOnChange, useStatic } from '~/hooks'
import * as Selectors from '~/selectors'
import { selectLatestAction } from '~/selectors/historyActions'
import { TimelineLeftOffsetContextProvider } from '~/providers/TimelineLeftOffsetContextProvider'
import * as PT from '~/PropTypes'
import { PLAYBACK_UPDATE_FREQUENCY, secondsToTimelineTime, time2Pixel } from '~/Util'
import { endProjectLoading } from '~/actions/projectData/endProjectLoading'

import DnDContextProvider, { Context as DndContext } from './DnDContextProvider'
import DraggingItemProvider from './DraggingItemProvider'
import GeometryContextProvider from './GeometryContextProvider'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import './Timeline.scss'
import TimelineHeader from './TimelineHeader'
import TimelineWorkspace from './TimelineWorkspace'
import TimelineToolbar from './Toolbar'
import Countdown from './components/Countdown'
import { selectorActivePreview, selectPreviewMode } from '~/selectors/preview'
import { setActivePreview } from '~/actions/preview'
import { PREVIEW_MODE } from '~/config/constants/preview'
// ---

function EffectMonitorDraggingState() {
  const { isDraggingItemOverLayer } = React.useContext(DndContext)
  const dispatch = useDispatch()

  useOnChange(isDraggingItemOverLayer, isDragging => {
    if (isDragging) {
      // TODO:
      //  this will be executed over and over again, if dragged item is moved outside of timeline and back
      //  (i.e., during the same drag operation, without releasing mouse)
      //  Need to somehow check whether drag operation has been finished before re-emitting this action
      dispatch(Actions.timeline.startDraggingItemOverTimeline())
    } else {
      // When drag over timeline is finished (either mouse released, or just leaves timeline borders),
      // commit context time to store, so slider doesn't jump back where it was before drag started

      // dispatch(Actions.timeline.rewind(time))
    }
  })

  return null
}

function HotkeysListener() {
  const deleteSelectedAssets = useAction(Actions.timeline.deleteSelectedAssets)
  const showSettings = useAction(Actions.timeline.showSettings)
  const copyAssets = useAction(Actions.layer.assetsCopy)
  const selectClipsAtCurrentPosition = useAction(Actions.layer.selectClipsAtCurrentPosition)
  const pasteAssets = useAction(assetsPaste)

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.DELETE], deleteSelectedAssets)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.COPY], copyAssets)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PASTE], pasteAssets)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.SELECT_CLIPS_AT_CURRENT_POSITION],
    selectClipsAtCurrentPosition)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.CLIP_SETTINGS], showSettings)

  return null
}

function AutoplayTimer() {
  // It's important that timer *rewinds* timeline, not just moves slider.
  // So that players inside Preview will sync with this time.
  const updateSlider = useAction(Actions.timeline.updateSlider)
  const pause = useAction(Actions.playback.changeTimelinePlaybackState, PLAYBACK_STATE.PAUSE)
  const latestEndTime = useSelector(Selectors.selectLatestEndTime)
  const timer = useRef()

  const refParams = useStatic(useSelector(state => ({
    sliderTime: state.timeline.sliderTime,
    isPlaying: state.playback.timelinePlaybackState === PLAYBACK_STATE.PLAY,
    activeAsset: Selectors.getPlayingMediaAssets(state)[0],
    mediaRecordingAssetInProgress: Selectors.recording.isRecordingStarted(state),
  })))
  const { isPlaying, mediaRecordingAssetInProgress } = refParams.current

  React.useEffect(
    () => {
      if (isPlaying) {
        let prevDate = performance.now()
        timer.current = setInterval(
          () => {
            const { sliderTime, activeAsset } = refParams.current
            const now = performance.now()
            const nextTime = sliderTime + (now - prevDate) * 1e4
            prevDate = now
            // While asset is playing, slider position will be updated by `onProgress` event on videoplayer in Preview component.
            // While we're out of any asset, slider should move on it's own, simply by timer.
            if ((activeAsset === undefined) || mediaRecordingAssetInProgress) {
              if (mediaRecordingAssetInProgress) {
                updateSlider(nextTime)
                return
              }
              if (nextTime >= latestEndTime) {
                pause()
              } else {
                updateSlider(nextTime)
              }
            }
          },
          PLAYBACK_UPDATE_FREQUENCY
        )

        return () => {
          clearInterval(timer.current)
        }
      }

      return () => {
        clearInterval(timer.current)
      }
    },
    [ isPlaying, refParams, updateSlider, latestEndTime,
      pause, mediaRecordingAssetInProgress ]
  )

  return null
}

const TimelineProvider = memo(({ sliderTime, scrollLeft, scrollTop, children }) => {
  // onMouseDown сontrol for <div class = "timeline-header__ruller"/>
  const refIsMouseDown = React.useRef(false)

  const timelineMouseDown = React.useRef(false)
  // Store time position locally for as-fast-as-possible UI updates.
  // Only notify redux store when dragging finally stopped.
  const [ localTime, setLocalTime ] = React.useState(sliderTime)

  const [ isSticky, setSliderSticky ] = React.useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSliderSticky(prev => !timelineMouseDown.current ? false : prev)
  })

  const canMove = !timelineMouseDown.current

  const value = useMemo(() => ({
    scrollLeft,
    scrollTop,
    refIsMouseDown,
    timelineMouseDown,
    localTime,
    setLocalTime,
    setSliderSticky,
    isSticky,
    canMove,
  }), [
    scrollLeft,
    scrollTop,
    refIsMouseDown,
    timelineMouseDown,
    localTime,
    setLocalTime,
    setSliderSticky,
    isSticky,
    canMove,
  ])


  return (
    <TimelineScrollPositionContext.Provider value={value}>
      {children}
    </TimelineScrollPositionContext.Provider>
  )
})

// ---

class Timeline extends React.Component {

  constructor(props) {
    super(props)
    this.scrollbarRef = React.createRef()
    this.layersContainerRef = React.createRef()
  }

  state = {
    scrollLeft: 0,
    scrollTop: 0,
    loadedLayersCount: 0,
    isLoaded: false,
    modalError: '',
  }

  shift = 0

  render() {
    const {
      layers,
      sliderTime, scale,
      isEmpty,
      onFocus,
      rewind,
      saveSettingsOpened,
      activeProjectLoading,
      showCountdown,
      setShowCountdown,
      mediaRecordingAssetInProgress,
    } = this.props
    const { scrollLeft, scrollTop, modalError } = this.state

    const timelineTopOffset = this.layersContainerRef.current?.getBoundingClientRect().top || 0
    return (
      <>
        <If condition={showCountdown}>
          <Countdown onCancel={() => setShowCountdown(false)} items={range(4).reverse()} />
        </If>
        <div
          id="timeline"
          className={cx('timeline', { 'timeline--disabled': saveSettingsOpened || showCountdown, 'timeline--hidden': activeProjectLoading })}
          onFocus={onFocus}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          onMouseDown={this.setTimelinePreview}
        >
          <HotkeysListener />
          <AutoplayTimer />
          <TimelineToolbar disabled={mediaRecordingAssetInProgress} onMoveSlider={rewind} />
          <TimelineTopOffsetContext.Provider value={timelineTopOffset}>
            <TimelineProvider sliderTime={sliderTime} scrollLeft={scrollLeft} scrollTop={scrollTop}>
              <DnDContextProvider>
                <DraggingItemProvider>
                  <TimelineLeftOffsetContextProvider>
                    <GeometryContextProvider>
                      {setTimelineRootRef => (
                        <div
                          className={cx('wrapper', { wrapper__disabled: true })}
                          style={{ overflow: 'hidden' }}
                          ref={setTimelineRootRef}
                        >

                          <TimelineHeader
                            scale={scale}
                            time={sliderTime}
                            onMoveSlider={rewind}
                            disabled={saveSettingsOpened || mediaRecordingAssetInProgress}
                          />

                          <If condition={modalError}>
                            <MessageBox
                              className="timeline-error-modal"
                              message={modalError}
                              onClose={() => this.setState({ modalError: '' })}
                            />
                          </If>
                          <TimelineWorkspace
                            ref={this.layersContainerRef}
                            scale={scale}
                            scrollbarRef={this.scrollbarRef}
                            layers={layers}
                            isEmpty={isEmpty}
                            onScroll={this.onScroll}
                            onLayerAssetsLoaded={this.onLayerAssetsLoaded}
                            onDeniedDrop={this.onDeniedDrop}
                          />

                          <EffectMonitorDraggingState />
                        </div>
                      )}
                    </GeometryContextProvider>
                  </TimelineLeftOffsetContextProvider>
                </DraggingItemProvider>
              </DnDContextProvider>
            </TimelineProvider>
          </TimelineTopOffsetContext.Provider>
        </div>

        <If condition={activeProjectLoading}>
          <CircularProgress
            size={100}
            endless
            percents={80}
            text="Loading.."
            transparent
          />
        </If>
      </>
    )
  }

  componentDidMount() {
    this.setScroll()
  }


  componentDidUpdate(prevProps) {
    const {
      assetsByLayers,
      activeProjectLoading,
      activeProject,
      activeProjectId,
      endActiveProjectLoading,
      sliderTime,
      visibleTimelineDuration,
      scale,
      playing,
      projectDuration,
      mediaRecordingAssetInProgress,
    } = this.props

    const { loadedLayersCount, isLoaded } = this.state
    // TODO: refactor to make loadedLayersCount === activeProject?.layers.length insteadof ">="
    if ((activeProjectId === newProjectId || (loadedLayersCount >= activeProject?.layers.length
      && activeProjectLoading)) && !isLoaded) {
      // TODO: a timer for fix some problems with update timeline, refactor this timer and remove isLoaded state
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoaded: true })
      setTimeout(() => {
        endActiveProjectLoading()
      }, 500)
    }

    /**
     * Handling  timeline scroll on playback including mediaRecordingAsset in progress case.
     */
    if (!playing) {
      this.setScroll()
      this.bounced = false
    }

    if (playing) {
      if (Math.abs(prevProps.sliderTime - sliderTime) > secondsToTimelineTime(0.5)) {
        this.bounced = false
      } else if (mediaRecordingAssetInProgress || projectDuration > visibleTimelineDuration) {
        if (!this.bounced && visibleTimelineDuration - sliderTime <= 0) {
          this.bounced = true
          // 200 pixels
          this.handleScrollLeft(200)
        }
        if (this.bounced) {
          // if (projectDuration - sliderTime < pixel2Time(200, scale)) {
          // this.bounced = false
          // this.handleScrollLeft(200)
          // return
          // }
          const shift = Math.round(time2Pixel(sliderTime - prevProps.sliderTime, scale))
          this.handleScrollLeft(shift)
        }
      }
    }

    if ((prevProps.assetsByLayers !== assetsByLayers && !activeProjectLoading)
      || (prevProps.activeProjectLoading === true && activeProjectLoading === false)) {
      const { layers, insertLayer, draggingLayerId } = this.props

      if (prevProps.layers.at(-1)?.id !== layers.at(-1)?.id) {
        this.handleScrollTop()
      }
      if (layers.length && !draggingLayerId) {
        const lastLayer = layers[layers.length - 1]
        const assets = assetsByLayers.get(lastLayer.id)
        if (assets.length !== 0) {
          insertLayer(layers.length, true)
        }
      }
    }
  }

  onDeniedDrop = modalError => {
    this.setState({ modalError })
  }

  onLayerAssetsLoaded = () => {
    const { activeProjectLoading } = this.props
    if (activeProjectLoading) {
      this.setState(state => {
        const { loadedLayersCount } = state
        const nextValue = loadedLayersCount + 1
        return { loadedLayersCount: nextValue }
      })
    }
  }

  onScroll = e => {
    const { setScrollLeft, storeScrollLeft } = this.props
    const { scrollLeft, scrollTop } = e.target
    if (storeScrollLeft !== scrollLeft) {
      setScrollLeft(scrollLeft)
    }
    this.setState({ scrollLeft, scrollTop })
  }

  handleScrollLeft = shift => {
    const scrollLeft = this.scrollbarRef.current.getScrollLeft()
    this.scrollbarRef.current.scrollLeft(shift + scrollLeft)
  }

  handleScrollTop = (shift = null) => {
    if (shift === null) {
      this.scrollbarRef.current.scrollToBottom()
      return
    }
    const scrollLeft = this.scrollbarRef.current.getScrollLeft()
    this.scrollbarRef.current.scrollLeft(shift + scrollLeft)
  }

  setScroll = () => {
    const {
      storeScrollLeft,
    } = this.props

    if (this.scrollbarRef?.current
      && storeScrollLeft !== this.scrollbarRef.current.lastViewScrollLeft) {
      this.scrollbarRef.current.scrollLeft(storeScrollLeft)
    }
  }

  setTimelinePreview = () => {
    const {
      activePreview,
      activePreviewMode,
      setTimelinePreview,
    } = this.props

    if (activePreviewMode === PREVIEW_MODE.AUTO
      && activePreview !== PLAYER_TYPE.TIMELINE) {
      setTimelinePreview()
    }
  }

}

Timeline.defaultProps = {
  scale: 10.0,
  sliderTime: secondsToTimelineTime(60),
  onFocus: () => { },
  saveSettingsOpened: false,
  storeScrollLeft: 0,
  activeProject: undefined,
  activeProjectId: null,
  latestAction: {},
  draggingLayerId: undefined,
  activePreview: PropTypes.string,
  activePreviewMode: PropTypes.string,
  setTimelinePreview: () => { },
}

Timeline.propTypes = {
  layers: PropTypes.arrayOf(PT.Layer).isRequired,
  sliderTime: PropTypes.number,
  scale: PropTypes.number,
  storeScrollLeft: PropTypes.number,

  activeProject: PropTypes.shape({ layers: PropTypes.arrayOf(PropTypes.shape({})) }),

  isEmpty: PropTypes.bool.isRequired,

  onFocus: PropTypes.func,
  endActiveProjectLoading: PropTypes.func.isRequired,

  rewind: PropTypes.func.isRequired,

  insertLayer: PropTypes.func.isRequired,
  setScrollLeft: PropTypes.func.isRequired,
  activeProjectLoading: PropTypes.bool.isRequired,
  assetsByLayers: PropTypes.instanceOf(Map).isRequired,
  saveSettingsOpened: PropTypes.bool,
  activeProjectId: PropTypes.string,
  latestAction: PropTypes.shape({
    type: PropTypes.string,
    payload: PropTypes.any,
  }),
  draggingLayerId: PropTypes.string,
  projectDuration: PropTypes.number.isRequired,
  playing: PropTypes.bool.isRequired,
  visibleTimelineDuration: PropTypes.number.isRequired,
  mediaRecordingAssetInProgress: PropTypes.bool.isRequired,
  showCountdown: PropTypes.bool.isRequired,
  setShowCountdown: PropTypes.func.isRequired,
  activePreview: PropTypes.string,
  activePreviewMode: PropTypes.string,
  setTimelinePreview: PropTypes.func,
}

const mapStateToProps = state => ({
  sliderTime: state.timeline.sliderTime,
  visibleTimelineDuration: state.timeline.duration,
  playing: state.playback.timelinePlaybackState === PLAYBACK_STATE.PLAY,
  scale: state.timeline.scale,
  layers: state.timeline.layers,
  activeProjectLoading: state.projectData.activeProjectLoading || state.mainView.offlineRestoration,
  activeProject: state.projectData.activeProject,
  activeProjectId: state.projectData.activeProjectId,
  assetsByLayers: Selectors.getAssetsByLayers(state),
  isEmpty: Selectors.getAssets(state).length === 0,
  saveSettingsOpened: state.mainView.showSaveSettings,
  storeScrollLeft: state.timeline.scrollLeft,
  latestAction: selectLatestAction(state),
  draggingLayerId: Selectors.selectDraggingLayerId(state),
  projectDuration: Selectors.getProjectDuration(state),
  mediaRecordingAssetInProgress: Selectors.recording.isRecordingStarted(state)
    || Selectors.recording.isRecordingSaving(state),
  showCountdown: Selectors.recording.selectShowCountdown(state),
  activePreview: selectorActivePreview(state),
  activePreviewMode: selectPreviewMode(state),
})

const mapDispatchToProps = dispatch => ({
  rewind: time => dispatch(Actions.timeline.rewind(time)),
  onFocus: () => dispatch(Actions.timeline.focusTimelineRegion()),
  insertLayer: (index, isAdditional = false) => dispatch(
    insertLayer(index, undefined, false, isAdditional)
  ),
  setScrollLeft: scrollLeft => dispatch(Actions.timeline.setScrollLeft(scrollLeft)),
  endActiveProjectLoading: () => dispatch(endProjectLoading()),
  setShowCountdown: value => dispatch(Actions.recording.setShowCountdown(value)),
  setTimelinePreview: () => dispatch(setActivePreview(PLAYER_TYPE.TIMELINE)),
})

export default memo(connect(mapStateToProps, mapDispatchToProps)(Timeline))
