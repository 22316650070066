import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'

/**
 * @typedef {object} InterfaceState
 * @property {object} splitSize
 * @property {boolean} isResize
 */

/**
 * @type {InterfaceState}
 */
const initialState = {
  splitSize: {
    media: 0,
  },
  resizing: false,
}

const userInterface = (state, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SPLIT_SIZE: {
      const { value, type } = action.payload
      state.splitSize[type] = value
    }
      break
    case ActionTypes.SET_RESIZING: {
      const { resizing } = action.payload
      state.resizing = resizing
    }
      break
    default:
      break
  }
}

export default produce(userInterface, initialState)
