import { SOURCE_FILE_TYPES } from '~/enums'
import * as Selectors from '~/selectors'
import * as API from '~/ServerAPI'
import { createMediaFile } from './sourceFiles'

export const importLocalFile = file => async (dispatch, getState) => {
  let response = null
  try {
    const folderId = Selectors.selectCurrentFolderId(getState(), SOURCE_FILE_TYPES.MEDIA)
    response = await API.importFromLocalFile(file.path, file.size, folderId)
  } catch (err) {
    response = err.response
  }
  if (response?.data.id !== undefined) {
    const { id } = response.data
    dispatch(createMediaFile(id))
  }
}
