import { useCallback, useEffect } from 'react'
import { isNil } from 'lodash'
import { useDispatch } from 'react-redux'
import { setTimeCodeMode } from 'actions/playback'
import { INITIAL_TIME_CODE_DATA_BY_MODE, TIME_CODE_STATE } from 'config/constants/timecode'
import { ActiveTimeUnits, TimeCodeContainerLocationType, TimeCodeModeType, TimeCodeStateType } from 'types/timecode'
import { isJSON } from 'helpers/isJSON'

type UseInitialTimeCodeContainer = {
  containerLocation: TimeCodeContainerLocationType,
  setTimeUnits: React.Dispatch<React.SetStateAction<ActiveTimeUnits>>,
  defaultTimeCodeMode: TimeCodeModeType,
  toggleContainer(timeCodeState?: TimeCodeStateType, activeTimeCodeContainer?: TimeCodeContainerLocationType): void,
  activeTimeCodeContainer: TimeCodeContainerLocationType,
  timeCode: TimeCodeModeType
}

export const useInitialTimeCodeContainer = ({
  containerLocation,
  setTimeUnits,
  defaultTimeCodeMode,
  toggleContainer,
  activeTimeCodeContainer,
  timeCode,
}: UseInitialTimeCodeContainer) => {
  const dispatch = useDispatch()

  const getMode = useCallback((containerLocation, defaultTimeCodeMode) => {
    const existsTimeMode = localStorage.getItem(containerLocation)
    const mode: TimeCodeModeType = isJSON(existsTimeMode) && existsTimeMode
      ? JSON.parse(existsTimeMode)
      : defaultTimeCodeMode
    return mode
  }, [])

  useEffect(() => {
    const mode = getMode(containerLocation, defaultTimeCodeMode)
    if ((mode !== timeCode) || (containerLocation !== activeTimeCodeContainer)) {
      dispatch(setTimeCodeMode({
        containerLocation,
        mode: getMode(containerLocation, defaultTimeCodeMode),
      }))
    }
  }, [ defaultTimeCodeMode, dispatch, containerLocation, getMode, activeTimeCodeContainer, timeCode ])

  // Initial time code data by default time format
  useEffect(() => {
    setTimeUnits(_ => [
      ...INITIAL_TIME_CODE_DATA_BY_MODE[getMode(containerLocation, defaultTimeCodeMode)],
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isNil(activeTimeCodeContainer) && activeTimeCodeContainer !== containerLocation) {
      toggleContainer(TIME_CODE_STATE.EDIT, activeTimeCodeContainer)
    }
  }, [ activeTimeCodeContainer, toggleContainer, containerLocation ])
}
