/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */

import { EventEmitter } from 'events'
import { v4 as uuidv4 } from 'uuid'
import { Events } from './events'
import { WS_MSE_EVENT_TYPES } from './enums'
import { getUrlParams } from './getUrlParams'

const isHTTPS = window.location.protocol === 'https:'
const bufferedTimeInterval = 6
const pingInterval = 10000
const bEnableLogs = false

export default class MSP extends EventEmitter {

  static get Events(): typeof Events {
    return Events
  }

  private media: HTMLMediaElement | null = null
  private mediaSource: MediaSource | null = null
  private sourceBuffer: SourceBuffer | null = null
  private ws: WebSocket | null = null
  private pendingAcksSequenceNumber = 0
  private pendingAcks = new Map()
  private bInProcess = false
  private bEndOfStream = false
  private dataQueue: Blob[] = []
  private seekRequest: number | null = null
  private bSeekRequestsInProcess = false
  private startStreamTime = 0
  private fileId = ''
  private bSeekingInProcess = false
  private bBuffering = true
  private bPendingResetCurrentPos = false
  private uid = `${uuidv4()}`
  private originalGetCurrentTime: (() => number) | undefined;
  private originalSetCurrentTime: ((value: number) => void) | undefined;
  private pingTimeout: NodeJS.Timeout | null = null

  log(msg:string) {
    if (bEnableLogs) {
      console.log(`${this.uid} ${msg}`)
    }
  }

  wsSendAsync(msg: { type: string, payload?: object }) {
    return new Promise<void>((resolve, reject) => {
      try {
        this.pendingAcksSequenceNumber++
        this.pendingAcks.set(this.pendingAcksSequenceNumber, {
          cb: (sequenceNumber: number) => {
            this.pendingAcks.delete(sequenceNumber)
            resolve()
          },
        })
        const data = JSON.stringify({ ...msg, sequenceNumber: this.pendingAcksSequenceNumber })
        this.ws?.send(data)
      } catch (e) {
        reject(e)
      }
    })
  }

  ensureSourceBufferCompleteUpdating() {
    return new Promise<void>(resolve => {
      if (this.sourceBuffer?.updating) {
        this.sourceBuffer.addEventListener('updateend', () => {
          resolve()
        }, { once: true })
      } else {
        resolve()
      }
    })
  }

  ensureProcessNextItemCompleted() { // http://18.184.210.86/issues/3652#note-6
    return new Promise<void>(resolve => {
      if (this.bInProcess) {
        this.once(Events.PROCESS_NEXT_ITEM_COMPLETED, () => {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  async processSeekRequests() {
    this.bSeekRequestsInProcess = true
    try {
      while (this.seekRequest !== null) {
        const t = this.seekRequest
        this.seekRequest = null
        await this.restartStreaming(t)
      }
    } catch (e) {
      this.log(`processSeekRequests error: ${e}`)
    }
    this.bSeekRequestsInProcess = false
  }

  async processNextItem() {
    this.bInProcess = true
    try {
      while (this.dataQueue.length > 0 && !this.bSeekingInProcess) {
        // the order of processing matters
        const dataBlob = this.dataQueue.shift()
        const dataBuffer = await dataBlob?.arrayBuffer()
        await this.ensureSourceBufferCompleteUpdating()
        if (dataBuffer && !this.bSeekingInProcess && this.sourceBuffer) {
          this.sourceBuffer.appendBuffer(dataBuffer)
        }
      }
      await this.ensureSourceBufferCompleteUpdating()
      if (this.bEndOfStream && !this.bSeekingInProcess) {
        // Check if the sourceBuffer is in 'ended' state (optional)
        this.mediaSource?.endOfStream()
      }
    } catch (e) {
      this.log(`processNextItem error:${e}`)
    }
    this.bInProcess = false
    this.emit(Events.PROCESS_NEXT_ITEM_COMPLETED)
  }

  async clearMediaSourceData() {
    try {
      this.log('clearMediaSourceData')
      if (this.mediaSource?.readyState === 'open') {
        this.sourceBuffer?.abort()
      }
      await this.ensureSourceBufferCompleteUpdating()
      this.sourceBuffer?.buffered.length && this.sourceBuffer?.remove(0, this.sourceBuffer?.buffered.end(0) || 0)
      this.bBuffering = true
      this.bEndOfStream = false
    } catch (e) {
      this.log(`clearMediaSourceData error: ${e}`)
    }
  }

  async sendPing() {
    try {
      if (this.ws?.readyState === WebSocket.OPEN) {
        const pingEvent = {
          type: WS_MSE_EVENT_TYPES.PING,
        }
        await this.wsSendAsync(pingEvent)
      }
    } catch (e) {
      this.log(`sendPing e:${e}`)
    }
  }

  async restartStreaming(timeSec: number) {
    try {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.bSeekingInProcess = true
        this.log(`restartStreaming begin timeSec:${timeSec}`)
        this.startStreamTime = timeSec
        this.bPendingResetCurrentPos = true
        const stopEvent = { type: WS_MSE_EVENT_TYPES.STOP }
        await this.wsSendAsync(stopEvent)
        await this.ensureProcessNextItemCompleted()
        this.dataQueue.length = 0
        await this.clearMediaSourceData()
        this.bSeekingInProcess = false
        this.log('restartStreaming end')
        const playEvent = {
          type: WS_MSE_EVENT_TYPES.PLAY,
          payload: { fileId: this.fileId, timeSec },
        }
        await this.wsSendAsync(playEvent)
      } else {
        this.log('WebSocket is not open')
      }
    } catch (e) {
      this.log(`restartStreaming e:${e}`)
    }
  }

  updateServerPlayingState = () => {
    if (!this.bSeekingInProcess && !this.bEndOfStream && this.media) {
      const bufferedTime = (this.sourceBuffer?.buffered.length && this.sourceBuffer?.buffered.end(0)) || 0
      const curTime = this.originalGetCurrentTime?.call(this.media) || 0
      this.log(`handleServerPlayingState: bufferedTime: ${bufferedTime} curTime: ${curTime}`)
      if (bufferedTime && this.bPendingResetCurrentPos) {
        this.log('reset currentTime to zero')
        this.originalSetCurrentTime?.call(this.media, 0)
        this.bPendingResetCurrentPos = false
      }
      if (bufferedTime >= (curTime + bufferedTimeInterval)) {
        if (this.bBuffering) {
          this.bBuffering = false
          try {
            const pauseEvent = {
              type: WS_MSE_EVENT_TYPES.PAUSE,
              payload: {},
            }
            this.log('pause')
            this.wsSendAsync(pauseEvent)
          } catch (e) {
            this.log(`failed to send WS_MSE_EVENT_TYPES.PAUSE ${e}`)
          }
        }
      } else if (!this.bBuffering && (bufferedTime < (curTime + bufferedTimeInterval / 2))) {
        this.bBuffering = true
        try {
          const resumeEvent = {
            type: WS_MSE_EVENT_TYPES.RESUME,
            payload: {},
          }
          this.log('resume')
          this.wsSendAsync(resumeEvent)
        } catch (e) {
          this.log(`failed to send WS_MSE_EVENT_TYPES.RESUME ${e}`)
        }
      }
    }
  }

  attachMedia(media: HTMLMediaElement, src: string): void {
    const parts = src.split('/')
    const urlPart = parts.slice(0, -1).join('/').replace('media://', isHTTPS ? 'wss://' : 'ws://')
    const { timeSec, fileId } = getUrlParams(src)
    const mediaSource = new MediaSource()
    this.fileId = fileId
    this.log(`attachMedia fileId: ${fileId}`)
    this.mediaSource = mediaSource
    this.media = media
    media.src = URL.createObjectURL(mediaSource)

    // override currentTime get operation to include url start time params
    this.originalGetCurrentTime = Object.getOwnPropertyDescriptor(HTMLMediaElement.prototype, 'currentTime')?.get
    this.originalSetCurrentTime = Object.getOwnPropertyDescriptor(HTMLMediaElement.prototype, 'currentTime')?.set
    const getCurrentTimeInternal = () => {
      let ret = this.startStreamTime
      if (!this.bPendingResetCurrentPos && this.originalGetCurrentTime) {
        ret += this.originalGetCurrentTime.call(media)
      }
      this.log(`getCurrentTimeInternal ret: ${ret}`)
      return ret
    }
    const setCurrentTimeInternal = (value: number) => {
      this.log(`setCurrentTimeInternal value:${value}`)
      this.emit(Events.START_SEEK_MEDIA_SERVER_PROCESSING, value)
      this.seekRequest = value
      if (!this.bSeekRequestsInProcess) {
        this.processSeekRequests()
      }
    }
    Object.defineProperty(media, 'currentTime', {
      get: getCurrentTimeInternal,
      set: setCurrentTimeInternal,
      configurable: true, // Ensure that the property is configurable
    })
    mediaSource.addEventListener('sourceopen', () => {
      if (!this.mediaSource) {
        return // ensure the player is not destroyed
      }
      this.log(`sourceopen: fileId: ${fileId}`)
      const ws = new WebSocket(urlPart)
      this.ws = ws
      // Handle WebSocket connection
      ws.addEventListener('open', async () => {
        if (!this.ws) {
          return
        }
        try {
          const idEvent = {
            type: WS_MSE_EVENT_TYPES.ID,
            payload: { id: this.uid },
          }
          await this.wsSendAsync(idEvent)
          this.startStreamTime = Number(timeSec)
          const playEvent = {
            type: WS_MSE_EVENT_TYPES.PLAY,
            payload: { fileId, timeSec: this.startStreamTime },
          }
          this.log(`play: fileId: ${fileId} startStreamTime: ${this.startStreamTime}`)
          await this.wsSendAsync(playEvent)
          this.pingTimeout = setInterval(this.sendPing.bind(this), pingInterval)
          this.emit(Events.CONNECTED)
        } catch (e) {
          const msg = `websocket open handler error ${e}`
          this.emit(Events.ERROR, msg)
        }
      })
      ws.addEventListener('message', event => {
        const { data } = event
        if (data instanceof Blob) {
          if (!this.bSeekingInProcess) {
            // this.log(`ondata: ${data.size}`)
            this.dataQueue.push(data)
            if (!this.bInProcess) {
              this.processNextItem()
            }
          }
        } else {
          const msg = JSON.parse(data)
          const { type, payload } = msg
          switch (type) {
            case WS_MSE_EVENT_TYPES.ACK: {
              const { sequenceNumber } = payload
              const ackObj = this.pendingAcks.get(sequenceNumber)
              ackObj && ackObj.cb && ackObj.cb(sequenceNumber)
              break
            }
            case WS_MSE_EVENT_TYPES.META: {
              const { duration, mimeType } = payload
              this.sourceBuffer = mediaSource.addSourceBuffer(mimeType)
              this.sourceBuffer.addEventListener('updateend', this.updateServerPlayingState)
              this.media?.addEventListener('timeupdate', this.updateServerPlayingState)
              const data = JSON.stringify({ type: WS_MSE_EVENT_TYPES.SOURCE_BUFFER_OPENED })
              this.ws?.send(data)

              this.emit(Events.DURATION, duration)
              break
            }
            case WS_MSE_EVENT_TYPES.EOS: {
              this.log('received WS_MSE_EVENT_TYPES.EOS')
              this.bEndOfStream = true
              if (!this.bInProcess) {
                this.processNextItem()
              }
            }
          }
        }
      })
    }, { once: true })
  }

  restoreOriginalFunctions(): void {
    if (this.media) {
      if (this.originalGetCurrentTime && this.originalSetCurrentTime) {
        Object.defineProperty(this.media, 'currentTime', {
          get: this.originalGetCurrentTime,
          set: this.originalSetCurrentTime,
          configurable: true,
        })
      }
    }
  }

  destroy(): void {
    this.log(`destroy  ${this.fileId}`)
    if (this.pingTimeout) {
      clearInterval(this.pingTimeout)
      this.pingTimeout = null
    }
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close()
    }
    this.ws = null
    this.restoreOriginalFunctions()
    this.media?.removeEventListener('timeupdate', this.updateServerPlayingState)
    this.sourceBuffer = null
    this.mediaSource = null
    this.media = null
  }

}
