import { InputLabel } from '@material-ui/core'
import Slider from 'components/base/Slider'
import SettingsInput from 'components/Settings/SettingsInput'
import React, { useState } from 'react'
import { useAction } from 'hooks'
import { history } from 'actions'
import styles from './SliderSettings.module.scss'


type Props = {
  initialValue: number,
  onValueChange: (x: number) => void
  minValue: number,
  maxValue: number,
  label: string,
  disabled: boolean,
  mask: string,
}

const MAX_VALUE = 100

export default function SliderSettings(props: Props) {
  const {
    initialValue = 0,
    onValueChange = () => {},
    minValue = 0,
    maxValue = MAX_VALUE,
    label = 'Label',
    disabled = false,
    mask = 'NUM',
  } = props

  const [ value, setValue ] = useState(initialValue)
  const [ startCallbackCalled, setStartCallbackCalled ] = useState(false)
  const blockActionHistory = useAction(history.setBlockAddHistoryAction, true)
  const unblockActionHistory = useAction(history.setBlockAddHistoryAction, false)


  const stringValue = value.toString()

  const handleChange = (v: number) => {
    setValue(v)
    if (!startCallbackCalled) {
      blockActionHistory()
      setStartCallbackCalled(true)
    }
    onValueChange(v)
  }
  const handleChangeCommitted = (v: number) => {
    setStartCallbackCalled(false)
    unblockActionHistory()
    onValueChange(v)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Control max limit
    const v = e.target.value
    const valueNum = Number(v.includes('%') ? v.slice(0, -1) : v)
    const num = Math.min(maxValue, Math.max(0, valueNum))
    setValue(num)
    onValueChange(num)
  }

  return (
    <div className={styles.container}>
      <InputLabel
        classes={{
          root: styles.labelRoot,
        }}
      >
        {label}
      </InputLabel>
      <Slider
        min={minValue}
        max={maxValue}
        step={1}
        disabled={disabled}
        value={value}
        classes={{
          root: styles.sliderRoot,
          track: styles.track,
          mark: styles.mark,
          markActive: styles.markActive,
          thumb: styles.thumb,
        }}
        scale={(x: number) => 0.25 * x}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
      <SettingsInput
        onChange={handleInputChange}
        inputProps={{
          mask,
          lazy: stringValue === '',
          blocks: {
            NUM: {
              mask: Number,
              min: minValue,
              max: maxValue,
              radix: '.',
              scale: 1,
            },
          },
          placeholder: `${maxValue}%`,
          value: stringValue,
        }}
        className={styles.input}
        disabled={disabled}
      />
    </div>
  )
}
