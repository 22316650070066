import { shallowEqualArrays, shallowEqualObjects } from 'shallow-equal'
import * as RangeTools from './RangeTools'

export { RangeTools }

// 100 nanoseconds
export const TIMELINE_TIME_UNIT = 1e7

export const DEFAULT_FPS = 25

export const DEFAULT_VIDEO_WIDTH = 1920

export const DEFAULT_VIDEO_HEIGHT = 1080

export const PLAYBACK_UPDATE_FREQUENCY = 50 // ms

export const getWindowWidth = () => document.getElementById('root-app-solveig').clientWidth
export const getWindowHeight = () => document.getElementById('root-app-solveig').clientHeight

// ---
// TODO: use these two handlers all over the project, instead of inline usage of `1e7`

export function timelineTimeToSeconds(timelineTime) {
  return timelineTime / TIMELINE_TIME_UNIT
}

export function secondsToTimelineTime(seconds) {
  return Math.round(seconds * TIMELINE_TIME_UNIT) // rounding is important to solve JS's big-number problem
}

export const TIMELINE_RANGE = secondsToTimelineTime(6 * 60 * 60)

// ---

export function roundTimelineTime(time) {
  /* In project, time unit is 100ns (1e7).
  * With 2 fractional digits, a precision is 1 nanosecond.
  * More fractional digits just doesn't make practical sense. */
  return parseFloat(time.toFixed(2))
}

export const time2Pixel = (time, scale, startTimePos = 0) => {
  const scaledPixWidth = scale * getWindowWidth()
  const relativeTime = time - startTimePos
  const nPos = (relativeTime * scaledPixWidth) / TIMELINE_RANGE
  return nPos
}

export const pixel2Time = (xPosition, scale, startTimePos = 0) => {
  const scaledPixWidth = scale * getWindowWidth()
  let pos = startTimePos
  pos += (xPosition * TIMELINE_RANGE) / scaledPixWidth
  return roundTimelineTime(pos)
}

export function formatRBGColor({ r, g, b, a }) {
  return `rgba(${r},${g},${b}${a !== undefined ? `,${a}` : ''})`
}

export function n(num) {
  return num.toString().padStart(2, '0')
}

export function nnn(num) {
  return num.toString().padStart(3, '0')
}

/**
 *
 * @param  {Array<number>} rest
 * @returns {Array<number>}
 */
function roundTime(...rest) {
  const sum = rest.reduce((sum, param) => sum + param)

  return sum === 0
    ? rest.map((x, i, arr) => (i === arr.length - 1) ? x + 1 : x)
    : rest
}

export const timeToFractions = time => {
  const ms = time / 1e4
  return {
    hours: Math.floor((ms / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((ms / (1000 * 60)) % 60),
    seconds: Math.floor((ms / 1000) % 60),
    milliseconds: Math.floor(ms % 1000),
  }
}

export const refTimeToHHMMSSMSMS = (time, round = false) => {
  let {
    hours,
    minutes,
    seconds,
    milliseconds,
  } = timeToFractions(time)

  if (round) {
    [ hours, minutes, seconds, milliseconds ] = roundTime(
      hours, minutes, seconds, milliseconds
    )
  }

  return `${n(hours)}:${n(minutes)}:${n(seconds)}.${nnn(milliseconds)}`
}

export const refTimeToHHMMSSFRAME = (time, fps, round = false) => {
  let {
    hours,
    minutes,
    seconds,
    milliseconds,
  } = timeToFractions(time)

  if (round) {
    [ hours, minutes, seconds, milliseconds ] = roundTime(
      hours, minutes, seconds, milliseconds
    )
  }

  return `${n(hours)}:${n(minutes)}:${n(seconds)}:${n(Math.ceil(milliseconds / (Math.ceil(1000 / fps))))}`
}

export const HHMMSSMSMS2TimelineTime = timeString => {
  // eslint-disable-next-line prefer-const
  let [ rest, ms ] = timeString.split('.')
  const [
    hours,
    minutes,
    seconds,
  ] = rest.split(':')

  if (!!ms && ms.length < 2) ms += '00'
  else if (!!ms && ms.length < 3) ms += '0'

  const resultMs = Number(ms)
  + Number(seconds) * 1000
  + Number(minutes) * 60 * 1000
  + Number(hours) * 60 * 60 * 1000

  return resultMs * 10_000
}

export const refTimeToHHMMSS = (time, round = false) => {
  let {
    hours,
    minutes,
    seconds,
  } = timeToFractions(time)

  if (round) {
    [ hours, minutes, seconds ] = roundTime(hours, minutes, seconds)
  }

  return `${n(hours)}:${n(minutes)}:${n(seconds)}`
}

export const conditionalRefTimeToHHMMSS = (time, round = false) => {
  let {
    hours,
    minutes,
    seconds,
  } = timeToFractions(time)

  if (round) {
    [ hours, minutes, seconds ] = roundTime(hours, minutes, seconds)
  }

  if (hours === 0) {
    return `${n(minutes)}:${n(seconds)}`
  }

  return `${n(hours)}:${n(minutes)}:${n(seconds)}`
}

export function base64Img(data) {
  return `data:image/png;base64,${data}`
}

/**
 * @param {string|undefined} str
 * @return {string|undefined}
 */
export function normalizeThumbnail(str) {
  if (str === undefined) {
    return str
  }

  if (/^(\/|https?:\/\/)/.test(str)) {
    return str
  }

  if (/^data:image\//.test(str)) {
    return str
  }

  return base64Img(str)
}

export function shallowEqual(a, b) {
  if (a === b) {
    return true
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return shallowEqualArrays(a, b)
  }
  return shallowEqualObjects(a, b)
}

export function isCtrlKeyPressed(event) {
  // `metaKey` means `CMD` on MacOS.
  // Ctrl on MacOS serves for showing context menu, and doesn't affect `ctrlKey` prop.
  //
  // On Windows `metaKey` is `WIN` key.
  // Let's assume user won't click anything with `WIN` pressed (as it always opens system menu),
  // so we don't have to check for Windows explicitly.
  return event.ctrlKey || event.metaKey
}

export function isTextInput(element) {
  const tagName = element.tagName.toLowerCase()

  if (tagName === 'textarea') {
    return true
  }

  if (tagName !== 'input') {
    return false
  }

  const { type } = element
  // `text`, `number`, `email`, `color` – a lot of input types may allow text input
  // `radio` and `checkbox` are guaranteed to not to.
  return !(type === 'radio' || type === 'checkbox')
}

export function getObjectFields(object) {
  return Object.fromEntries(Object.keys(object).filter(k => object[k] !== Function).map(k => [ k.replace('_', ''), object[k] ]))
}

export const getArrayItemOfPercentage = (array, percentage) => {
  const index = Math.min(Math.floor(array.length * percentage), array.length - 1)
  return array[index]
}

export const isObject = variable => typeof variable === 'object'
  && !Array.isArray(variable)
  && variable !== null

export const approximateEqual = (a, b) => Math.abs(a - b) < 0.00001

export function bytesToSize(bytes) {
  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ]
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export function pathSep() {
  return (navigator.userAgentData.platform === 'Windows') ? '\\' : '/'
}

export function getNameFromPath(pathName) {
  return pathName.slice(pathName.lastIndexOf(pathSep()) + 1)
}

export function getPathFromPathName(pathName) {
  return pathName.slice(0, pathName.lastIndexOf(pathSep()))
}

export const getFPSByRefVideo = refVideo => (refVideo?.fpsNum / refVideo?.fpsDenum) || DEFAULT_FPS
