export const UNIT_MEASUREMENT = {
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
  MILLISECOND: 'millisecond',
  FRAME: 'frame',
} as const

export const UNIT_MEASUREMENT_TITLE = {
  [UNIT_MEASUREMENT.MILLISECOND]: 'ms',
  [UNIT_MEASUREMENT.FRAME]: 'frame',
  [UNIT_MEASUREMENT.HOUR]: 'hh',
  [UNIT_MEASUREMENT.MINUTE]: 'mm',
  [UNIT_MEASUREMENT.SECOND]: 'ss',
} as const

export const TIME_CODE_STATE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
} as const

export const TIME_CODE_CONTAINER_LOCATION = {
  TIMELINE: 'TIMELINE',
  PREVIEW: 'PREVIEW',
} as const

export const MAX_UNIT_INDEX = 3
export const DEFAULT_TIME_CODE_VALUES = {
  [UNIT_MEASUREMENT.HOUR]: '00',
  [UNIT_MEASUREMENT.MINUTE]: '00',
  [UNIT_MEASUREMENT.SECOND]: '00',
  [UNIT_MEASUREMENT.MILLISECOND]: '000',
  [UNIT_MEASUREMENT.FRAME]: '00',
}

export const PERMITTED_TIME_CODE_HOTKEYS = {
  ESC: 'Escape',
  ARROWRIGHT: 'ArrowRight',
  ARROWLEFT: 'ArrowLeft',
  ARROWUP: 'ArrowUp',
  ARROWDOWN: 'ArrowDown',
  KEY_C: 'c',
  KEY_V: 'v',
  ENTER: 'Enter',
}

export const TIME_CODE_DIGITS = {
  FIRST: 'DIGIT-FIRST',
  SECOND: 'DIGIT-SECOND',
  THIRD: 'DIGIT-THIRD',
} as const

export const TIME_CODE_DIGITS_SEQUENCE = [
  TIME_CODE_DIGITS.FIRST,
  TIME_CODE_DIGITS.SECOND,
  TIME_CODE_DIGITS.THIRD,
]

export const INCREMENT_VALUE_BY_DIGIT_TYPE = {
  [TIME_CODE_DIGITS.FIRST]: 1,
  [TIME_CODE_DIGITS.SECOND]: 10,
  [TIME_CODE_DIGITS.THIRD]: 100,
}

export const TIME_CODE_MODE = {
  FRAME: UNIT_MEASUREMENT.FRAME,
  MILLISECOND: UNIT_MEASUREMENT.MILLISECOND,
} as const

export const TIME_CODE_UNIT_PARAMS = {
  [UNIT_MEASUREMENT.HOUR]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.MINUTE]: {
    MIN: 0,
    MAX: 59,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.SECOND]: {
    MIN: 0,
    MAX: 59,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
  [UNIT_MEASUREMENT.MILLISECOND]: {
    MIN: 0,
    MAX: 999,
    MASK: '000',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.THIRD),
    INPUT_ELEMS: 3,
  },
  [UNIT_MEASUREMENT.FRAME]: {
    MIN: 0,
    MAX: 99,
    MASK: '00',
    MAX_DIGIT_INDEX: TIME_CODE_DIGITS_SEQUENCE.indexOf(TIME_CODE_DIGITS.SECOND),
    INPUT_ELEMS: 2,
  },
}

export const INITIAL_TIME_CODE_DATA = [
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.HOUR],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.HOUR].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.HOUR,
  },
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.MINUTE],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.MINUTE].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.MINUTE,
  },
  {
    value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.SECOND],
    active: false,
    editableDigit: null,
    nextDigit: null,
    inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.SECOND].INPUT_ELEMS - 1,
    id: UNIT_MEASUREMENT.SECOND,
  },
]

export const INITIAL_TIME_CODE_DATA_BY_MODE = {
  [TIME_CODE_MODE.MILLISECOND]: [
    ...INITIAL_TIME_CODE_DATA,
    {
      value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.MILLISECOND],
      active: false,
      editableDigit: null,
      nextDigit: null,
      inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.MILLISECOND].INPUT_ELEMS - 1,
      id: UNIT_MEASUREMENT.MILLISECOND,
    },
  ],
  [TIME_CODE_MODE.FRAME]: [
    ...INITIAL_TIME_CODE_DATA,
    {
      value: DEFAULT_TIME_CODE_VALUES[UNIT_MEASUREMENT.FRAME],
      active: false,
      editableDigit: null,
      nextDigit: null,
      inputIndex: TIME_CODE_UNIT_PARAMS[UNIT_MEASUREMENT.FRAME].INPUT_ELEMS - 1,
      id: UNIT_MEASUREMENT.FRAME,
    },
  ],
}

export const TIME_CODE_DATASET_ID = {
  CONTAINER: 'data-time-code-container',
  INPUT: 'data-time-code-input',
  HOUR: 'data-time-code-hour',
  MINUTE: 'data-time-code-minute',
  SECOND: 'data-time-code-second',
  MILLISECOND: 'data-time-code-millisecond',
  FRAME: 'data-time-code-frame',
  SEPARATOR: 'data-time-code-separator',
  MEASUREMENT: 'data-time-code-measurement',
  MENU: 'data-time-code-menu',
  CHEVRON: 'data-time-code-chevron',
} as const


export const TIME_CODE_DATASET_VALUES = Object.values(TIME_CODE_DATASET_ID)
export const TIME_CODE_TIME_UNITS = [
  TIME_CODE_DATASET_ID.HOUR,
  TIME_CODE_DATASET_ID.MINUTE,
  TIME_CODE_DATASET_ID.SECOND,
  TIME_CODE_DATASET_ID.MILLISECOND,
  TIME_CODE_DATASET_ID.FRAME,
]

export const TIME_CODE_MODE_VALUES = [
  {
    value: 'Timecode',
    title: 'HH:MM:SS.MSEC',
    mode: TIME_CODE_MODE.MILLISECOND,
    checkRegExp: /^\d{2}:\d{2}:\d{2}.\d{3}$/,
  },
  {
    value: 'Timecode + frames',
    title: 'HH:MM:SS:FRAME',
    mode: TIME_CODE_MODE.FRAME,
    checkRegExp: /^\d{2}:\d{2}:\d{2}:\d{2}$/,
  },
]
