import { TranslationContext } from 'contexts/TranslationContext'
import { HOTKEY_CATEGORY } from 'enums'
import { useAction, useModalDialog } from 'hooks'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { activeHotkeyProfileSelector, hotkeyProfilesOptionsSelector } from 'selectors/user-profile-selector'
import {
  addHotkeyProfile,
  searchHotkey as searchHotkeyAction,
  setActiveHotkeyProfile
} from 'actions/user'
import SearchMenu from 'components/AssetPanels/Audio/SearchMenu'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import { CrumbButton } from 'components/CrumbButton/CrumbButton'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { Chip } from 'components/Chip/Chip'
import { CategoryEntry } from 'types/menu'
import styles from '../CustomizationHotkeys.module.scss'

const menuActions = [ 'ADD_NEW', 'RENAME', 'DELETE' ]

interface IProps {
  setCategoryId: React.Dispatch<React.SetStateAction<string>>
  categoryId: string,
  deletionDialog: ReturnType<typeof useModalDialog>
  renameModal: ReturnType<typeof useModalDialog>
}

export const HotkeysHead: FC<IProps> = ({ setCategoryId, categoryId, deletionDialog, renameModal }) => {
  const { t } = React.useContext(TranslationContext)
  const [ categories ] = useState(() => Object.values(HOTKEY_CATEGORY)
    .map(c => ({ id: c, name: t(c) })))
  const setActive = useAction(setActiveHotkeyProfile)
  const handleChangeProfile = (profile: string) => {
    setActive(profile)
  }
  const searchHotkey = useAction(searchHotkeyAction)
  const hotkeyProfilesOptions = useSelector(hotkeyProfilesOptionsSelector) as CategoryEntry[]
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  // const deleteProfile = useAction(deleteHotkeyProfile)
  const addProfile = useAction(addHotkeyProfile)

  const actions = menuActions
    .map(a => ({ id: a, text: t(a), disabled: activeHotkeyProfile.readonly && (a === 'DELETE' || a === 'RENAME') }))

  const handleClickAction = (actionId: string) => {
    switch (actionId) {
      case 'ADD_NEW': {
        addProfile()
        break
      }
      case 'RENAME': {
        renameModal.open()
        break
      }
      case 'DELETE': {
        deletionDialog.open()
        break
      }
      default: {
        break
      }
    }
  }

  const handleSearch = (v: string) => {
    searchHotkey(v)
  }

  return (
    <>
      <div className={styles.presetConfigLine}>
        <div className="flex items-center">
          <span>
            {t('PRESET')}
            :
          </span>
          <SearchMenu
            items={hotkeyProfilesOptions}
            useReset={false}
            onSelect={handleChangeProfile}
            category={activeHotkeyProfile.id}
            search=""
            className={styles.presetDropdown}
          />
          <DropdownMenu
            emitFromContent
            hideChevron
            menuItems={actions}
            onClick={handleClickAction}
            dropdownClassName={styles.hotkeyDropdown}
          >
            <CrumbButton />
          </DropdownMenu>
        </div>
        <SearchInput
          className={styles.hotkeysSearch}
          placeholder={t('SEARCH_COMMAND')}
          onSearch={handleSearch}
        />
      </div>
      <div className={styles.presetChips}>
        {categories.map(category => (
          <Chip
            key={category.id}
            className={styles.hotkeyChip}
            onClick={() => setCategoryId(category.id)}
            label={category.name}
            color={categoryId === category.id ? 'primary' : undefined}
          />
        ))}
      </div>
    </>
  )
}
