import { UNIT_MEASUREMENT } from 'config/constants/timecode'
import { SEPARATOR } from 'enums'
import { ActiveTimeUnits } from 'types/timecode'

export const timeUnitsToStringValueTime = (timeUnits: ActiveTimeUnits) => (
  timeUnits.reduce((prevString, unit, index) => {
    let resultString = prevString
    if (index) {
      resultString += (unit.id === UNIT_MEASUREMENT.MILLISECOND) ? SEPARATOR.DOT : SEPARATOR.COLON
    }
    resultString += unit.value
    return resultString
  }, '')
)
