/* eslint-disable react/destructuring-assignment */
/* eslint-disable sort-class-members/sort-class-members */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { isEqual } from 'lodash'
import { Player } from './Player'
import { getVideoPlayerProgress } from './helpers/getVideoPlayerProgress'

// import { getParsedBufferedDiapasons } from './helpers/getParsedBufferedDiapasons'


const NETWORK_IDLE = 1

const mapToPlayerCallback = cb => ({ target: player }) => cb(player)

class ExtendedReactPlayer extends Component {

  player = {
    isReady: false,
    isLoading: false,
    buffering: {
      isBuffering: false,
      progress: null,
    },
    duration: null,
    videoPlayer: this.videoPlayer,
    getInternalPlayer: () => this.videoPlayer,
    getDuration: () => this.player.duration || this.videoPlayer.duration,
    getCurrentTime: () => this.videoPlayer.currentTime,
    getId: () => this.videoPlayer.id,
    getMode: () => this.props.mode,
    seekTo: seconds => {
      if (this.videoPlayer.currentTime !== seconds) {
        this.videoPlayer.currentTime = seconds
      }
      this.props.onProgress(this.player)
    },
  }

  bufferingTimeout = null
  playingProgressInterval = null
  videoPlayer = null
  onBufferingStartTime = 0

  componentDidMount() {
    this.videoPlayer = this.props.innerRef.current
    this.handleLoadStart()
    this.props.onMounted(this.player)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.clearTimeouts()
      this.nonStopWait = false
      this.handleEndBuffering()
    }

    if (!isEqual(prevProps, this.props)) {
      this.handleUpdate()
    }
  }

  componentWillUnmount() {
    this.nonStopWait = false
    this.handleEndBuffering()
    this.clearTimeouts()
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, url, innerRef,
      width, height, crossOrigin, controls, muted, className } = this.props
    return (
      <Player
        className={className}
        src={url}
        id={id}
        crossOrigin={crossOrigin}
        ref={innerRef}
        width={width}
        height={height}
        controls={controls}
        muted={muted}
        onWaiting={this.onWaiting}
        onLoadedMetadata={this.onLoadedMetaData}
        onProgress={this.onProgress}
        onPause={this.onPaused}
        onEnded={this.handleEnded}
        onCanPlayThrough={this.onCanPlayThrough}
        onSeeking={this.handleSeeking}
        onDuration={this.handleOnDuration}
      />
    )
  }

  handlePaused = () => {
    this.handleTimeUpdate()
  }

  handleEnded = () => {
    this.props.onEnded()
  }

  handleOnDuration = duration => {
    this.player.duration = duration
  }


  handleUpdate = () => {
    if (this.videoPlayer.volume !== this.props.volume) {
      this.videoPlayer.volume = this.props.volume || 0
    }
    const { buffering } = this.player
    if (this.videoPlayer) {
      if (!this.videoPlayer.paused && (buffering.isBuffering || !this.props.playing)) {
        // console.log('[PAUSE]', this.videoPlayer.id)
        this.videoPlayer.pause()
      } else if (this.videoPlayer.paused && this.props.playing && !buffering.isBuffering) {
        // console.log('[PLAY]', this.videoPlayer.id, 'buffer:', getParsedBufferedDiapasons(this.videoPlayer).map(([ start, end ]) => `[${start}-${end}]`).join(', '))
        if (this.playingProgressInterval) {
          clearInterval(this.playingProgressInterval)
        }
        this.playingProgressInterval = setInterval(
          this.handleTimeUpdate, this.props.progressInterval
        )
        this.videoPlayer.play().catch(() => { /* NOP */ })
      }
    }
  }

  handleProgress = videoPlayer => {
    const { buffering } = this.player
    const { expectedBufferingS } = this.props
    if (buffering.isBuffering && expectedBufferingS) {
      const { progress } = getVideoPlayerProgress(
        videoPlayer, expectedBufferingS
      )
      // NOTE: use 99 because there is 99.99999999999986 value is available
      if (progress > 99) {
        this.handleEndBuffering()
      } else if (buffering.progress !== progress) {
        buffering.progress = progress
        clearTimeout(this.bufferingTimeout)
        this.bufferingTimeout = setTimeout(this.handleBufferingTimeout, 10_000)
        this.props.onBufferingProgress()
      }
    }
  }

  handleStartBuffering = () => {
    const { buffering } = this.player
    const { expectedBufferingS } = this.props
    const { progress } = !expectedBufferingS ? { progress: 0 }
      : getVideoPlayerProgress(this.videoPlayer, expectedBufferingS)

    if (!buffering.isBuffering && progress <= 99) {
      buffering.progress = progress
      buffering.isBuffering = true
      this.bufferingTimeout = setTimeout(this.handleBufferingTimeout, 10_000)
      this.props.onBufferingProgress()
      // console.log('[START BUFFERING]', this.videoPlayer.id, `(${this.videoPlayer.currentTime}s)`, 'buffer:', getParsedBufferedDiapasons(this.videoPlayer).map(([ start, end ]) => `[${start}-${end}]`).join(', '))
    }
  }

  handleStartBufferingNonStop = () => {
    const { buffering } = this.player
    // waiting event triggered twice in a row: on pause and on seek
    if (Date.now() - this.onBufferingStartTime < 500) {
      return
    }

    clearTimeout(this.nonStopId)
    this.onBufferingStartTime = Date.now()

    if (this.props.active && this.isPlayerBuffering) {
      buffering.isBuffering = true
      this.nonStopWait = true
      const curTime = this.player.getCurrentTime()
      const dur = this.player.getDuration()
      const seekTime = curTime + 10 > dur
        ? dur
        : curTime + 10
      this.player.seekTo(seekTime)

      this.nonStopId = setTimeout(() => {
        if (!this.isPlayerBuffering) {
          this.nonStopWait = false
          this.handleEndBuffering()
          return
        }

        this.handleStartBufferingNonStop()
      }, 10000)
    } else {
      this.nonStopWait = false
      this.handleEndBuffering()
    }
    this.props.onBufferingProgress()
  }

  handleBufferingTimeout = () => {
    // console.log('handleBufferingTimeout')
    clearTimeout(this.bufferingTimeout)
    if (!this.isPlayerBuffering) {
      this.handleEndBuffering(true)
    } else {
      this.bufferingTimeout = setTimeout(this.handleBufferingTimeout, 10_000)
    }
  }

  handleEndBuffering = (timeout = false) => {
    if (this.props.mode === 'test' && this.nonStopWait) {
      return
    }

    const { buffering } = this.player
    if (buffering.isBuffering) {
      buffering.isBuffering = false
      clearTimeout(this.bufferingTimeout)
      clearTimeout(this.nonStopId)
      this.props.onBufferingProgress()
      if (timeout) {
        // console.log('[TIMEOUT]', this.videoPlayer.id)
      }
      this.handleUpdate()
      // console.log('[END BUFFERING]', this.videoPlayer.id, `(${this.videoPlayer.currentTime}s)`, 'state:', this.videoPlayer.readyState, 'buffer:', getParsedBufferedDiapasons(this.videoPlayer).map(([ start, end ]) => `[${start}-${end}]`).join(', '))
    }
  }

  /**
   * There are situations when readyState === 4
   * but buffered.length === 0
   * https://stackoverflow.com/questions/62989072/html5-video-readystate-4-still-video-is-hanging-during-playing
   */
  get isPlayerBuffering() {
    const { readyState, buffered, networkState } = this.videoPlayer
    if (((networkState === 1 || networkState === 2)
      && buffered.length === 0
      && readyState >= 3)) {
      return false
    }
    // there is case when readyState 2 but all data buffered available after endofStream at MSP
    if (buffered.length && buffered.end(0) >= this.player.getDuration()) {
      return false
    }
    return readyState <= 2 || !buffered.length
  }

  handleSeeking = () => {
    if (this.videoPlayer.networkState === NETWORK_IDLE && !this.isPlayerBuffering) {
      this.handleEndBuffering()
    } else if (this.props.mode === 'test') {
      // this.handleStartBufferingNonStop(true)
    } else {
      this.handleStartBuffering()
    }
  }


  handleWaiting = () => {
    if (this.props.mode === 'test') {
      this.handleStartBufferingNonStop()
    } else {
      this.handleStartBuffering()
    }
  }

  handleTimeUpdate = () => {
    if (this.videoPlayer.paused && this.playingProgressInterval) {
      clearInterval(this.playingProgressInterval)
    }
    this.props.onProgress(this.player)
  }

  handleReady = () => {
    this.player.isReady = true
    const { onReady, onDuration } = this.props
    onReady(this.player)
    onDuration(this.player.getDuration())
  }

  handleCanPlayThrough = () => {
    if (!this.props.expectedBufferingS && !this.isPlayerBuffering) {
      this.handleEndBuffering()
    }
  }

  handleLoadStart = () => {
    if (!this.props.expectedBufferingS) {
      if (this.props.mode === 'test') {
        this.handleStartBufferingNonStop()
      } else {
        this.handleStartBuffering()
      }
    }
  }

  clearTimeouts = () => {
    if (this.playingProgressInterval) {
      clearInterval(this.playingProgressInterval)
    }
    if (this.bufferingTimeout) {
      clearTimeout(this.bufferingTimeout)
    }
    if (this.nonStopId) {
      clearTimeout(this.nonStopId)
    }
  }

  onProgress = mapToPlayerCallback(this.handleProgress)
  onWaiting = mapToPlayerCallback(this.handleWaiting)
  onSeeking = mapToPlayerCallback(this.handleSeeking)
  onLoadedMetaData = mapToPlayerCallback(this.handleReady)
  onCanPlayThrough = mapToPlayerCallback(this.handleCanPlayThrough)
  onPaused = mapToPlayerCallback(this.handlePaused)

}

ExtendedReactPlayer.defaultProps = {
  playing: false,
  volume: 0,
  expectedBufferingS: null,
  progressInterval: 50,
  crossOrigin: 'anonymous',
  controls: true,
  muted: false,
  mode: 'default',
  active: false,

  onProgress: () => {},
  onReady: () => {},
  onDuration: () => {},
  onBufferingProgress: () => {},
  onMounted: () => {},
  onEnded: () => {},
}

ExtendedReactPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  playing: PropTypes.bool,
  volume: PropTypes.number,
  expectedBufferingS: PropTypes.number,
  progressInterval: PropTypes.number,
  crossOrigin: PropTypes.string,
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  mode: PropTypes.oneOf([ 'default', 'test' ]),
  active: PropTypes.bool,

  onProgress: PropTypes.func,
  onReady: PropTypes.func,
  onDuration: PropTypes.func,
  onBufferingProgress: PropTypes.func,
  onMounted: PropTypes.func,
  onEnded: PropTypes.func,
}

export default React.forwardRef((props, ref) => (
  <ExtendedReactPlayer
    innerRef={ref}
    {...props}
  />
))
