/* eslint-disable react/no-array-index-key */
import { noop } from 'lodash'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'components/base/MenuItem'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import Divider from '~/components/base/Divider/Divider'
import './DropdownMenu.scss'

export const DropdownMenu = ({ children, menuItems, chevronSize = 8, emitFromContent = false,
  className = '', dropdownClassName, hideChevron = false, onClick = noop, ...rest }) => {
  const contentRef = useRef(null)

  const [ menuEl, setMenuEl ] = useState(null)
  const openProjectMenu = React.useCallback(() => setMenuEl(contentRef.current), [])
  const closeProjectMenu = React.useCallback(() => setMenuEl(null), [])

  const onClickItem = item => {
    if (!item.disabled) {
      closeProjectMenu()
      if (item.onClick) {
        item.onClick()
      }
      onClick(item.id)
    }
  }

  const onContentClick = e => {
    if (emitFromContent) {
      e.stopPropagation()
      openProjectMenu()
    }
  }

  return (
    <>
      <div onClick={onContentClick} className={classNames(className, 'dropdown-menu')} ref={contentRef} {...rest}>
        {children}
        <If condition={!hideChevron}>
          <div className="dropdown-menu__chevron-box" onClick={openProjectMenu}>
            <div
              className={classNames('dropdown-menu__chevron', {
                'dropdown-menu__chevron--expanded': !!menuEl,
              })}
              style={{ width: `${chevronSize}px`, height: `${chevronSize}px`, marginTop: `-${chevronSize * 0.4}px` }}
            />
          </div>
        </If>
      </div>

      <Menu
        keepMounted
        getContentAnchorEl={null}
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: classNames(dropdownClassName, 'head-control__dropdown-menu'),
        }}
        open={!!menuEl}
        onClose={closeProjectMenu}
      >
        {menuItems.map((item, index) => (
          <Choose>
            <When condition={item.text}>
              <If condition={item.url}>
                <a id={item.id} href={item.url} target="_blank" rel="noopener noreferrer">
                  <MenuItem
                    key={item.text}
                    onClick={() => onClickItem(item)}
                  >
                    {item.text}
                  </MenuItem>
                </a>
              </If>

              <If condition={!item.url}>
                <span id={item.id}>
                  <MenuItem
                    key={item.text}
                    disabled={item.disabled}
                    onClick={() => onClickItem(item)}
                  >
                    {item.text}
                  </MenuItem>
                </span>
              </If>
            </When>
            <When condition={item.divider}>
              <Divider className="dropdown-divider" key={`divider-${index}`} />
            </When>
          </Choose>
        ))}
      </Menu>
    </>
  )
}
