export const WS_MSE_EVENT_TYPES = {
  ID: 'mse_id',
  PLAY: 'mse_play',
  PAUSE: 'mse_pause',
  RESUME: 'mse_resume',
  STOP: 'mse_stop',
  ACK: 'mse_ack',
  META: 'mse_meta',
  SOURCE_BUFFER_OPENED: 'mse_source_buffer_opened',
  EOS: 'mse_eos',
  PING: 'mse_ping',
}
