import { all } from 'redux-saga/effects'
import actionsHistorySaga from './actionsHistorySaga'
import actionsSyncSaga from './historySyncSaga'
import analyticsSaga from './analyticsSaga'
import timelineSaga from './timelineSaga'
import sourceFilesSaga from './sourceFilesSaga'
import mainViewSaga from './mainViewSaga'
import sdkEventsSaga from './sdkEventsSaga'
import recordingSaga from './recordingSaga'
import userSaga from './user.saga'
import previewSaga from './previewSaga'
import playback from './playback'

export function* rootSaga() {
  yield all([
    actionsHistorySaga(),
    actionsSyncSaga(),
    recordingSaga(),
    analyticsSaga(),
    timelineSaga(),
    sourceFilesSaga(),
    mainViewSaga(),
    sdkEventsSaga(),
    userSaga(),
    previewSaga(),
    playback(),
  ])
}
