import React, { FC } from 'react'
import { MovableModalContainer } from 'components/MovableModal'
import { useSelector } from 'react-redux'
import { selectShowSaveSettings } from 'selectors/mainView'
import SaveSettings from './SaveSettings'

export const SaveSettingsContainer: FC = () => {
  const isShowSaveSettings = useSelector(selectShowSaveSettings)
  return (
    <MovableModalContainer visible={isShowSaveSettings} withBackground width={713} height={320}>
      <SaveSettings />
    </MovableModalContainer>
  )
}
