import { addSourceFileAtSliderPosition } from 'actions/addSourceFileAtSliderPosition'
import FoldersTree from 'components/AssetPanels/Media/FoldersTree'
import styles from 'components/AssetPanels/Media/FoldersTree/folderTree.module.scss'
import Scrollbars from 'components/base/Scrollbars'
import { SelectDialog } from 'components/base/SelectDialog'
import { FILE_STATUS, FILE_TYPE, HOTKEYS, SOURCE_FILE_TYPES } from 'enums'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import * as Actions from '~/actions'
import { ReactComponent as DeleteIcon } from '~/assets/media/ic_delete.svg'
import { ReactComponent as MenuIcon } from '~/assets/media/ic_item_menu.svg'
import { ReactComponent as AddIcon } from '~/assets/media/ic_plus.svg'
import { ReactComponent as RetryIcon } from '~/assets/media/ic_vector.svg'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { ConfirmationDialog } from '~/components/base/ConfirmationDialog/ConfirmationDialog'
import { TranslationContext } from '~/contexts/TranslationContext'

import { MediaUsedOnDeletionError } from '~/errors/MediaUsedOnDeletionError'
import {
  useAction,
  useBind,
  useModalDialog,
  useOnClickWithoutPropagation
} from '~/hooks'
import * as Selectors from '~/selectors'
import './MediaControls.scss'
import { MediaItemMenu } from './MediaItemMenu/MediaItemMenu'
import { BaseAssetsListContext } from '../BaseAssetsList'

function DeleteKeyListener({ handler }) {
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.DELETE], handler)

  return null
}

export default function MediaControls({
  item,
  isFolder,
  useRemove = true,
  useMenu = true,
}) {
  const { t } = useContext(TranslationContext)
  const { selectedItems } = useContext(BaseAssetsListContext)
  const onAddToTimeline = useAction(
    addSourceFileAtSliderPosition,
    item.id
  )

  const onRequestDeleteMedia = useAction(
    Actions.sourceFiles.requestDeleteFile,
    item.id,
    SOURCE_FILE_TYPES.MEDIA
  )

  const onRequestDeleteSelectedMediaAssets = useAction(
    Actions.sourceFiles.requestDeleteFile,
    selectedItems.map(item => item.id),
    SOURCE_FILE_TYPES.MEDIA
  )
  const deleteMediaForce = useBind(onRequestDeleteMedia, true)
  const deleteFolder = useAction(
    Actions.sourceFiles.requestDeleteFolder,
    item.id,
    SOURCE_FILE_TYPES.MEDIA
  )
  const deleteFolderForce = useBind(deleteFolder, true)
  const onRetryUpload = useAction(Actions.upload.retryUpload, item.id)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const selectedAssets = useSelector(Selectors.getSelectedAssets)
  const openSaveSettings = useAction(Actions.mainView.showSaveSettings, true)
  const setProjectSelectedTask = useAction(
    Actions.project.setSelectedTask,
    item.taskId
  )

  const onShowSaveSettings = useCallback(() => {
    setProjectSelectedTask()
    openSaveSettings()
  }, [ setProjectSelectedTask, openSaveSettings ])

  const areThereFolders = useSelector(state => Selectors.selectAreThereFolders(state, {
    type: SOURCE_FILE_TYPES.MEDIA,
    selectedItems: [ item.id ],
  }))

  const filter = useSelector(
    state => Selectors.selectSourceFilesParams(state, SOURCE_FILE_TYPES.MEDIA).filter
  )

  const itemMenuRef = useRef(null)

  const [ errorTitle, setErrorTitle ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ isOpenItemMenu, setIsOpenItemMenu ] = useState(false)
  const [ selectedFolder, setSelectedFolder ] = useState(null)

  const forceDeletionDialog = useModalDialog({
    onConfirm: isFolder ? deleteFolderForce : deleteMediaForce,
  })

  const onError = e => {
    if (e instanceof MediaUsedOnDeletionError) {
      const { title, type } = e
      setErrorTitle(t(title))
      setErrorMessage(t(type))
      forceDeletionDialog.open()
    }
  }

  const deleteItem = async () => {
    try {
      if (isFolder) {
        await deleteFolder()
      } else {
        await onRequestDeleteMedia()
      }
    } catch (e) {
      onError(e)
    }
  }

  const onDeleteItems = async () => {
    if (!isEmpty(selectedItems)) {
      try {
        await onRequestDeleteSelectedMediaAssets()
      } catch (e) {
        onError(e)
      }
    }
  }

  const singleDeletion = useModalDialog({ onConfirm: deleteItem })
  const multipleDeletion = useModalDialog({ onConfirm: onDeleteItems })

  const stopPropagation = useOnClickWithoutPropagation()
  const handleClickDelete = useOnClickWithoutPropagation(singleDeletion.open)

  const handleClickMenu = useOnClickWithoutPropagation(() => {
    setIsOpenItemMenu(true)
  })

  const handleCloseItemMenu = () => {
    setIsOpenItemMenu(false)
  }

  const onMoveMediaItemsToFolder = useAction(
    Actions.sourceFiles.moveMediaItemsToFolder,
    {
      folders: isFolder ? [ item.id ] : [],
      files: isFolder ? [] : [ item.id ],
      targetFolder: selectedFolder,
      type: SOURCE_FILE_TYPES.MEDIA,
    }
  )

  const foldersDialog = useModalDialog({ onConfirm: onMoveMediaItemsToFolder })

  const handleClickMoveFiles = useOnClickWithoutPropagation(() => {
    foldersDialog.open()
    handleCloseItemMenu()
  })

  const currentFolder = useSelector(
    state => Selectors.selectCurrentFolderId(state, SOURCE_FILE_TYPES.MEDIA)
  )

  const isTopLayer = currentFolder === undefined

  useEffect(() => {
    if (!foldersDialog.active) setSelectedFolder(null)
  }, [ foldersDialog.active ])

  const handleFolderSelect = useCallback(id => {
    setSelectedFolder(id)
  }, [])

  // for Scrollbars rerender
  const [ folderTreeLoading, setFolderTreeLoading ] = useState(true)

  const isOutputFiletype = item.type === FILE_TYPE.OUTPUT

  const selectedMediaItems = useMemo(() => [ item.id ], [ item.id ])

  const deleteBtnEnabled = (__APP_PROFILE__ === 'vrspot'
    ? filter === 'voiceover'
    : __CFG__.BTN_DELETE_CLIP)
    && item.canRemove
    && useRemove

  const customMediaTabContent = useSelector(
    state => state.user.customMediaTabContent
  )
  const customMenuEnabled = __CFG__.SOURCE_FILES_MANAGEMENT.MOVE_TO_FOLDERS
    && !customMediaTabContent
    && (isTopLayer ? isOutputFiletype || areThereFolders : true)
    && useMenu
  const { getTooltip } = useHotkeyTooltip()

  return (
    <>
      <div className="media-file__controls">
        <If condition={deleteBtnEnabled}>
          <If condition={(selectedClipId === item.id) && !selectedAssets?.length}>
            <DeleteKeyListener handler={multipleDeletion.open} />
          </If>
          <div
            title={getTooltip(t('MEDIA_CONFIRM_BTN_DELETE_TOOLTIP'), HOTKEYS.DELETE)}
            className="media-file__controls-item media-file__controls-item--delete"
            onClick={handleClickDelete}
            onMouseDown={stopPropagation}
            onMouseUp={stopPropagation}
          >
            <DeleteIcon />
          </div>
          {/** for one-time deletion */}
          <If condition={singleDeletion.active}>
            <ConfirmationDialog
              onClick={stopPropagation}
              onCancel={singleDeletion.cancel}
              onConfirm={singleDeletion.confirm}
              confirmBtnText={t('MEDIA_CONFIRM_BTN_DELETE')}
              closeBtnText={t('MEDIA_CANCEL_BTN_DELETE')}
              description={t('MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED')}
            />
          </If>
          {/** for multiple deletion */}
          <If condition={multipleDeletion.active}>
            <ConfirmationDialog
              onClick={stopPropagation}
              onCancel={multipleDeletion.cancel}
              onConfirm={multipleDeletion.confirm}
              confirmBtnText={t('MEDIA_CONFIRM_BTN_DELETE')}
              closeBtnText={t('MEDIA_CANCEL_BTN_DELETE')}
              description={t('MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED')}
            />
          </If>
          <If condition={forceDeletionDialog.active}>
            <ConfirmationDialog
              onClick={stopPropagation}
              onCancel={forceDeletionDialog.cancel}
              onConfirm={forceDeletionDialog.confirm}
              title={errorTitle}
              description={errorMessage}
              confirmBtnText={t('MEDIA_CONFIRM_BTN_DELETE')}
              closeBtnText={t('MEDIA_CANCEL_BTN_DELETE')}
            />
          </If>
        </If>

        <Choose>
          <When condition={item.error !== undefined}>
            <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.RETRY_UPLOAD}>
              <div
                className="media-file__controls-item media-file__controls-item--retry"
                onClick={onRetryUpload}
                onMouseDown={stopPropagation}
                onMouseUp={stopPropagation}
              >
                <RetryIcon />
              </div>
            </If>
          </When>
          <When
            condition={
              __CFG__.BTN_ADD_CLIP && item.status === FILE_STATUS.READY
            }
          >
            <div
              className="media-file__controls-item media-file__controls-item--add"
              onClick={onAddToTimeline}
              onMouseDown={stopPropagation}
              onMouseUp={stopPropagation}
              title={getTooltip(t('MEDIA_BTN_ADD_TOOLTIP'), HOTKEYS.ADD_TO_TIMELINE)}
            >
              <AddIcon />
            </div>
          </When>
        </Choose>
      </div>
      <If condition={customMenuEnabled}>
        <div
          ref={itemMenuRef}
          className="media-file__controls media-file__controls--bottom"
        >
          <div
            title="Menu"
            className="media-file__controls-item media-file__controls-item--save"
          >
            <MenuIcon
              onClick={handleClickMenu}
              onMouseUp={stopPropagation}
              onMouseDown={stopPropagation}
            />
          </div>
        </div>
      </If>
      <If condition={isOpenItemMenu}>
        <MediaItemMenu
          anchorEl={itemMenuRef.current}
          open={isOpenItemMenu}
          isFolder={isFolder}
          showMoveTo={areThereFolders}
          isTopLayer={isTopLayer}
          showSettings={isOutputFiletype}
          onClickMoveFiles={handleClickMoveFiles}
          onClickShowSettings={onShowSaveSettings}
          onClickMoveToMedia={onMoveMediaItemsToFolder}
          onClose={handleCloseItemMenu}
        />
      </If>
      <If condition={foldersDialog.active}>
        <SelectDialog
          title={t('MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER')}
          confirmBtnText={t('MEDIA_CONFIRM_BTN_OK')}
          onCancel={foldersDialog.cancel}
          onClick={stopPropagation}
          onMouseUp={stopPropagation}
          onConfirm={() => foldersDialog.confirm()}
          dataLangConfirmId="MEDIA_CONFIRM_BTN_OK"
          buttonConfirmTitle={t('MEDIA_CONFIRM_BTN_OK_TOOLTIP')}
        >
          <div className={styles.folderTreeContainer}>
            <Scrollbars>
              <FoldersTree
                onInit={setFolderTreeLoading}
                selectedFolder={selectedFolder}
                onSelect={handleFolderSelect}
                selectedMediaItems={selectedMediaItems}
              />
            </Scrollbars>
            <If condition={folderTreeLoading}>
              <div className={styles.folderTreeProgress}>
                <CircularProgress
                  size={100}
                  endless
                  percents={80}
                  text={`${t('MEDIA_PROGRESS_TEXT_LOADING')}..`}
                  transparent
                />
              </div>
            </If>
          </div>
        </SelectDialog>
      </If>
    </>
  )
}
