import { useRef, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Selectors from '~/selectors'
import { PlaceholderAsset, TransitionAsset } from '~/models/Asset'
import { DraggingItemContext } from '~/components/Timeline/DraggingItemProvider'
import { time2Pixel } from '~/Util'
import { useMultipleDragRectCoords } from './useMultipleDragRectCoords'
import { snapToLayer } from './snapToLayer'
import { getPlaceholdersByLayerIndex } from './getPlaceholdersByLayerIndex'
import { getStaticAssetsByLayerIndex } from './getStaticAssetsByLayerIndex'
import { getStartNTopDraggableLayers } from './getStartNTopDraggableLayers'


export const useMultipleDragging = ({
  dragItem,
  clientOffset,
  initialClientOffset,
  scale,
  overNewLayerIndex,
  scrollTop,
  targetLayerIndex,
  sourceOffset,
  timelineTopOffset,
  timelineLeftOffset,
  scrollLeft,
  isMultipleDrag,
  sliderTimeX,
  dragAssetsWidth,
  setSliderSticky,
  timelineMouseDown,
}) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (!isMultipleDrag) return {}

  const { setProps: setDraggingItemProps } = useContext(DraggingItemContext)

  const transitions = useSelector(state => Selectors.getTransitionAssets(state))
  const leftXOffset = timelineLeftOffset - scrollLeft

  // History of movement of dragged assets on the timeline
  const historyX = useRef({
    initialDropX: 0,
    initialDraggableBlockLeftX: 0,
    dropX: 0,
    draggableBlockLeftX: 0,
    quickOffset: 0, // to quickly move (appear) clips when leaving an intersection with the mouse pointer
    leftSideX: 0,
    readyForDrop: false,
    isIntersected: false,
    intersectedData: null,
    moveDirection: null,
    currentLayerIndex: null,
  })

  const multipleDragAssets = useSelector(state => Selectors
    .getSelectedAssetsByIds(state, dragItem.selectedDragIds))

  const allAssets = useSelector(Selectors.getAssets)
  const staticAssets = useMemo(() => allAssets.filter(a => !(a instanceof PlaceholderAsset)
  && !(a instanceof TransitionAsset)
  && !dragItem.selectedDragIds?.includes(a.id)), [ dragItem.selectedDragIds, allAssets ])

  const draggableLayers = getStartNTopDraggableLayers(dragItem)
  // Offset of the currently dragged asset relative to the top asset
  const layerIndexOffset = draggableLayers.startLayer.index - draggableLayers.topLayer.index
  const layers = useSelector(Selectors.getLayers)
  const layerIds = useMemo(() => layers.map(layer => layer.id), [ layers ])

  const staticAssetsByLayerIndex = useMemo(() => getStaticAssetsByLayerIndex(
    staticAssets, layerIds
  ), [ layerIds, staticAssets ])
  const placeholdersByLayerIndex = useMemo(() => getPlaceholdersByLayerIndex(
    staticAssetsByLayerIndex, layerIds
  ), [ layerIds, staticAssetsByLayerIndex ])

  const {
    draggableBlockLeftX,
    draggableBlockY,
    intersectionPlaceholder,
  } = useMultipleDragRectCoords({
    dragItem,
    clientOffset,
    initialClientOffset,
    sourceOffset,
    leftXOffset,
    timelineTopOffset,
    setDraggingItemProps,
    historyX,
    staticAssets,
    scale,
    draggbleAssets: dragItem.selectedAssets ?? [],
    overNewLayerIndex,
    layers,
    layerIds,
    targetLayerIndex,
    placeholdersByLayerIndex,
    layerIndexOffset,
    isMultipleDrag,
    transitions,
    sliderTimeX,
    dragAssetsWidth,
    setSliderSticky,
    timelineMouseDown,
  })

  // Coords and wodth for preview for placeholder paste
  const multipleSnappedPreview = {
    x: time2Pixel(intersectionPlaceholder?.startTime ?? 0, scale) + leftXOffset,
    width: time2Pixel(intersectionPlaceholder?.duration ?? 0, scale),
    y: snapToLayer({
      layerIndex: targetLayerIndex,
      timelineScrollTop: scrollTop,
    }),
  }

  return {
    draggableBlockLeftX,
    draggableBlockY: !overNewLayerIndex
      ? snapToLayer({
        layerIndex: targetLayerIndex - layerIndexOffset,
        timelineScrollTop: scrollTop,
      })
      : draggableBlockY,
    multipleDragAssets,
    transitions,
    multipleSnappedPreview,
  }
}
