import { all, put, select, takeEvery } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import { loadSourceFiles } from '~/actions/sourceFiles'
import { LOCAL_STORAGE_SOURCE_FILES_PARAMS, STORAGE_SOURCE_FILES_NAV_HISTORY, STORAGE_SOURCE_FILES_NAV_HISTORY_INDEX } from '~/constant'
import { getSourceFiles, selectSourceFilesNavHistory, selectSourceFilesNavHistoryIndex, selectSourceFilesParams } from '~/selectors'

const saveGroupValueToStorage = (key, type, value) => {
  localStorage.setItem(key, JSON.stringify({
    ...JSON.parse(localStorage.getItem(key)),
    [type]: value,
  }))
}

function* handleLoadSourceFiles({ payload }) {
  const { type } = payload
  const sourceFilesParams = yield select(state => selectSourceFilesParams(state, type))
  const sourceFilesNavHistory = yield select(
    state => selectSourceFilesNavHistory(state, type)
  )
  const sourceFilesNavHistoryIndex = yield select(
    state => selectSourceFilesNavHistoryIndex(state, type)
  )

  // eslint-disable-next-line camelcase
  const { order, sort, filter, folder,
    currentAudioType = 'music', audioFilter = {}, category, source, search } = sourceFilesParams

  saveGroupValueToStorage(
    LOCAL_STORAGE_SOURCE_FILES_PARAMS,
    type,
    { order, sort, filter, folder, audioFilter, currentAudioType, category, source, search }
  )
  saveGroupValueToStorage(
    STORAGE_SOURCE_FILES_NAV_HISTORY,
    type,
    sourceFilesNavHistory
  )
  saveGroupValueToStorage(
    STORAGE_SOURCE_FILES_NAV_HISTORY_INDEX,
    type,
    sourceFilesNavHistoryIndex
  )

  yield put(loadSourceFiles(type, { force: true }))
}

function* handleDeleteSourceFile({ payload }) {
  const { type } = payload
  const items = yield select(state => getSourceFiles(state, type))
  yield put(loadSourceFiles(type, { type, start: items.length, count: 1, force: true }))
}

function* watchAll() {
  yield all([
    takeEvery([
      ActionTypes.SOURCE_FILES_SET_PARAMS,
      ActionTypes.ACTIONS_MEDIA_DIRECTORY_BACKWARD,
      ActionTypes.ACTIONS_MEDIA_DIRECTORY_FORWARD,
      ActionTypes.ACTIONS_MEDIA_DIRECTORY_OPEN,
    ], handleLoadSourceFiles),
    takeEvery([
      ActionTypes.SOURCE_FILE_DELETED,
      ActionTypes.SOURCE_FILES_MOVED ], handleDeleteSourceFile),
  ])
}

export default watchAll
