import { combineReducers } from 'redux'
import historyActions from './actionsHistory'
import layer from './layer'
import mainView from './mainView'
import playback from './playback'
import project from './project'
import projectData from './projectData'
import sourceFiles from './sourceFiles'
import preview from './preview'
import timeline from './timeline'
import user from './user'
import recording from './recording'
import app from './app'
import userInterface from './interface'

const reducers = combineReducers({
  app,
  timeline,
  layer,
  playback,
  project,
  preview,
  user,
  mainView,
  sourceFiles,
  projectData,
  historyActions,
  recording,
  userInterface,
})

export default reducers
