import React, { FC } from 'react'
import { useMediaAssetSettingsForm } from 'hooks/media/settings/useMediaAssetSettingsForm'
import { MovableModalContainer } from 'components/MovableModal'
import MediaAssetSettingsForm from './Form'

const SettingsContainer: FC = () => {
  const { showSettings, onUpdateAsset, asset, type } = useMediaAssetSettingsForm()
  return (
    <MovableModalContainer visible={showSettings}>
      <MediaAssetSettingsForm
        type={type}
        asset={asset}
        onChange={onUpdateAsset}
        // ensure form is re-created and it's internal state, if any, is resetted
        key={asset?.id}
      />
    </MovableModalContainer>
  )
}
export default SettingsContainer
