import React, { FC, memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { PreviewPlayerContext } from 'contexts/PreviewPlayerContext'
import { NWShortCutContext } from 'contexts/NWShortCutContext'


export const PreviewPlayerContextProvider: FC = memo(({ children }) => {
  const [ previewPlayer, setPreviewPlayer ] = useState<HTMLDivElement | null>(null)
  const previewPlayerBox = useRef<HTMLDivElement | null>(null)

  const onSetPreviewPlayer: React.RefCallback<HTMLDivElement> = useCallback(ref => {
    setPreviewPlayer(ref)
  }, [ setPreviewPlayer ])

  const { registerNWGlobalHotKey, unRegisterNWGlobalHotKey } = useContext(NWShortCutContext)

  const handleFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
      unRegisterNWGlobalHotKey()
    } else if (previewPlayer) {
      previewPlayer.requestFullscreen()
      registerNWGlobalHotKey()
    }
  }, [ registerNWGlobalHotKey, unRegisterNWGlobalHotKey, previewPlayer ])

  const values = useMemo(() => ({
    previewPlayer,
    previewPlayerBox,
    onSetPreviewPlayer,
    handleFullscreen,
  }), [
    previewPlayer,
    previewPlayerBox,
    onSetPreviewPlayer,
    handleFullscreen,
  ])

  return (
    <PreviewPlayerContext.Provider value={values}>
      {children}
    </PreviewPlayerContext.Provider>
  )
})
