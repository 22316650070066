import { Box } from '@material-ui/core'
import { setActivePreview, setPreviewLoading, setPreviewMode, toggleSplitPreview } from 'actions/preview'
import classNames from 'classnames'
import { PREVIEW_MODE, SPLIT_PREVIEW_BUTTONS } from 'config/constants/preview'
import { TranslationContext } from 'contexts/TranslationContext'
import { PLAYER_TYPE } from 'enums'
import React, { FC, MouseEvent, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectorActivePreview } from 'selectors/preview'
import { PlayerType } from 'types/common'
import { PreviewModeType } from 'types/preview'
import splitter from '~/assets/preview/splitter.svg'

interface IProps {
 type: string,
 name?:string,
 togglePreview?(e: MouseEvent): void,
 showSplitter: boolean,
 playerType: PlayerType
 isSplitted: boolean,
 activeMode: PreviewModeType,
}

export const PreviewPlayerHead: FC<IProps> = ({ activeMode, type, name, showSplitter, playerType, isSplitted }) => {
  const activePreview = useSelector(selectorActivePreview)
  const { t } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const togglePreview = useCallback(() => {
    dispatch(toggleSplitPreview())
  }, [ dispatch ])

  const onSetPreview = useCallback((mode: PreviewModeType) => () => {
    dispatch(setPreviewMode(mode))
    if (mode !== PREVIEW_MODE.AUTO) {
      let preview
      switch (mode) {
        case PREVIEW_MODE.SOURCE:
          preview = PLAYER_TYPE.MEDIA
          break
        case PREVIEW_MODE.TIMELINE:
          preview = PLAYER_TYPE.TIMELINE
          break
      }
      if (preview !== activePreview) {
        dispatch(setPreviewLoading(true))
        dispatch(setActivePreview(preview))
      }
    }
  }, [ dispatch, activePreview ])

  return (
    <div className="head__item item">
      <Box
        display="flex"
        alignItems="center"
        gridGap="10px"
        className={classNames('item__box', { active: activePreview === playerType })}
      >
        <span className={classNames('item__type', { active: activePreview === playerType })}>
          {type}
        </span>
        <span className="item__name">{name || ''}</span>
      </Box>
      <Box className="item__preview-splitting preview-splitting">
        <Box className={classNames('preview-splitting__buttons button', { hidden: isSplitted })}>
          {SPLIT_PREVIEW_BUTTONS.map(({ name, mode }) => (
            <Box
              className={classNames('button__item', { active: mode === activeMode })}
              onClick={onSetPreview(mode as PreviewModeType)}
              key={mode}
            >
              {t(name)}
            </Box>
          ))}
        </Box>
        {!!(!isSplitted || (showSplitter && __APP_PROFILE__ !== 'itochu'))
          && <img src={splitter} alt="splitter" onClick={togglePreview} />}
      </Box>
    </div>
  )
}
