/* eslint-disable no-alert */
import { patchDevProfile } from 'ServerAPI'
import { showCustomizeView } from 'actions/mainView'
import { patchUserProfile } from 'actions/user'
import ButtonBase from 'components/base/Button'
import { FormField } from 'components/base/FormField/FormField'
import Input from 'components/base/Input'
import MessageBox from 'components/base/MessageBox'
import Scrollbars from 'components/base/Scrollbars'
import { MAX_LOGO_SIZE_BYTES, MAX_LOGO_SIZE_PX } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import { debounce, isNil } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { userProfileSelector } from 'selectors/user-profile-selector'
import { translations } from 'translations/translations'
import defaultAvatar from '~/assets/avatar.svg'
import { ReactComponent as LogoExample } from '~/assets/customization/logo-example.svg'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import Accordion from '~/components/base/Expansion'
import { CustomizationCss } from './CustomizationCss'
import { CustomizationJs } from './CustomizationJs'
import { CustomizationText } from './CustomizationText'
import './CustomizationView.scss'
import { LogoUploader } from './LogoUploader'

export const CustomizationView = () => {
  const { t } = React.useContext(TranslationContext)
  const onClose = useAction(showCustomizeView, false)
  const logoLimitString = `${t('CUSTOMIZATION_TEXT_ACCEPTED_FILES')} ${MAX_LOGO_SIZE_PX}x${MAX_LOGO_SIZE_PX}px PNG or SVG, ≤ ${MAX_LOGO_SIZE_BYTES / 1e6}MB.`
  const patchUser = useAction(patchUserProfile)
  const userProfile = useSelector(userProfileSelector)
  const [ form, setForm ] = useState({ ...userProfile })

  const [ error, setError ] = React.useState('')

  const patchFn = v => {
    patchDevProfile(v)
      .then(() => patchUser(v))
      .catch(e => {
        setError(e?.response?.data?.status || 'Something went wrong')
      })
  }

  const debouncedPatch = React.useCallback(debounce(patchFn, 500, { leading: true }), [])
  const debouncedPatchNoLeading = React.useCallback(debounce(patchFn, 500, { leading: false }), [])
  const debouncedPatchWithTrailing = React.useCallback(
    debounce(patchFn, 500, { trailing: true }), []
  )

  React.useEffect(() => {
    if (userProfile) {
      setForm({ ...userProfile })
    }
  }, [ userProfile ])

  const changesHandler = (key, leading = true) => value => {
    if (value !== form[key]) {
      const newForm = { ...form, [key]: value }
      if (leading) {
        debouncedPatch(newForm)
      } else {
        setForm(newForm)
        debouncedPatchNoLeading(newForm)
      }
    }
  }

  const handleResetChanges = () => {
    const initForm = {
      companyName: __CFG__.COMPANY_NAME,
      appTitle: __CFG__.TITLE,
      svgLogo: '',
      favIcon: '',
      hideSVGLogo: null,
      hideDefaultUserAvatar: null,
      customJS: null,
    }
    setForm(initForm)
    debouncedPatchNoLeading({ hideSVGLogo: null, hideDefaultUserAvatar: null })
    debouncedPatchWithTrailing(initForm)
  }

  return (
    <div className="customization">
      <div className="customization__top">
        <span className="customization__caption">
          {t('CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR')}
        </span>
        <div className="customization__close" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <Scrollbars style={{ height: 'calc(100% - 86px)' }}>
        <div style={{ padding: '20px' }}>
          <Accordion
            className="customization_accordion"
            caption={t('CUSTOMIZATION_CAPTION_LOGO_AND_NAME')}
            disabled={__APP_PROFILE__ === 'itochu'}
            defaultExpanded={__APP_PROFILE__ !== 'itochu'}
          >
            <FormField className="customization_formfield" caption={t('CUSTOMIZATION_CAPTION_COMPANY_NAME')}>
              <Input value={!isNil(form.companyName) ? form.companyName : __CFG__.COMPANY_NAME || ''} onChange={e => changesHandler('companyName', false)(e.target.value)} />
            </FormField>
            <FormField className="customization_formfield" caption={t('CUSTOMIZATION_CAPTION_EDITOR_NAME')}>
              <Input value={form.appTitle || ''} onChange={e => changesHandler('appTitle', false)(e.target.value)} />
            </FormField>
            <div className="customization_logo-example">
              <LogoExample />
            </div>
            <FormField
              className="customization_formfield"
              caption={t('CUSTOMIZATION_FORM_CAPTION_LOGO')}
              description={`${t('CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO')} ${logoLimitString}`}
            >
              <LogoUploader
                name={t('CUSTOMIZATION_FORM_FIELD_NAME_LOGO')}
                btnText={t('CUSTOMIZATION_BTN_UPLOAD_LOGO')}
                logo={form.svgLogo || ''}
                onChange={changesHandler('svgLogo')}
                logoLimitString={logoLimitString}
                hidden={form.hideSVGLogo === true}
                onChangeHidden={changesHandler('hideSVGLogo', false)}
                dataLangId="CUSTOMIZATION_BTN_UPLOAD_LOGO"
                title={t('CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP')}
              />
            </FormField>
          </Accordion>
          <Accordion
            className="customization_accordion"
            caption={t('CUSTOMIZATION_FORM_CAPTION_USER_AVATAR')}
            disabled={__APP_PROFILE__ === 'itochu'}
            defaultExpanded={__APP_PROFILE__ !== 'itochu'}
          >
            <FormField
              className="customization_formfield"
              description={`${t('CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR')} ${logoLimitString}`}
            >
              <LogoUploader
                name={t('CUSTOMIZATION_FORM_FIELD_NAME_AVATAR')}
                logo={form.defaultUserAvatar || ''}
                defaultImg={defaultAvatar}
                onChange={changesHandler('defaultUserAvatar')}
                logoLimitString={logoLimitString}
                hidden={form.hideDefaultUserAvatar === true}
                onChangeHidden={changesHandler('hideDefaultUserAvatar', false)}
              />
            </FormField>
          </Accordion>
          <Accordion className="customization_accordion" caption={t('CUSTOMIZATION_FORM_CAPTION_COLOR_THEME')}>
            <CustomizationCss customCss={form.customCSS || ''} onChange={changesHandler('customCSS')} />
          </Accordion>
          <Accordion
            className="customization_accordion"
            caption={t('CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT')}
            disabled={__APP_PROFILE__ === 'itochu'}
            defaultExpanded={__APP_PROFILE__ !== 'itochu'}
          >
            <FormField description={t('CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING')}>
              <CustomizationJs text={form.customJS || ''} onChange={changesHandler('customJS')} />
            </FormField>
          </Accordion>
          <Accordion className="customization_accordion" caption={t('CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT')}>
            <FormField description={t('CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT')}>
              <CustomizationText text={form.customLanguage || JSON.stringify(translations, null, 2)} onChange={changesHandler('customLanguage')} />
            </FormField>
          </Accordion>
        </div>
      </Scrollbars>
      <div className="customization_actions">
        <ButtonBase
          className="customization_edit-btn"
          border
          onClick={handleResetChanges}
          data-lang-id="CUSTOMIZATION_BTN_RESET_ALL_CHANGES"
          title={t('CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP')}
        >
          {t('CUSTOMIZATION_BTN_RESET_ALL_CHANGES')}
        </ButtonBase>
      </div>
      {error && (
        <MessageBox
          title="Customization saving error"
          message={error}
          onClose={() => setError(false)}
        />
      )}
    </div>
  )
}
