import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import * as PT from '~/PropTypes'
import * as Actions from '~/actions'
import Scrollbars from '~/components/base/Scrollbars'
import { useAction } from '~/hooks'
import * as Selectors from '~/selectors'
import './BaseAssetsPanel.scss'
import CustomDragLayer from './CustomDragLayer'
import Voiceover from './Voiceover'
import Webcam from './Webcam'

export default function BaseAssetsPanel(props) {
  const { loadTypes, type, ListComponent } = props

  const mediaRecordingOpened = useSelector(Selectors.recording.selectMediaRecordingSettingsState)

  const mediaRecordingClose = useAction(Actions.mainView.closeMediaRecordingSettings)

  const {
    items,
    allFilesFetched,
    totalCount,
  } = useSelector(state => Selectors.getSourceFilesGroup(state, type))
  // ---

  // TODO: pass request params somehow
  const fetchFiles = useAction(Actions.sourceFiles.loadSourceFiles, loadTypes ?? type)

  React.useEffect(
    () => {
      if (!allFilesFetched && items.length === 0) {
        fetchFiles()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ fetchFiles, allFilesFetched ]
  )

  const onScroll = useCallback(
    values => {
      // when we reach end of the scroll area, load next portion of files
      // we can use onUpdate of Scrollbars if we need to start loading
      // earlier, before we reach the bottom
      if (values.top >= 0.9 && (!allFilesFetched || !totalCount)) {
        fetchFiles({ start: items.length, count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allFilesFetched, fetchFiles, items.length ]
  )
  // ---

  return (
    <Scrollbars className="vertical-scrollbar">
      <Choose>
        <When condition={mediaRecordingOpened === 'audio'}>
          <Voiceover onClose={mediaRecordingClose} />
        </When>
        <When condition={mediaRecordingOpened === 'video'}>
          <Webcam onClose={mediaRecordingClose} />
        </When>
        <Otherwise>
          <CustomDragLayer />
          <ListComponent onScroll={onScroll} type={type} />
        </Otherwise>
      </Choose>

    </Scrollbars>
  )
}

BaseAssetsPanel.defaultProps = {
  loadTypes: undefined,
}

BaseAssetsPanel.propTypes = {
  type: PT.SourceFileType.isRequired,
  loadTypes: PropTypes.arrayOf(PT.SourceFileType),
  ListComponent: PropTypes.elementType.isRequired,
}
