import { MARK_TYPE } from 'config/constants/preview'
import { PLAYER_TYPE } from 'enums'
import { LocalClipCreatorMarkerType } from './types'

export const INITIAL_MOUSE_MOVEMENT_DATA = {
  markType: null,
  left: 0,
  savedProgress: null,
  dragging: false,
  draggingX: 0,
  startX: null,
  leftBarX: null,
  rightBarX: null,
}

export const CLIP_CREATOR_LOCAL_MARKERS: LocalClipCreatorMarkerType = {
  [PLAYER_TYPE.MEDIA]: {
    [MARK_TYPE.IN]: null,
    [MARK_TYPE.OUT]: null,
  },
  [PLAYER_TYPE.TIMELINE]: {
    [MARK_TYPE.IN]: null,
    [MARK_TYPE.OUT]: null,
  },
}
