import { TIME_CODE_MODE, TIME_CODE_MODE_VALUES } from 'config/constants/timecode'
import { SEPARATOR } from 'enums'
import { secondsToTimelineTime } from 'Util'

export const stringValueToTimeLineTime = (strTime: string, fps: number) => (
  TIME_CODE_MODE_VALUES.reduce((result, modeValues) => {
    if (modeValues.checkRegExp.test(strTime)) {
      switch (modeValues.mode) {
        case TIME_CODE_MODE.FRAME: {
          const HHMMSSFFAsArray = strTime.split(SEPARATOR.COLON)
          const hours = secondsToTimelineTime(Number(HHMMSSFFAsArray[0]) * 60 * 60)
          const minutes = secondsToTimelineTime(Number(HHMMSSFFAsArray[1]) * 60)
          const seconds = secondsToTimelineTime(Number(HHMMSSFFAsArray[2]))
          const frames = Math.ceil(1000 / fps) * Number(HHMMSSFFAsArray[3]) * 10000
          return hours + minutes + seconds + frames
        }
        case TIME_CODE_MODE.MILLISECOND: {
          const HHMMSS = strTime.split(SEPARATOR.COLON)
          const SSMS = HHMMSS[HHMMSS.length - 1].split(SEPARATOR.DOT)
          const hours = secondsToTimelineTime(Number(HHMMSS[0]) * 60 * 60)
          const minutes = secondsToTimelineTime(Number(HHMMSS[1]) * 60)
          const seconds = secondsToTimelineTime(Number(SSMS[0]))
          const milliseconds = Number(SSMS[1]) * 10000
          return hours + minutes + seconds + milliseconds
        }
      }
    }
    return result
  }, 0)
)
