/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Menu from '@material-ui/core/Menu'
import { ReactComponent as ParentClosedIcon } from 'assets/icons/closed-parent-arrow.svg'
import { ReactComponent as ParentOpenedIcon } from 'assets/icons/opened-parent-arrow.svg'
import { ReactComponent as ExpandIcon } from 'assets/settings/dropdown_expand.svg'
import { ReactComponent as ResetIcon } from 'assets/settings/dropdown_reset.svg'
import cx from 'classnames'
import Divider from 'components/base/Divider/Divider'
import MenuItem from 'components/base/MenuItem'
import Scrollbars from 'components/base/Scrollbars'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { TranslationContext } from 'contexts/TranslationContext'
import { debounce } from 'lodash'
import { CategoryEntry } from 'types/menu'
import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styles from './searchMenu.module.scss'

type Props = {
  items: Array<CategoryEntry>
  onSelect?: (item: string, name: string) => void,
  onSearch?: (value: string) => void,
  category: string,
  search?: string,
  placeholder?: string,
  includesAll?: boolean,
  className?: string;
  useReset?: boolean;
}

const noop = () => {}


function SearchMenu({ items: originalItems = [],
  onSelect = noop,
  category,
  onSearch = noop,
  includesAll = false,
  search = '',
  placeholder = '',
  useReset = true,
  className }: Props) {
  const { t } = useContext(TranslationContext)

  const allItem = { id: 'all', name: t('AUDIO_MENU_ITEM_ALL'), entriesCount: 0, isParent: false, level: 0 }

  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement| null>(null)
  const [ _search, setSearch ] = useState(search)

  const [ items, setItems ] = useState(originalItems)

  useEffect(() => {
    setItems(originalItems)
  }, [ originalItems ])

  function handleSelect(state: string, name: string) {
    onSelect(state, name)
    handleClose()
  }

  function handleSearch(value: string) {
    setSearch(value)
    onSearch(value)
  }

  const handleSearchDebounced = useCallback(debounce(handleSearch, 300), [])

  function handleClose() {
    setSearch('')
    setAnchorEl(null)
  }


  let filteredItems = useMemo(() => items.filter(
    item => item.name.toLowerCase().indexOf(_search.toLowerCase()) !== -1
  ), [ items, _search ])

  if (_search === '' && category === 'all' && !includesAll) {
    filteredItems = [{ ...allItem },
      ...filteredItems ]
  }

  const allItems = useMemo(() => {
    if (includesAll) {
      return items
    }
    return [{ ...allItem }, ...items ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ items ])

  const selectedName = useMemo(() => {
    const flattened = allItems.reduce(
      (result, item) => {
        result.push(item)
        if (item.children) {
          result.push(...item.children)
        }
        return result
      },
      [] as unknown as [ CategoryEntry ]
    )
    return flattened.find(item => item.id === category)?.name || ''
  },
  [ allItems, category ])

  const handleParentArrowClick = (e: MouseEvent<HTMLSpanElement>, item: CategoryEntry) => {
    e.stopPropagation()
    // flat array with isParent and level properties
    setItems(v => v.reduce((acc, c, i, arr) => {
      acc.push({ ...c, isOpened: c.id === item.id ? !item.isOpened : c.isOpened })
      if (item.id === c.id && c.children) {
        // changed close to open
        if (!item.isOpened) {
          acc.push(...c.children.map(child => ({ ...child })))
        } else {
          arr.splice(
            arr.findIndex(cc => cc.id === c.children![0].id),
            c.children.length
          )
        }
      }
      return acc
    }, [] as Array<CategoryEntry>))
  }

  const handleItemClick = (item: CategoryEntry) => {
    handleSelect(item.id, item.name)
  }

  return (
    <>
      <div
        className={cx(className, styles.dropdown)}
        onClick={e => setAnchorEl(e.currentTarget)}
        ref={dropdownRef}
      >
        <span>{selectedName}</span>
        <div className={styles.controls}>
          {category !== 'all' && useReset
          && (
          <div
            onClick={e => { e.stopPropagation(); handleSelect(allItem.id, allItem.name) }}
            className={styles.reset}
          >
            <ResetIcon />
          </div>
          )}
          <div
            className={cx(styles.arrow, { [styles.opened]: anchorEl })}
          >
            <ExpandIcon />
          </div>
        </div>
      </div>
      <Menu
        anchorEl={dropdownRef.current}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ list: styles.menuContainer }}
        getContentAnchorEl={null}
      >
        <SearchInput
          autoFocus
          value={search}
          onSearch={handleSearch}
          onChange={handleSearchDebounced}
          className={cx('search_input', styles.search)}
          placeholder={placeholder}
        />
        <Divider classes={{ root: styles.divider }} />
        <Scrollbars renderViewClassName={styles.menuScrollbar} renderTrackVerticalClassName={styles.verticalTrack}>
          {filteredItems.length !== 0 ? filteredItems.map(item => (
            <MenuItem
              className={styles.menuItem}
              selected={category === item.id}
              key={item.id}
              onClick={() => handleItemClick(item)}
            >
              <div style={{
                height: '100%',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginRight: '20px',
              }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item.children
                    ? (
                      <span onClick={e => handleParentArrowClick(e, item)}>
                        {item.isOpened ? <ParentOpenedIcon /> : <ParentClosedIcon />}
                      </span>
                    )
                    : null}
                  <span style={{
                    display: 'inline-block',
                    height: '100%',
                    // eslint-disable-next-line no-nested-ternary
                    paddingLeft: item.children ? '10px' : item.level > 0 ? '20px' : '',
                    marginLeft: item.level > 0 ? '5px' : '',
                    borderLeft: item.level > 0 ? '1px solid #4E4E4E' : '',
                  }}
                  >
                    {item.name}
                  </span>
                </div>
                <div className={styles.count}>
                  {item.entriesCount === 0 ? '' : item.entriesCount}
                </div>
              </div>
            </MenuItem>
          ))
            : (
              <MenuItem
                className={cx(styles.menuItem, styles.noResults)}
              >
                {t('NO_RESULTS_FOUND')}
              </MenuItem>
            )}
        </Scrollbars>
      </Menu>
    </>
  )
}

export default SearchMenu
