import { TIME_CODE_STATE } from 'config/constants/timecode'
import { getFrameStep } from 'helpers/getFrameStep'
import { maxBy } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import * as Actions from '~/actions'
import { PLAYBACK_STATE } from '~/enums'
import { useAction, useBind, useCompose, useDiff, useStatic } from '~/hooks'
import * as Selectors from '~/selectors'
import { RangeTools, secondsToTimelineTime, timelineTimeToSeconds, TIMELINE_RANGE } from '~/Util'

/**
 * @param {Asset} asset
 * @return {{ mediaStart: number, startTime: number }}
 */
function getActiveAssetTimeParams(asset) {
  if (asset === undefined) {
    return {
      mediaStart: 0,
      startTime: 0,
      duration: 0,
    }
  }

  return {
    mediaStart: asset.mediaStart,
    startTime: asset.startTime,
    duration: asset.duration,
    endTime: asset.endTime,
  }
}

/**
 * @param {Asset} asset
 * @return {[number, boolean]}
 */
export function useAssetProgress(asset) {
  const { startTime, mediaStart } = getActiveAssetTimeParams(asset)
  const latestTimelineRewindTime = useSelector(state => state.timeline.latestTimelineRewindTime)
  const isTimelineRewinded = useSelector(state => state.timeline.isTimelineRewinded)

  const checkIsChanged = (current, prev, isInitial) => isInitial || (current !== prev)
  const isStartTimeChanged = useDiff(startTime, checkIsChanged)
  const isMediaStartChanged = useDiff(mediaStart, checkIsChanged)
  const isTimelineRewindedChanged = useDiff(isTimelineRewinded, checkIsChanged)

  const shouldRewind = (isTimelineRewindedChanged && isTimelineRewinded)
    || isStartTimeChanged || isMediaStartChanged
  return {
    shouldRewind,
    latestTimelineRewindTime,
  }
}

/**
 * @param {object} state
 * @return {NumbersRange}
 */
function getProjectTimeRange(state) {
  const assets = Selectors.getAssets(state)
  if (assets.length === 0) {
    return [ 0, 0 ]
  }
  const { endTime = 0 } = maxBy(assets, asset => asset.endTime) || {}
  return [ 0, endTime ]
}

/**
 * @param {object} state
 * @return {number}
 */
function getProjectProgress(state) {
  const [ projectStartTime ] = getProjectTimeRange(state)
  const { sliderTime } = state.timeline
  return Math.max(0, sliderTime - projectStartTime)
}

/**
 * Calc project duration, INCLUDING blanks between video clips.
 *
 * TODO:
 *  when backend will support rendering blank fragments,
 *  update selectors::getProjectDuration func respectively, and use it here instead of this
 * @see http://18.184.210.86/issues/183#note-17
 *
 * @param {object} state
 * @return {number}
 */
function getProjectDuration(state) {
  const [ startTime, endTime ] = getProjectTimeRange(state)
  return endTime - startTime
}

/**
 * @param {object} state
 * @return {{
 *  duration: number,
 *  progress: number,
 *  startTime: number,
 *  endTime: number
 * }}
 */
export function getProjectParams(state) {
  const [ startTime, endTime ] = getProjectTimeRange(state)
  return {
    duration: getProjectDuration(state),
    progress: getProjectProgress(state),
    startTime,
    endTime,
  }
}

// ---

/**
 * @param {Asset} asset
 * @param {function: ReactPlayerState} getPlayerState
 * @param {object} params
 * @param {boolean} [params.enabled=true]
 * @return {function(object)}
 */
export function usePlayerProgressHandler(asset, getPlayerState, {
  enabled = true,
  seekInitialized,
} = {}) {
  const updateSlider = useAction(Actions.timeline.updateSlider)
  const sliderTime = useSelector(state => state.timeline.sliderTime)
  /**
   * It's important to use ref here, not a value in callback dependencies.
   * We don't want to re-create callback *after* time params changed –
   * we want *existing* callback to !!always!! execute with the most actual data.
   */
  const refGetPlayerState = useStatic(getPlayerState)
  const refEnabled = useStatic(enabled)
  const refSeekInitialized = useStatic(seekInitialized)

  return React.useCallback(
    data => {
      // react-player emits progress on mount too (always rewinds to 0).
      // We never need that updates.
      const playerState = refGetPlayerState.current()
      const sec = data.getCurrentTime()
      const { mediaStart, startTime, duration } = asset

      const currentPlayerTime = Math.max(secondsToTimelineTime(sec) - mediaStart, 0) + startTime
      const assetDuration = startTime + duration

      const isStopPlaying = !(playerState.isReady && refEnabled.current)
      || !refSeekInitialized.current
      || playerState.isBuffering
      || playerState.paused

      // Player time is equal asset endTime
      const isFinishedTime = currentPlayerTime >= assetDuration

      if (isStopPlaying) {
        // The player pauses before slidertime becomes equal to endTime asset, so palyingAssets do not change
        if (playerState.paused && isFinishedTime && (assetDuration !== sliderTime)) {
          updateSlider(Math.min(currentPlayerTime, assetDuration))
        }
        return
      }

      updateSlider(Math.min(currentPlayerTime, assetDuration))
    },
    [ asset, refGetPlayerState, refEnabled, refSeekInitialized, updateSlider, sliderTime ]
  )
}

/**
 * @return {function(number)}
 */
export function useTimelineRewindHandler() {
  const { startTime: projectStartTime } = useSelector(getProjectParams)
  return useCompose(
    useAction(Actions.timeline.rewind),
    React.useCallback(
      projectProgress => projectProgress + projectStartTime,
      [ projectStartTime ]
    ),
    secondsToTimelineTime
  )
}

/**
 * @param {number} fps
 * @return {{ onPrevFrame: function, onNextFrame: function }}
 */
export function useFrameStepHandlers(fps, previewPlayerType) {
  const pause = useAction(Actions.playback.changeTimelinePlaybackState, PLAYBACK_STATE.PAUSE)
  const rewind = useAction(Actions.timeline.rewind)
  const activePreview = useSelector(state => state.preview.activePreview)

  const { sliderTime } = useSelector(state => state.timeline)
  const { timeCodeState } = useSelector(state => state.playback)
  const calcNewSliderTime = React.useCallback(
    forward => {
      const progress = timelineTimeToSeconds(sliderTime)
      const { forwardStep, backStep } = getFrameStep({ progress, fps })
      const offset = forward ? forwardStep : backStep
      const nextProgerss = Number((progress + offset).toFixed(6))
      return RangeTools.fitTo([ 0, TIMELINE_RANGE ],
        secondsToTimelineTime(nextProgerss))
    },
    [ fps, sliderTime ]
  )

  const onFrameStep = React.useCallback(
    forward => {
      if (timeCodeState !== TIME_CODE_STATE.EDIT
        && (previewPlayerType === activePreview)) {
        pause()
        rewind(calcNewSliderTime(forward))
      }
    },
    [ pause, rewind, calcNewSliderTime, timeCodeState, previewPlayerType, activePreview ]
  )

  const onNextFrame = useBind(onFrameStep, true)
  const onPrevFrame = useBind(onFrameStep, false)

  return {
    onNextFrame,
    onPrevFrame,
    timeCodeState,
  }
}
