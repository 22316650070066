import { setSourcePlayerProgress } from 'actions/preview'
import { PLAYER_TYPE } from 'enums'
import { isMediaServerUrl } from 'helpers/isMediaServerUrl'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssetById } from 'selectors'
import { selectIsBuffering } from 'selectors/playback'
import {
  selectInOutPointsMoving,
  selectInOutPointsTimeLineAssetID,
  selectSourcePlayerProgress
} from 'selectors/preview'
import { PlayerType } from 'types/common'
import { timelineTimeToSeconds } from 'Util'

type UsePlayerProgressType = {
    preview: PlayerType,
    onRewindTimeline(sec: number): void,
    progress: number,
    timeout: React.MutableRefObject<NodeJS.Timeout | null>
    isMediaServerProcessing: boolean,
    isStaticAsset: boolean,
}

export const usePlayerProgress = ({
  preview,
  progress,
  onRewindTimeline,
  timeout,
  isMediaServerProcessing,
  isStaticAsset,
}: UsePlayerProgressType) => {
  const inOutPointsTimeLineAssetID = useSelector(selectInOutPointsTimeLineAssetID)
  const timeLineAsset = useSelector(state => selectAssetById(state, inOutPointsTimeLineAssetID))
  const memoizedPlayerProgress = useSelector(selectSourcePlayerProgress)
  const isMovingIOPoints = useSelector(selectInOutPointsMoving)
  const isBuffering = useSelector(selectIsBuffering)
  const dispatch = useDispatch()

  // Set media player progress depending on slidertime position (for doubleClick inOutPointsTimeLineAsset)
  useEffect(() => {
    if (timeLineAsset && preview === PLAYER_TYPE.MEDIA) {
      const playerProgress = timeLineAsset?.playerProgress
      !isStaticAsset && !isBuffering && (memoizedPlayerProgress !== progress)
        ? dispatch(setSourcePlayerProgress(timelineTimeToSeconds(playerProgress)))
        : onRewindTimeline(timelineTimeToSeconds(playerProgress))
      if (!isBuffering && isMediaServerUrl(timeLineAsset.url)) {
        onRewindTimeline(timelineTimeToSeconds(playerProgress))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ inOutPointsTimeLineAssetID, timeLineAsset?.playerProgress ])

  // Memoization of media player progress. For split (open/close) & auto mode.
  useEffect(() => {
    if (!isStaticAsset
      && !isMovingIOPoints
      && !isMediaServerProcessing
      && !isBuffering
      && preview === PLAYER_TYPE.MEDIA
    ) {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      // eslint-disable-next-line no-param-reassign
      timeout.current = setTimeout(() => {
        dispatch(setSourcePlayerProgress(progress))
      }, 80)
    }
  }, [
    isStaticAsset,
    progress,
    dispatch,
    preview,
    timeout,
    isMovingIOPoints,
    isMediaServerProcessing,
    isBuffering,
  ])

  return {
    isBuffering,
  }
}
