/* eslint-disable class-methods-use-this */
import * as ActionTypes from '~/actions/ActionTypes'
import { clearAssetsSelection, updateAssetInPreview } from '~/actions/layer'
import { deleteAssets, loadProjectTransitionAsset, undoSplittingAssets } from '~/actions/timeline'
import { TRANSITIONS } from '~/enums'
import { getTransitionAssets } from '~/selectors'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class AssetSplittedAction extends AssetHistoryAction {

  constructor(payload, dispatch, getState) {
    const { sliderTime, newPartAsset } = payload
    super(HistoryActionType.AssetSplittedAction, payload, dispatch, getState)
    this._sliderTime = sliderTime
    this._newPartAsset = newPartAsset
  }

  get sliderTime() {
    return this._sliderTime
  }

  set sliderTime(sliderTime) {
    this._sliderTime = sliderTime
  }

  get newPartAsset() {
    return this._newPartAsset
  }

  set newPartAsset(newPartAsset) {
    this._newPartAsset = newPartAsset
  }

  * undo() {
    const { asset, newPartAsset } = this
    const transitions = getTransitionAssets(this.getState())
    const restoringTransitions = transitions.filter(tr => tr.isAttachedTo(asset.id, 'right')
    || tr.isAttachedTo(asset.id, 'left')
    || tr.isAttachedTo(newPartAsset.id, 'right')
    || tr.isAttachedTo(newPartAsset.id, 'left'))
    yield deleteAssets([ newPartAsset ])
    yield updateAssetInPreview(asset.id, { duration: asset.duration })

    for (let i = 0; i < restoringTransitions.length; i++) {
      const transition = restoringTransitions[i]
      let leftVideoAssetId = null
      let rightVideoAssetId = null
      switch (transition.type) {
        case TRANSITIONS.FADEIN: {
          rightVideoAssetId = asset.id
          break
        }
        case TRANSITIONS.FADEOUT: {
          leftVideoAssetId = asset.id
          break
        }
        case TRANSITIONS.DISSOLVE: {
          if (newPartAsset.id === transition.leftVideoAssetId) {
            leftVideoAssetId = asset.id
            rightVideoAssetId = transition.rightVideoAssetId
          } else if (asset.id === transition.rightVideoAssetId) {
            rightVideoAssetId = asset.id
            leftVideoAssetId = transition.leftVideoAssetId
          }
          break
        }
        default: {
          throw Error({ message: '[Splitted Undo] Incorrect transition type' })
        }
      }
      yield loadProjectTransitionAsset(transition, leftVideoAssetId, rightVideoAssetId, {
        pregeneratedId: transition.id,
        layerId: transition.layerId,
      })
    }
    yield undoSplittingAssets()
  }

  * redo() {
    const { asset, sliderTime, newPartAsset } = this
    yield clearAssetsSelection()
    yield updateAssetInPreview(asset.id, { selected: true })
    yield { type: ActionTypes.SPLIT,
      payload: { asset, sliderTime, pregeneratedId: newPartAsset.id } }
  }

  getOptimizedObject() {
    return { ...this }
  }

}
