import React, { FC } from 'react'
import cn from 'classnames'
import {
  ActiveTimeUnits,
  TimeCodeDataSetKeyType,
  TimeCodeDigitsType,
  UnitMeasurementType
} from 'types/timecode'
import {
  TIME_CODE_DATASET_ID,
  TIME_CODE_DIGITS_SEQUENCE
} from 'config/constants/timecode'
import { EMPTY_STRING } from 'constant'
import { Box } from '@material-ui/core'

type IProps = {
 unit: ActiveTimeUnits[number],
 timeCodeRefs: Record<
     UnitMeasurementType,
     Record<TimeCodeDigitsType,
            React.MutableRefObject<HTMLInputElement | null> | null
           >
    >
 isActiveContainer: boolean,
 onChangeUnit(e: React.SyntheticEvent): void
 isLastUnit: boolean;
}

export const UnitTime: FC<IProps> = ({
  unit,
  timeCodeRefs,
  isActiveContainer,
  onChangeUnit,
  isLastUnit,
}) => (
  <div
    className={cn('time-code__unit unit', {
      pointer: isActiveContainer,
    })}
  >
    {unit.editableDigit ? (
      <Box display="flex" alignItems="center" justifyContent="center">
        {unit.value.split(EMPTY_STRING).reverse().map((num, index) => (
          <input
            ref={timeCodeRefs[unit.id][TIME_CODE_DIGITS_SEQUENCE[index]]}
            type="text"
            value={num}
            className={cn('unit__input', {
              gray: unit.active && unit.editableDigit !== TIME_CODE_DIGITS_SEQUENCE[index],
            })}
            data-id={TIME_CODE_DATASET_ID[unit.id.toUpperCase() as TimeCodeDataSetKeyType]}
            data-unit={unit.id}
            data-digit={TIME_CODE_DIGITS_SEQUENCE[index]}
            onChange={onChangeUnit}
            key={`${unit.id.toUpperCase()}-${TIME_CODE_DATASET_ID[unit.id.toUpperCase() as TimeCodeDataSetKeyType]}`}
          />
        )).reverse()}
      </Box>
    ) : (
      unit.value.split(EMPTY_STRING).reverse().map((num, index) => (
        <span
          data-id={TIME_CODE_DATASET_ID[unit.id.toUpperCase() as TimeCodeDataSetKeyType]}
          data-unit={unit.id}
          data-digit={TIME_CODE_DIGITS_SEQUENCE[index]}
          className={cn('unit__text', {
            select: unit.active,
            active: isActiveContainer,
            gray: isLastUnit,
          })}
          key={`${unit.id}-${TIME_CODE_DIGITS_SEQUENCE[index]}`}
        >
          {num}
        </span>
      )).reverse()
    )}
  </div>
)
