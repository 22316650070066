import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import cx from 'classnames'
import * as Actions from 'actions'
import { ReactComponent as ImportIcon } from '~/assets/media/ic_import.svg'
import './FileInput.scss'
import { useOnChange } from '~/hooks'
import { useLocalFileImport } from '~/hooks/useLocalFileImport'
import { TranslationContext } from '~/contexts/TranslationContext'

const EMPTY = props => props

export default function FileInput({ title, disabled = false }) {
  const {
    getRootProps,
    isDragActive,
    // isDragAccept,
    // isDragReject,
  } = useLocalFileImport()

  const getDropProps = !disabled ? getRootProps : EMPTY

  const dispatch = useDispatch()
  useOnChange(isDragActive, isDragging => {
    dispatch(Actions.mainView.setLocalFileDragging(isDragging))
  })

  const { t } = useContext(TranslationContext)

  return (
    <div className="file-input">
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getDropProps({
          className: cx('file-input__wrap', {
            'file-input__wrap--active': isDragActive,
            // 'file-input__wrap--accept': isDragAccept,
            // TODO: for some weird reason, isDragReject is true during
            // uploading is in a progress and draging next supported file.
            // If uploading is completed isDragReject is false again.
            // 'file-input__wrap--reject': isDragReject,
          }),
        })}
      >
        <div
          className="input-container"
          data-lang-id="FILE_INPUT_BTN"
          title={t('FILE_INPUT_BTN_TOOLTIP')}
        >
          <div className="input-container__body">
            <ImportIcon />
            <p>{title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
