import { useSelector } from 'react-redux'
import { selectInOutPointsMoving } from 'selectors/preview'
import * as Assets from 'models/Asset'
import { noop } from 'lodash'
import { selectIsResizingEditor } from 'selectors/useInterface'
import useDragPreviewClipCreator from './useDragPreviewClipCreator'
import { useDropPreviewClipCreator } from './useDropPreviewClipCreator'

type UseDnDPreview = {
  isMedia: boolean,
  asset: Assets.UploadableAsset
}

export const useDnDPreview = ({ isMedia, asset }: UseDnDPreview) => {
  const isMovingIOPoints = useSelector(selectInOutPointsMoving)
  const resizing = useSelector(selectIsResizingEditor)
  const { collected, setDraggable } = useDragPreviewClipCreator(asset, {
    draggable: isMedia && !isMovingIOPoints && !resizing,
    onDenied: noop,
    onDragStarted: noop,
  })

  const [ , drop ] = useDropPreviewClipCreator()

  return {
    isDragging: collected.isDragging && isMedia,
    setDraggable,
    drop,
  }
}
