import classNames from 'classnames'
import React, { FC } from 'react'
import { ConnectDragSource } from 'react-dnd'
import './SourcePreview.scss'

interface IProps {
  imageUrl: string,
  setDraggable: ConnectDragSource
  styles: Record<string, string>
}

export const SourceImagePreview: FC<IProps> = ({ imageUrl, setDraggable, styles = {} }: IProps) => (
  <div
    style={{ ...styles }}
    className={classNames('source-preview-img player', { 'display-none': !imageUrl })}
    draggable
  >
    <div className="source-preview-img__body media-image-preview" ref={ref => setDraggable(ref)}>
      <img src={imageUrl} alt="preview" />
    </div>
  </div>
)
