import { DefaultRootState } from 'react-redux'
import Asset from 'models/Asset'
import * as Selectors from 'selectors'
import { SourceFileTypes } from 'types/common'

export function getEditingAsset(state: DefaultRootState, type: SourceFileTypes | undefined) {
  if (!type) return null

  const panelState = Selectors.getSourceFilesGroup(state, type)
  const { editingAssetBackup } = panelState

  if (editingAssetBackup === null) {
    return null
  }
  const asset = Selectors.getAssets(state).find((x: Asset) => x.id === editingAssetBackup.id)

  if (asset === undefined) {
    throw new Error(`Can't find asset#${editingAssetBackup.id}, although backup for it exists.`)
  }

  return asset
}
