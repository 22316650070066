import { useCallback, useEffect, useRef, useState } from 'react'
import { MovableContainerType } from 'types/modals/movable'

export const useMovableModal = ({ visible, width, height }:
  { visible: boolean, width: number, height: number }) => {
  const movableContainerRef = useRef<MovableContainerType>(null)
  const mouseCoords = useRef<Record<'x' | 'y', number>>({ x: 0, y: 0 })
  const [ isMouseDown, setIsMouseDown ] = useState<boolean>(false)

  const moveModal = useCallback((e: MouseEvent) => {
    if (isMouseDown && movableContainerRef.current) {
      e.stopPropagation()
      window.getSelection()?.removeAllRanges()
      const { x: left, y: top } = movableContainerRef.current.getBoundingClientRect()
      // new x y coordinates relative to mouse position
      let x = left + (e.clientX - mouseCoords.current.x)
      let y = top + (e.clientY - mouseCoords.current.y)

      const isMaxRightPosition = (x + width) > window.innerWidth
      const isMaxBottomPosition = (y + height) > window.innerHeight

      x = isMaxRightPosition ? window.innerWidth - width : x
      y = isMaxBottomPosition ? window.innerHeight - height : y

      movableContainerRef.current.style.left = `${x < 0 ? 0 : x}px`
      movableContainerRef.current.style.top = `${y < 0 ? 0 : y}px`
      // reset CSS style transform property
      movableContainerRef.current.style.transform = `translate(${0}px, ${0}px)`
      mouseCoords.current.x = x <= 0 ? Math.ceil(width / 2) : e.clientX
      mouseCoords.current.y = y <= 0 ? Math.ceil(height / 2) : e.clientY

      if (isMaxRightPosition) {
        mouseCoords.current.x = (window.innerWidth - width) + Math.ceil(width / 2)
      }
      if (isMaxBottomPosition) {
        mouseCoords.current.y = (window.innerHeight - height) + Math.ceil(height / 2)
      }
    }
  }, [ isMouseDown, width, height ])

  const onMouseDownNUp = useCallback((e: MouseEvent) => {
    setIsMouseDown(prev => {
      if (!prev) {
        if (!mouseCoords.current.x && !mouseCoords.current.y) {
          mouseCoords.current.x = e.clientX
          mouseCoords.current.y = e.clientY
        }
      } else {
        mouseCoords.current = { x: 0, y: 0 }
      }

      return !prev
    })
  }, [])

  const onMouseUp = (e: MouseEvent) => {
    setIsMouseDown(false)
    mouseCoords.current = { x: 0, y: 0 }
  }

  useEffect(() => {
    const container = movableContainerRef.current
    container?.addEventListener('mousedown', onMouseDownNUp)
    container?.addEventListener('mouseup', onMouseDownNUp)
    document?.addEventListener('mousemove', moveModal)
    window?.addEventListener('mouseup', onMouseUp)

    return () => {
      container?.removeEventListener('mousedown', onMouseDownNUp)
      container?.removeEventListener('mouseup', onMouseDownNUp)
      document?.removeEventListener('mousemove', moveModal)
      window?.removeEventListener('mouseup', onMouseUp)
    }
  }, [ onMouseDownNUp, moveModal, visible ])
  return {
    movableContainerRef,
    isMouseDown,
  }
}
