import React, { FC, MouseEvent } from 'react'
import cn from 'classnames'
import { REGULATION_CONTROL } from 'enums'
import { RegularControlActionType } from 'types/common'
import { TIME_CODE_DATASET_ID } from 'config/constants/timecode'

type IProps = {
 onContainerArrowsIncrementControl: (actionType: RegularControlActionType) => (e: MouseEvent) => void
 isActiveContainer: boolean
}

export const TimeControl: FC<IProps> = ({ onContainerArrowsIncrementControl, isActiveContainer }) => (
  <div className={cn('time-code__control control')}>
    {isActiveContainer ? (
      <>
        <div
          className={cn('control__arrow-box')}
          id={REGULATION_CONTROL.INCREASE}
          data-id={TIME_CODE_DATASET_ID.CHEVRON}
          onClick={onContainerArrowsIncrementControl(REGULATION_CONTROL.INCREASE)}
        >
          <div
            className={cn('control__increase')}
            data-id={TIME_CODE_DATASET_ID.CHEVRON}
          />
        </div>
        <div
          className={cn('control__arrow-box')}
          id={REGULATION_CONTROL.DECREASE}
          data-id={TIME_CODE_DATASET_ID.CHEVRON}
          onClick={onContainerArrowsIncrementControl(REGULATION_CONTROL.DECREASE)}
        >
          <div
            className={cn('control__decrease')}
            data-id={TIME_CODE_DATASET_ID.CHEVRON}
          />
        </div>
      </>
    ) : null}
  </div>
)
