import React, { FC } from 'react'
import MediaSettingsContainer from 'components/AssetPanels/Media/Settings/SettingsContainer'
import { SaveSettingsContainer } from 'components/SaveSettings/SaveSettingsContainer'
import { CustomizationHotkeysContainer } from 'components/CustomizationHotkeys/CustomizationHotkeysContainer'
import { CustomizationViewContainer } from 'views/CustomizationView/CustomizationViewContainer'

export const ModalView: FC = () => (
  <>
    <MediaSettingsContainer />
    <SaveSettingsContainer />
    <CustomizationHotkeysContainer />
    <CustomizationViewContainer />
  </>
)
