import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { CategoryEntry } from 'types/menu'
import { fetchAudioCategories } from 'services/AudioLibraryService'

type Props = {
  type: typeof __CFG__.AUDIO_CATEGORIES[number]
}

export function useCategoryFetch({ type }: Props) {
  const [ categories, setCategories ] = useState<CategoryEntry[]>([])

  useEffect(() => {
    async function fn() {
      const entries = await fetchAudioCategories({ type })
      setCategories(orderBy(entries, [ 'name' ], [ 'asc' ]))
    }
    fn()
  }, [ type ])

  return categories
}
