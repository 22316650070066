import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import HeadControlPanel from 'components/HeadControlPanel'

import { SearchInput } from 'components/base/SearchInput/SearchInput'
import RawButton from 'components/base/RawButton'

import { ReactComponent as FilterIcon } from 'assets/media/ic_filter.svg'
import { ReactComponent as MenuIcon } from 'assets/media/ic_menu.svg'
import { TranslationContext } from 'contexts/TranslationContext'

type Props = {
  caption: string,
}

const noop = () => {}

const showHeader = ![ 'vrspot', 'public', 'desktop', 'itochu' ].includes(__APP_PROFILE__)

function DummyHeader({ caption }: Props) {
  const { t } = useContext(TranslationContext)
  return (
    showHeader
      ? (
        <HeadControlPanel caption={caption}>
          <Box display="flex" gridRowGap={2} marginBottom={1} justifyContent="flex-end">
            {__CFG__.SOURCE_FILES_MANAGEMENT.FILTER && (
            <div className="head-control__btn" title={t('DUMMY_HEADER_BTN_FILTERS_TOOLTIP')}>
              <RawButton>
                <FilterIcon />
              </RawButton>
            </div>
            )}

            {__CFG__.SOURCE_FILES_MANAGEMENT.VIEW_OPTIONS
            && (
            <div className="head-control__btn" title={t('DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP')}>
              <RawButton>
                <MenuIcon />
              </RawButton>
            </div>
            )}
          </Box>
          {__CFG__.SOURCE_FILES_MANAGEMENT.SEARCH && <SearchInput onSearch={noop} />}
        </HeadControlPanel>
      )
      : <HeadControlPanel caption={caption} />
  )
}

export { DummyHeader }
