import cx from 'classnames'
import { MediaRecordingAsset, ImageAsset, TextAsset, AudioAsset } from 'models/Asset'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSourceFileById } from 'selectors'
import { PLAYBACK_STATE } from 'enums'
import { useAction } from 'hooks'
import { ReactComponent as TransitionPlaceholder } from '~/assets/timeline/ic_transition_placeholder.svg'
import * as PT from '~/PropTypes'
import * as Actions from '~/actions'
import { LAYER_ITEM_CONTAINER_CLASSNAME } from '~/config/constants/layer'
import { setPlaybackClip } from '~/actions/playback'
import { setInOutPointsTimelineAsset } from '~/actions/preview'
import { PREVIEW_MODE } from '~/config/constants/preview'
import { selectPreviewMode, selectorSplitPlayers } from '~/selectors/preview'
import useAssetDropTarget from './useAssetDropTarget'

function AssetDropTarget(props) {
  const {
    children, forwardRef, asset, isDragging,
    onClick, onMouseLeave, onMouseEnter, style,
    borderLeftWidth, onMouseDown,
  } = props
  const assetRef = useRef()

  const { placeholderPosition, isDragOver } = useAssetDropTarget(asset, assetRef)
  const dispatch = useDispatch()
  const sourceFile = useSelector(state => getSourceFileById(state, asset.fileId))
  const updatePreviewMarkers = useAction(Actions.preview.updatePreviewMarkers)
  const setPreviewLoading = useAction(Actions.preview.setPreviewLoading)
  const activePreviewMode = useSelector(selectPreviewMode)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const isSplittedPreview = useSelector(selectorSplitPlayers)

  if (forwardRef) {
    forwardRef(assetRef.current)
  }

  const transitionPlaceholderOffset = borderLeftWidth > 1 ? Math.ceil(borderLeftWidth / 2)
    : 0

  const handleMouseDown = e => {
    // prevent propagation to layer
    e.stopPropagation()
    onMouseDown(e)
  }

  const onSetPreviewMarkers = () => {
    if (activePreviewMode !== PREVIEW_MODE.TIMELINE || isSplittedPreview) {
      if (asset.fileId !== selectedClipId
        && !(asset instanceof ImageAsset)
        && !(asset instanceof TextAsset)) {
        setPreviewLoading(true)
      }
      dispatch(setPlaybackClip(sourceFile, PLAYBACK_STATE.PAUSE))
      dispatch(setInOutPointsTimelineAsset(asset.id))
      updatePreviewMarkers(asset.id)
    }
  }

  return (
    <div
      style={{ position: 'absolute', top: '0px', bottom: '0px', height: '64px', left: style.left, width: style.width }}
      ref={assetRef}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseDown={handleMouseDown}
      onDoubleClick={onSetPreviewMarkers}
      className={LAYER_ITEM_CONTAINER_CLASSNAME}
      data-id={asset.id}
    >
      <div
        className={cx('layer-item', {
          'layer-item--selected': asset.selected,
          'layer-item--dragging': isDragging,
          'layer-item--text': asset instanceof TextAsset,
          'layer-item--image': asset instanceof ImageAsset && !asset.mediaDeleted,
          'layer-item--audio': asset instanceof MediaRecordingAsset || asset instanceof AudioAsset,
        })}
        data-id={asset.id}
        style={{ ...style, left: 0 }}
      >
        {/* if drag under a video asset near the end or beginning of the asset */}
        <If condition={asset.canHaveTransition && placeholderPosition !== 0}>
          <TransitionPlaceholder
            style={{ left: isDragOver
              ? placeholderPosition - transitionPlaceholderOffset
              : 0 }}
            className="transition-placeholder"
          />
        </If>
        {children}
      </div>
    </div>
  )
}

AssetDropTarget.defaultProps = {
  isDragging: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseDown: () => {},
  borderLeftWidth: 1,
}

AssetDropTarget.propTypes = {
  // eslint-disable-next-line react/require-default-props
  forwardRef: PropTypes.func,
  asset: PT.LayerAsset.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseDown: PropTypes.func,
  isDragging: PropTypes.bool,
  borderLeftWidth: PropTypes.number,
}

export default React.forwardRef((props, ref) => (
  <AssetDropTarget {...props} forwardRef={ref} />
))
