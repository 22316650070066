import classNames from 'classnames'
import { TranslationContext } from 'contexts/TranslationContext'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as PT from '~/PropTypes'
import { pixel2Time, time2Pixel } from '~/Util'
import { deleteLayer as deleteLayerAction } from '~/actions/timeline'
import FileInput from '~/components/base/FileInputLegacy/FileInput'
import { TimelineLeftOffsetContext } from '~/contexts/TimelineLeftOffsetContext'
import { useAction } from '~/hooks'
import { selectDraggingAssetId, selectHoveringInsertedLayerIds } from '~/selectors'
import { selectSplitSize } from '~/selectors/useInterface'
import { Context as TimelineGeometryContext } from '../GeometryContextProvider'
import { Draggable as LayerItem } from '../LayerItem'
import './Layer.scss'
import LayerControls from './LayerControls'
import useLayerDropTarget from './useLayerDropTarget'

// ---

const Layer = props => {
  const {
    assets, layer, layerIndex, scale,
    showDropAreaPlaceholder,
    onClick, onAssetDragStarted,
    onLayerAssetsLoaded,
    onAssetDragEnded,
    onDeniedDrop,
    style,
    className,
    dragRef,
  } = props
  const layerRef = useRef()

  const deleteLayer = useAction(deleteLayerAction)

  const hoveringInsertedLayerIds = useSelector(selectHoveringInsertedLayerIds)
  const draggingAssetId = useSelector(selectDraggingAssetId)
  const { media: mediaContainerSize } = useSelector(selectSplitSize)
  const isDragging = !!draggingAssetId

  const controlsRef = React.useRef()

  // ---

  const getItemsLeftPadding = React.useCallback(
    () => controlsRef.current.getBoundingClientRect().right,
    []
  )

  const collectedDnDProps = useLayerDropTarget(layerRef, {
    layerId: layer.id,
    layerIndex,
    scale,
    getDragOffsetLeft: getItemsLeftPadding,
    onDenied: onDeniedDrop,
  })

  const { isHovered, item } = collectedDnDProps

  const [ hoveredFirstly, setHoveredFirstly ] = useState(false)

  useEffect(() => {
    // NOTE: ISSUE http://18.184.210.86/issues/1210
    if (!!item?.type && !isHovered && !assets.length
      && hoveringInsertedLayerIds.includes(layer.id)
      && isDragging) {
      // NOTE: This is for fix when created new layer under hover
      if (hoveredFirstly) {
        // NOTE: timeout for fix react-dnd error "Invariant Violation: Expected to find a valid target"
        setTimeout(() => {
          deleteLayer(layer.id, true)
        })
      } else {
        setHoveredFirstly(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isHovered, hoveringInsertedLayerIds ])

  // ---

  // Init once. Items are padded by controls block, and controls size/position are constant.
  const [ itemsLeftPadding, setItemsLeftPadding ] = React.useState(0)
  React.useEffect(
    () => {
      setItemsLeftPadding(getItemsLeftPadding())
    },
    [ getItemsLeftPadding ]
  )

  // ---

  const getTimeFromMouseEvent = React.useCallback(
    e => pixel2Time(e.clientX - itemsLeftPadding, scale),
    [ itemsLeftPadding, scale ]
  )

  const mouseDownXRef = useRef(null)

  const handleOnClick = React.useCallback(
    e => {
      e.persist()
      if (mouseDownXRef.current === e.pageX) {
        onClick(e, getTimeFromMouseEvent(e))
      }
    },
    [ getTimeFromMouseEvent, onClick ]
  )

  const handleMouseDown = e => {
    e.persist()
    // protection from asset resizing click
    mouseDownXRef.current = e.pageX
  }

  // ---

  const { maxLayerWidth, timelineWidth } = React.useContext(TimelineGeometryContext)

  const layerWidth = useMemo(() => Math.max(
    timelineWidth,
    maxLayerWidth + itemsLeftPadding
    // 30 minutes
  ) + time2Pixel(18_000_000_000, scale), [ timelineWidth, scale, maxLayerWidth, itemsLeftPadding ])

  const [ mountAssetsCount, setMountAssetsCount ] = useState(0)

  // const projectDataLayer = activeProject?.layers[layerIndex]
  const [ layerAssetsCount, setLayerAssetsCount ] = useState(-1)
  const timeout = useRef(null)

  const onAssetMount = () => {
    // eslint-disable-next-line no-shadow
    setMountAssetsCount(mountAssetsCount => mountAssetsCount + 1)
  }

  useEffect(() => {
    if (layerAssetsCount === -1) {
      setLayerAssetsCount(assets.length)
    }
  }, [ assets, layerAssetsCount ])

  useEffect(() => {
    if ((layerAssetsCount <= 0 && !mountAssetsCount)
      || mountAssetsCount === layerAssetsCount) {
      onLayerAssetsLoaded()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ mountAssetsCount, layerAssetsCount ])
  const layerItemsRef = useRef(null)
  const { setLayerControlsDOMRectData } = useContext(TimelineLeftOffsetContext)
  const { t } = useContext(TranslationContext)

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setLayerControlsDOMRectData(() => controlsRef.current?.getBoundingClientRect())
    }, 150)
  }, [ assets, setLayerControlsDOMRectData, mediaContainerSize ])

  return (
    <div
      className={classNames('layer', className)}
      ref={layerRef}
      style={{
        ...style,
        width: layerWidth,
      }}
    >
      <LayerControls
        layerId={layer.id}
        ref={controlsRef}
        dragRef={dragRef}
      />

      <div
        ref={layerItemsRef}
        className="layer__items"
        onClick={handleOnClick}
        onMouseDown={handleMouseDown}
      >
        {!layer.visible && <div className="layer__mask" />}
        <If condition={showDropAreaPlaceholder}>
          <div className="layer__import">
            <FileInput title={t('TIMELINE_TEXT_DRAG_N_DROP')} disabled />
          </div>
        </If>
        {assets.map(asset => (
          <LayerItem
            onMount={onAssetMount}
            key={asset.id}
            asset={asset}
            onDragStarted={onAssetDragStarted}
            onDragEnded={onAssetDragEnded}
            onTranslate={t}
          />
        ))}
      </div>
    </div>
  )
}

Layer.propTypes = {
  assets: PropTypes.arrayOf(PT.LayerAsset).isRequired,
  layer: PT.Layer.isRequired,
  layerIndex: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,

  showDropAreaPlaceholder: PropTypes.bool,

  onClick: PropTypes.func,
  onLayerAssetsLoaded: PropTypes.func,
  onAssetDragStarted: PropTypes.func,
  onAssetDragEnded: PropTypes.func,
  onDeniedDrop: PropTypes.func,
  dragRef: PT.RefObject.isRequired,
}

Layer.defaultProps = {
  showDropAreaPlaceholder: false,
  onClick: () => { },
  onLayerAssetsLoaded: () => { },
  onAssetDragStarted: () => { },
  onAssetDragEnded: () => { },
  onDeniedDrop: () => { },
}

// ---

export default React.memo(Layer)
