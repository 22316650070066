import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Context as DnDContext } from '~/components/Timeline/DnDContextProvider'
import { DraggingItemContext } from '~/components/Timeline/DraggingItemProvider'
import { TimelineTopOffsetContext } from '~/components/Timeline/TimelineTopOffsetContext'
import { TimelineScrollPositionContext } from '~/components/Timeline/ScrollPositionContext'
import { getAssetsByLayers, getDnDPatchAssets } from '~/selectors'
import { TransitionAsset } from '~/models/Asset'
import { assetToTimelineDragElement } from './assetToTimelineDragElement'
import { getLayerIndexFromY } from '~/Util/timeline-coords'
import { patchDnDItemAssets } from './patchDnDAssets'
import { time2Pixel } from '~/Util'
import { TimelineLeftOffsetContext } from '~/contexts/TimelineLeftOffsetContext'


export const useInitialDragData = ({ item, clientOffset, localTime }) => {
  const { isDraggingItemOverLayer } = useContext(DnDContext)
  const { getProps: getDraggingItemProps } = useContext(DraggingItemContext)
  const topOffset = useContext(TimelineTopOffsetContext)
  const { timelineLeftOffset } = useContext(TimelineLeftOffsetContext)
  const { scrollLeft, scrollTop } = useContext(TimelineScrollPositionContext)

  const scale = useSelector(state => state.timeline.scale)
  const assetsByLayers = useSelector(getAssetsByLayers)
  const dndPatchAssets = useSelector(getDnDPatchAssets)

  // If changed of dndAssets (example: delete dissolve) in begin() callback
  const dragItem = patchDnDItemAssets(item, dndPatchAssets)

  const { isOverItem, detachedMode } = getDraggingItemProps()

  const sortedAssets = dragItem.selectedAssets ?? []
  const dragItemSelectedAssetsKeys = sortedAssets.map(asset => asset.id)
  const isMultipleDrag = Boolean((dragItemSelectedAssetsKeys.length > 1)
  && dragItemSelectedAssetsKeys.find(id => id === dragItem.id))

  const draggableAssets = dragItem.selectedAssets ?? []
  const dragFirstItem = draggableAssets[0]
  const dragLastItem = draggableAssets[draggableAssets.length - 1]
  const draggableBlockDuration = dragLastItem?.startTime
    + dragLastItem?.duration - dragFirstItem?.startTime

  const restElementsByLayers = Array.from(
    assetsByLayers,
    ([ , value ]) => value
      .filter(a => isMultipleDrag
        ? !dragItem.selectedDragIds?.includes(a.id) && !(a instanceof TransitionAsset)
        : dragItem.id !== a.id && !(a instanceof TransitionAsset))
      .map(assetToTimelineDragElement(scale))
  )

  const targetLayerIndex = getLayerIndexFromY({
    y: clientOffset.y + scrollTop,
    topOffset,
    layersLength: restElementsByLayers.length,
  })

  return {
    isDraggingItemOverLayer,
    isOverItem,
    detachedMode,
    scale,
    timelineTopOffset: topOffset,
    timelineLeftOffset,
    scrollLeft,
    scrollTop,
    isMultipleDrag,
    targetLayerIndex,
    restElementsByLayers,
    dragItem,
    dragAssetsWidth: time2Pixel(draggableBlockDuration, scale),
    sliderTimeX: time2Pixel(localTime, scale) + timelineLeftOffset - scrollLeft,
  }
}
