import React from 'react'

interface IContext {
  previewPlayer: HTMLDivElement | null,
  previewPlayerBox: React.MutableRefObject<HTMLDivElement | null>,
  onSetPreviewPlayer: React.RefCallback<HTMLDivElement>
  handleFullscreen(): void
}

export const PreviewPlayerContext = React.createContext<IContext>({} as IContext)
