import React from 'react'
import cx from 'classnames'
import './RawButton.scss'

function RawButton(props) {
  const { className, forwardRef, children, ariaLabel, dataLangId, ...rest } = props
  return (
    <button
      ref={forwardRef}
      className={cx('raw-button', className)}
      type="button"
      aria-label={ariaLabel}
      data-lang-id={dataLangId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </button>
  )
}

/**
 * @typedef {object} Props
 * @property {React.ReactNode} [children]
 * @property {string} [className]
 * @property {function} [onClick]
 * @property {string} [dataLangId]
 * @property {boolean} [disabled]
*/

/**
 *
 * @param {Props} param0
 * @param {any} ref
 * @returns
 */
const RawButtonContainer = ({ children = null, ...props }, ref) => (
  <RawButton {...props} forwardRef={ref}>
    {children}
  </RawButton>
)

export default React.forwardRef(RawButtonContainer)
