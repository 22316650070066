import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { DEFAULT_FPS } from '~/Util'
import * as PT from '~/PropTypes'
import Placeholder from './Placeholder'
import './Preview.scss'
import PreviewToolbar from './PreviewToolbar'
import usePreviewState from '~/hooks/preview/usePreviewState'
import { GenerateThumbnail } from './Thumbnail'
import { usePreview } from '~/hooks/preview/usePreview'
import { PreviewPlayerContext } from '~/contexts/PreviewPlayerContext'
import { useDnDPreview } from '~/hooks/preview/dnd/useDnDPreview'
// ---

function Preview(props) {
  const {
    showPlaceholder,
    playing,
    onPlayPause,
    children,
    fps,
    onPrevFrame,
    onNextFrame,
    onRewindTimeline,
    progress,
    duration,
    showThumbnail,
    isMedia,
    previewPlayerType,
    asset,
  } = props

  const {
    onToggleFullscreen,
    onCloseFullscreen,
    onResize,
    isLoading,
    params: { fullScreen, volume, muted, width, height },
  } = usePreviewState({ isMedia })

  const {
    previewPlayer,
    previewPlayerBox,
    onSetPreviewPlayer,
  } = useContext(PreviewPlayerContext)

  const { setPreviewPlayerVideoElem, previewPlayerVideoElem } = usePreview({
    onToggleFullscreen,
    onResize,
    onCloseFullscreen,
    isMedia,
    previewPlayer,
    previewPlayerBox,
    previewPlayerType,
  })

  const { setDraggable } = useDnDPreview({ isMedia, asset })

  return (
    <div
      ref={ref => {
        onSetPreviewPlayer(ref)
        isMedia && setDraggable(ref)
      }}
      className={classNames('preview-player', { 'preview-player--full': fullScreen })}
    >
      {/* TODO: fix DraggablePreviewOverlay component. */}
      {/* These components are used for I/O points!!! */}
      {/* <PreviewDropBox isMedia={isMedia} drop={drop} /> */}
      {/* <DraggablePreviewOverlay asset={asset} /> */}
      <div
        className="preview-player__video"
        ref={ref => {
          previewPlayerBox.current = ref
          setPreviewPlayerVideoElem(ref)
        }}
      >
        <If condition={showPlaceholder}>
          <Placeholder />
        </If>
        <If condition={isLoading && !showPlaceholder}>
          <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={150} />
          </Box>
        </If>

        {children({
          playing,
          volume,
          muted,
          width,
          height,
          isMedia,
        })}
      </div>

      <If condition={showThumbnail}>
        <GenerateThumbnail width={width} height={height} />
      </If>

      <PreviewToolbar
        isMedia={isMedia}
        fps={fps}
        playing={playing}
        progress={progress}
        duration={duration}
        onRewindTimeline={onRewindTimeline}
        onPlayPause={onPlayPause}
        onPrevFrame={onPrevFrame}
        onNextFrame={onNextFrame}
        previewPlayerType={previewPlayerType}
        previewPlayerVideoElem={previewPlayerVideoElem}
        asset={asset}
        isLoading={isLoading}
      />
    </div>
  )
}

Preview.defaultProps = {
  showPlaceholder: false,
  fps: DEFAULT_FPS,
  progress: 0,
  duration: 0,
  onRewindTimeline: null,
  onPrevFrame: null,
  onNextFrame: null,
  showThumbnail: false,
  isMedia: false,
  previewPlayerType: '',
  asset: null,
}

Preview.propTypes = {
  children: PropTypes.func.isRequired,

  playing: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  duration: PropTypes.number,
  showThumbnail: PropTypes.bool,

  showPlaceholder: PropTypes.bool,
  onRewindTimeline: PropTypes.func,

  onPrevFrame: PropTypes.func,
  onNextFrame: PropTypes.func,
  onPlayPause: PropTypes.func.isRequired,
  fps: PropTypes.number,
  isMedia: PropTypes.bool,
  previewPlayerType: PropTypes.string,
  asset: PropTypes.oneOfType([
    PT.VideoAsset, PT.ImageAsset, PT.AudioAsset, PT.TextAsset,
  ]),
}

// ---

export default React.memo(Preview)
