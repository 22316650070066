import { isNil } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { NWShortCutContext } from 'contexts/NWShortCutContext'
import { useKeyCodeListener, useResizeSensor } from 'hooks'
import { selectorPreview } from 'selectors/preview'
import { PlayerType } from 'types/common'
import { PLAYER_TYPE } from 'enums'

/*
 * Hook for <Preview /> Component
 */
type UsePreview = {
  onToggleFullscreen(): void,
  onResize(): void,
  onCloseFullscreen(): void,
  isMedia: boolean,
  previewPlayer: HTMLDivElement | null
  previewPlayerBox: React.MutableRefObject<HTMLDivElement | null>
  previewPlayerType: PlayerType
}

const FULL_SCREEN_EVENTS = [
  'fullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
  'webkitfullscreenchange',
]

export const usePreview = ({
  onToggleFullscreen,
  onResize,
  onCloseFullscreen,
  previewPlayerBox,
  previewPlayerType,
}: UsePreview) => {
  const { fullScreen } = useSelector(selectorPreview)

  const { unRegisterNWGlobalHotKey } = useContext(NWShortCutContext)
  const [ previewPlayerVideoElem, setPreviewPlayerVideoElem ] = useState<HTMLDivElement | null>(null)

  const onExit = useCallback((e: KeyboardEvent) => {
    if (previewPlayerType === PLAYER_TYPE.TIMELINE) {
      onCloseFullscreen()
      unRegisterNWGlobalHotKey()
    }
  }, [ onCloseFullscreen, unRegisterNWGlobalHotKey, previewPlayerType ])

  const isTimelinePlayer = previewPlayerType === PLAYER_TYPE.TIMELINE

  const fullscreenChange = useCallback(() => {
    if (isTimelinePlayer) {
      onToggleFullscreen()
    }
  }, [ onToggleFullscreen, isTimelinePlayer ])

  useEffect(() => {
    FULL_SCREEN_EVENTS.forEach(screenEvent => {
      document.removeEventListener(screenEvent, fullscreenChange, false)
    })

    FULL_SCREEN_EVENTS.forEach(screenEvent => {
      document.addEventListener(screenEvent, fullscreenChange, false)
    })

    return () => {
      FULL_SCREEN_EVENTS.forEach(screenEvent => {
        document.removeEventListener(screenEvent, fullscreenChange, false)
      })
    }
  }, [ onToggleFullscreen, fullscreenChange ])

  useEffect(() => {
    if (!fullScreen && !isNil(fullScreen) && isTimelinePlayer) {
      unRegisterNWGlobalHotKey()
    }
  }, [ fullScreen, unRegisterNWGlobalHotKey, isTimelinePlayer ])

  const options = useMemo(() => ({ debounce: 30, leading: true, trailing: true }), [])

  useKeyCodeListener(React.useRef(document), 'Escape', onExit)
  useResizeSensor(previewPlayerBox, onResize, options)

  return { setPreviewPlayerVideoElem, previewPlayerVideoElem }
}
