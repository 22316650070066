import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import produce from 'immer'
import { selectSourcePlayerProgress } from 'selectors/preview'
import { useLazyComposeConst, useAction } from '~/hooks'
import * as Actions from '~/actions'

const reducer = produce(Object.assign)

export default function useMediaPreviewState({ seek }) {
  const setPreviewLoading = useAction(Actions.preview.setPreviewLoading)
  const memoizedPlayerProgress = useSelector(selectSourcePlayerProgress)

  const [ state, dispatch ] = React.useReducer(reducer, null, () => ({
    progress: memoizedPlayerProgress || 0,
    duration: 0,
  }))

  const useHandler = useLazyComposeConst(dispatch)
  useEffect(() => {
    if (memoizedPlayerProgress === 0) {
      seek(memoizedPlayerProgress)
    }
  }, [ memoizedPlayerProgress, seek ])

  return [
    state,
    {
      onChangeProgress: useHandler(player => {
        const progress = player.getCurrentTime()
        return { progress }
      }),
      onChangeDuration: useHandler(duration => {
        setPreviewLoading(false)
        return { duration }
      }),
    },
    memoizedPlayerProgress,
  ]
}
