import { useCallback, useRef, useState } from 'react'

export const useContextMenu = () => {
  const contextMenuContainer = useRef<HTMLDivElement | null>(null)
  const [ menuCoords, setMenuCoords ] = useState({ left: 0, top: 0 })
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const onOpenMenu = useCallback((e: MouseEvent) => {
    if (anchorEl) return
    e.preventDefault()
    e.stopPropagation()
    setMenuCoords({ left: e.clientX, top: e.clientY })
    setAnchorEl(contextMenuContainer.current)
  }, [ anchorEl ])

  const onCloseMenu = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
    setAnchorEl(null)
    setMenuCoords({ left: 0, top: 0 })
  }, [])

  return {
    onCloseMenu,
    onOpenMenu,
    contextMenuContainer,
    anchorEl,
    menuCoords,
  }
}
