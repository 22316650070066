import MSP from 'components/ExtendedReactPlayer/MediaServerPlayer/msp'

export const initMediaServerPlayer = (ref, options) => {
  const {
    src,
    onDuration,
    onSeeking,
    onFinishMediaServerSeekProcessing,
    onStartMediaServerSeekProcessing,
  } = options
  const player = new MSP()
  let isSeek = false
  if (ref.current != null) {
    player.attachMedia(ref.current, src)
  }
  player.on(MSP.Events.DURATION, duration => {
    onDuration(duration)
  })
  player.on(MSP.Events.ERROR, msg => {
    console.error(msg)
  })
  player.on(MSP.Events.PROCESS_NEXT_ITEM_COMPLETED, () => {
    if (isSeek) {
      isSeek = false
      onFinishMediaServerSeekProcessing()
    }
  })
  player.on(MSP.Events.START_SEEK_MEDIA_SERVER_PROCESSING, () => {
    onSeeking && onSeeking()
    if (!isSeek) {
      isSeek = true
      onStartMediaServerSeekProcessing()
    }
  })
  return player
}
